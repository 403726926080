<div class="row px-1" style="height:calc(100vh - 4rem); overflow:hidden">
    <!-- CATEGORIE -->
    <div class="col-2 scrollable">
            <ul class="list-group">
                <li *ngFor="let c of categories" class="list-group-item list-group-item-primary" [class.active]="selected_category==c"
                    (click)="selected_category=c;onSelectCategory(c)">
                    {{c.name}}
                </li>
                
            </ul>
    </div>
    <!-- PRODOTTI -->
    <div class="col-6 pl-0 scrollable">
        <div class="row mb-5">
            <div class="col-3 card mb-2" *ngFor="let p of products" (click)="addProduct(p)" style="box-shadow: none">
                <div class="card-body bg-light" style="height:15vh; background: url('{{p.coverImage().imagePath}}'); background-repeat: no-repeat; background-size: cover">
                </div>
                <div class="card-footer bg-color1 p-1 text-center">
                    <small>{{p.name}}</small>
                </div>
            </div>
        </div>

        <app-keyboard *ngIf="keyboardShow" [classname]="'keyboard_shop'" (data)="addCustomProduct($event)"></app-keyboard>
    </div>
    <!-- COMANDA -->
    <div class="col-4 border-left">
        <div class="">
            <div class="row mb-2">
                <div [class.col-12]="document.details.length == 0" class="col-4">
                    <button class="btn btn-info btn-block" (click)="keyboardShow==true ? keyboardShow=false : keyboardShow=true">
                        <i class="fas fa-keyboard"></i>
                        <span *ngIf="document.details.length == 0" class="ml-2">Tastiera</span>
                    </button>
                </div>
                <div class="col-8">
                    <button *ngIf="document.details.length > 0" class="btn btn-danger btn-block" (click)="removeAll()" ><i class="fa fa-trash-alt mr-2"></i>Annulla</button>
                </div>
            </div>
            <div class="receipt_style" style="height:calc(100vh - 11rem); overflow-y:auto">
                <table class="table table-sm table-striped">
                    <tbody>
                        <tr *ngIf="document.details.length > 0" class="text-xl px-1">
                            <td><b>TOTALE</b></td>
                            <td></td>
                            <td colspan="2" class="text-right py-2"><b>{{document.total | currency:"EUR":true}}</b></td>
                        </tr>
                        <tr *ngFor="let d of document.details">
                            <td><span *ngIf="d.product">{{d.product.name}}</span><span *ngIf="!d.product">{{d.description}}</span></td>
                            <td><div>&#10005;{{d.quantity}}</div></td>
                            <td class="text-right">{{d.total_gross | currency:"EUR":true}}</td>
                            <td><button class="btn text-lg text-danger float-right" (click)="removeGood(d);"><i class="fa fa-trash-alt"></i></button></td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <div class="row mt-2" *ngIf="document.details.length > 0">
                <div class="col-6">
                    <button class="btn btn-primary btn-sm btn-block" (click)="receipt()" >Scontrino</button>
                </div>
                <div class="col-6">
                    <button class="btn btn-success btn-block" >Preconto</button>
                </div>
            </div>
        </div>
    </div>
</div>