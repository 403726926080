<section class="container">
    <div class="row p-2" style="justify-content: space-between">
        <div>
            Tavolo&ensp;<b>{{record.tablename}}</b><span *ngIf="!record.tablename">in preparazione</span>
            <span class="float-right bg-info ml-2 px-3"  *ngIf="record.state==4" style="border-radius:1rem">Servito</span>
            <span class="float-right bg-success ml-2 px-3" *ngIf="record.state==5" style="border-radius:1rem">Preacconto</span>
        </div>
        <!-- <div style="height:1.75rem; width:40vw; font-size: 0.75rem; line-height:0.75rem; overflow-y:auto">
            <span><b>Linguine all'astice</b>:&ensp;Lorem ipsum dolor sit amet, consectetur adipiscing elit</span>
        </div> -->
        <div>
            <span>Coperti&ensp;</span>
            <button class="btn-secondary px-2" (click)="record.adults==1 ? false : record.adults=record.adults-1"><i class="fa fa-minus"></i></button>
            <span [class.text-danger]="record.adults > 10" class="mx-3"><i class="fa fa-male mr-1"></i>{{record.adults}}</span>
            <button class="btn-secondary px-2" autofocus (click)="record.adults=record.adults+1"><i class="fa fa-plus"></i></button>
        </div>
    </div>
    <div class="row bg-color0" style="height:calc(100vh - 6rem); overflow:hidden">
        <!-- CATEGORIE -->
        <div class="col-2 scrollable">
                <ul class="list-group">
                    <li *ngFor="let c of categories" class="list-group-item list-group-item-primary" [class.active]="selected_category==c.id"
                        (click)="selected_category=c.id;onSelectCategory(c.id)">
                        {{c.name}}
                    </li>
                </ul>
        </div>
        <!-- PRODOTTI -->
        <div class="col-6 pl-0 scrollable">
            <div class="row mb-5">
                <div class="col-3 card mb-2" *ngFor="let p of products" (click)="addProduct(p)" style="box-shadow: none">
                    <div class="card-body bg-light" style="height:15vh; background: url('{{p.coverImage().imagePath}}'); background-repeat: no-repeat; background-size: cover">
                    </div>
                    <div class="card-footer bg-color1 p-1 text-center">
                        <small>{{p.name}}</small>
                    </div>
                </div>
            </div>
        </div>
        <!-- COMANDA -->
        <div class="col-4 border-left scrollable">
            <div class="">
                <ng-container *ngFor="let c of record.courses" >
                    <ng-container *ngIf="c.state<4 || (c.state==4 && c.goods && c.goods.length>0)">
                            <h3 class="mb-0" [class.bg-gray]="selected_course!=c.number" [class.bg-color1]="selected_course==c.number" (click)="c.state==4 ? false : selected_course=c.number">&nbsp;Portata {{c.number}}
                                <ng-container *ngIf="c.goods && c.goods.length>0">
                                    <span class="float-right bg-warning px-3" *ngIf="c.state==3" style="border-radius:1rem 0 0 1rem">Da servire</span>
                                    <span class="float-right bg-info px-3"  *ngIf="c.state==4" style="border-radius:1rem 0 0 1rem">Servito</span>
                                </ng-container>
                            </h3>
                    </ng-container>
                    <table class="table table-sm bg-white" (click)="selected_course=c.number">
                            <tbody>
                                <tr *ngFor="let g of c.goods">
                                    <td><div>{{g.quantity}}&#10005;</div></td>
                                    <td style="max-width:10vw; overflow-x:auto"><small>{{g.product.name}}</small></td>
                                    <td class="text-right">{{g.net_price*(1+g.tax/100) | currency:" &euro;"}}</td>
                                    <td>
                                        <button class="btn btn-xs btn-danger float-right" (click)="removeGood(g.course,g.id_product)"><i class="fa fa-trash-alt px-1 "></i></button>
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </ng-container>

                <button class="btn btn-xs btn-primary btn-sm btn-block" *ngIf="showSendCourses" (click)="openModalSendCommand()">Invio comanda</button>
                <!-- <button class="btn btn-secondary btn-sm btn-block" (click)="setState(5)">Preconto</button> -->
                <button class="btn btn-info btn-sm btn-block" *ngIf="record.state>3" (click)="openCloseAccount()">Chiusura conto</button>
            </div>
        </div>
    </div>
</section>


<app-toolbarmodel [model]="this"></app-toolbarmodel>


<ng-template #modalSendCommand>
    <!-- <div class="modal-header">
        Invia comanda
    </div> -->
    <div class="modal-body bg-color0 border">
        <div class="row">
            <div class="col-12">
            <button class="btn btn-block btn-primary py-3" (click)="sendCommand(current_course,true)">
                Invia 
                <b *ngIf="current_course==1">prima</b> 
                <b *ngIf="current_course==2">seconda</b>
                <b *ngIf="current_course==3">terza</b>
                <b *ngIf="current_course==4">quarta</b>
                portata</button>
            </div>
            <div class="col-12 mt-2">
                <button class="btn btn-block btn-info py-3" (click)="sendCommand(0,true)">Invia comanda <b>completa</b></button>
            </div>
            <div class="col-7 mt-2">
                <button class="btn btn-block btn-warning py-3" (click)="sendCommand(0,false)">Invia senza stampare</button>
            </div>
            <div class="col-5 mt-2">
                <button class="btn btn-block btn-secondary py-3" (click)="closeModalSendCommand()"><i class="fas fa-times mr-2"></i>Annulla</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #modalCloseAccount>
    <!-- <div class="modal-header bg-color1">
        Chiudi conto
    </div> -->
    <div class="modal-body bg-color0 border">
        <div class="row">
            <div class="col-7">
                <table class="table table-sm">
                    <tr>
                        <td>Conto</td>
                        <td class="text-right">{{document.total | currency:"EUR":true}}</td>
                    </tr>
                    <tr>
                        <td>Abbuono</td>
                        <td class="text-right">
                            <button class="btn btn-sm px-2 text-right" (click)="discount()"><i class="fa fa-pencil-alt"></i></button>
                            {{document.discount | currency:"EUR":true}}
                        </td>
                    </tr>
                    <tr style="background-color: rgba(0,0,0,0.1)">
                        <td ><h2>TOTALE</h2></td>
                        <td class="text-right"><h2>{{document.total-document.discount | currency:"EUR":true}}</h2></td>
                    </tr>
                    <tr>
                        <td><i class="far fa-question-circle mr-2"></i>Pro Capite
                        </td>
                        <td class="text-right">
                            <span class="mx-2 px-2 border"><i class="fa fa-male mr-2"></i>{{divider}}</span>
                            <button class="btn btn-sm px-2" (click)="divider==1 ? false : divider=divider-1"><i class="fa fa-minus"></i></button>
                            <button class="btn btn-sm px-2" (click)="divider=divider+1"><i class="fa fa-plus"></i></button>
                            {{(document.total-document.discount)/divider | currency:"EUR":true}}</td>
                    </tr>
                    <!-- <tr>
                        <td>Incassato</td>
                        <td class="text-right">
                            <button class="btn btn-sm px-2 text-right" (click)="rest()"><i class="fa fa-pencil-alt"></i></button>
                            {{cashed | currency:"EUR":true}}
                        </td>
                    </tr>
                    <tr >
                        <td><i class="far fa-question-circle mr-2"></i>
                            <span *ngIf="document.total-document.discount-cashed<0">Resto</span>
                            <span *ngIf="document.total-document.discount-cashed>0">Da incassare</span>
                            
                            </td>
                        <td class="text-right"> {{document.total-document.discount-cashed | currency:"EUR":true}}</td>
                    </tr> -->
                </table>
            </div>
            <div class="col-5 text-md receipt_style" style="max-height: 60vh; overflow-y:auto">
                <div class="text-center"><b >DETTAGLIO</b></div>
                <table class="table table-sm table-striped">
                    <tbody>
                        <!-- <tr>
                            <td ><i>Desc.</i></td>
                            <td><i>Qt</i></td>
                            <td class="text-right"><i>Costo</i></td>
                        </tr> -->
                        <tr *ngFor="let d of document.details">
                            <td>{{d.product.name}}</td>
                            <td><div>&#10005;{{d.quantity}}</div></td>
                            <td class="text-right">{{d.total_gross | currency:"EUR":true}}</td>
                        </tr>
                        <tr>
                            <td>TOTALE</td>
                            <td></td>
                            <td class="text-right"><b>{{document.total | currency:"EUR":true}}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class=" mt-2 row">
            <div class="col-3">
                <button class="btn btn-primary btn-block" (click)="receipt()">Scontrino</button>
            </div>
            <div class="col-3">
            <button class="btn btn-success btn-block" (click)="preaccount()">Preconto</button>
            </div>
            <div class="col-3">
                <button class="btn btn-danger btn-block" (click)="delete()"><i class="fa fa-trash-alt mr-2"></i>Elimina</button>
            </div>
            <div class="col-3">
                <button class="btn btn-secondary btn-block" (click)="closeCloseAccount()"><i class="fas fa-times mr-2"></i>Annulla</button>
            </div>
        </div>
            <!-- <button class="btn btn-secondary m-1 " (click)="divide()">Dividi</button> -->
            <!-- <button class="btn text-lg btn-secondary m-1 " (click)="percapita()">Procapite</button> -->
            <!-- <button class="btn btn-secondary m-1 " (click)="openModalCalculateRest()">Resto</button> -->
    </div>
</ng-template>


<ng-template #modalKeyboard>
    <div class="modal-header">

    </div>
    <div class="modal-body">


    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalKeyboard()">Chiudi</button>
    </div>
    
</ng-template>