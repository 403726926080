<section class="section">
    <div class="row p-2">
        <div class="col-2">
            <ul class="list-group">
                <li class="list-group-item list-group-item-primary " [class.active]="selected_setting==0" (click)="selected_setting=0">Generali</li>
                <li class="list-group-item list-group-item-primary " [class.active]="selected_setting==1" (click)="selected_setting=1">Sale</li>
                <li class="list-group-item list-group-item-primary " [class.active]="selected_setting==2" (click)="selected_setting=2">Tavoli</li>
                <li class="list-group-item list-group-item-primary " [class.active]="selected_setting==3" (click)="selected_setting=3">Categorie</li>
                <li class="list-group-item list-group-item-primary " [class.active]="selected_setting==4" (click)="selected_setting=4">Articoli</li>
                
                
            </ul>
        </div>
        <div class="col-10">
            <setting-general *ngIf="selected_setting==0"></setting-general>
            <setting-rooms *ngIf="selected_setting==1"></setting-rooms>
            <setting-tables *ngIf="selected_setting==2"></setting-tables>
            <setting-productcategories *ngIf="selected_setting==3"></setting-productcategories>
            <setting-products *ngIf="selected_setting==4"></setting-products>
        </div>
    </div>
</section>