import { Product } from "projects/c1-backend/src/app/db/product";
import { Helper } from "projects/core/src/helper";


export class Room{
    id:number=0;
    title:string="";
}

export class Tables{
    id:number=0;
    id_room:number=0;
    seats:number=1;
    title:string="";
}


export class Order{
    id:number=0;
    id_restaurant_table:number=0;
    tablename:string="";
    state:number=1;
    status:number=1;
    date:string="";
    checkin:string="";
    checkout:string="";
    adults=1;
    children=0;
    courses:Course[]=[];


    constructor(){

   
        for(let i=0;i<4;i++){
            let course:Course=new Course;
            course.number=i+1;
            course.goods=[];
            course.state=3;
            this.courses.push(course);
        }
        this.date=Helper.convertDateControl(new Date(),false);
        this.checkin=Helper.convertDateControl(new Date(),true);
        this.checkout=Helper.convertDateControl(new Date(),true);
        

    }
}


export class Course{
    number:number=0;
    goods:Good[]=[];
    state:number=3;

    constructor(){
        this.goods=[];
    }
}

export class Good{
    id:number=0;
    id_product:number=0;
    product:Product;
    quantity:number=1;
    net_price:number=0;
    tax:number=0;
    course:number=0;

}