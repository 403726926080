export class ProductCategories {
    id: number;
    name:string;
    status:boolean;
    parent:number;
    level:number;
    icon:string;
    count:number;
    params:any;



    get tax(){
        if(!Array.isArray(this.params)){
            try{
                this.params=JSON.parse(this.params);
            }catch(e){
                this.params=[];
            }
        }

        for(let p of this.params){
            if(p.name=="tax")
                return p.value;
        }


    }

    set tax(value){
        if(!Array.isArray(this.params)){
            try{
                this.params=JSON.parse(this.params);
            }catch(e){
                this.params=[];
            }
        }
        //trova il parametro tax
        if(this.params.length > 0){
            let tax_param=this.params.filter((x)=>x.name=="tax");
            if(tax_param && tax_param.length>0){
                tax_param[0].value=value;
            }else{
                let tp:any={};
                tp.name="tax";
                tp.value=value;
                this.params.push(tp);
            }
        }else{
            let tp:any={};
            tp.name="tax";
            tp.value=value;
            this.params.push(tp);
        }
    }


}