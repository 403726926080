<div class="modal-header bg-light">
    <b>Verifica di tutte le fatture emesse</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancel()" >
          <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="my-5 text-center" *ngIf="loading">
        <i class="fas fa-cloud-download-alt mr-2"></i>Scaricamento informazioni in corso...<br>La procedura potrebbe impiegare diversi minuti!<br>
        <button type="button" class="btn btn-outline-secondary mt-3" (click)="cancel()" >Annulla</button>
    </div>
    <ng-container *ngIf="!loading">
        <table class="table" >
            <thead>
                <th><small>ID documento</small></th>
                <th class="text-center"><small>Numero</small></th>
                <th class="text-center"><small>Stato nel DB</small></th>
                <th class="text-center"><small>Stato sullo SDI</small></th>
                <th class="text-center"><small>Modifica</small></th>
            </thead>
            <tr *ngFor="let record of list">
                <td class="py-0">{{record.id}}</td>
                <td class="py-0 text-center"><b>{{record.number}}</b></td>
                <td class="py-0 text-center">{{record.old_status}}</td>
                <td class="py-0 text-center">{{record.new_status}}</td>
                <td class="py-0 text-center"><span class="badge border">{{record.change}}</span></td>
            </tr>
        </table>
        <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
            <i class="icon fas fa-info-circle mr-2"></i><i>Nessuna fattura</i>
        </div>
    </ng-container>
</div>