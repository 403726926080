import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TablesComponent } from './tables/tables.component';
import { SettingsComponent } from './settings/settings.component';
import { ShopComponent } from './shop/shop.component';

const routes: Routes = [

  
];

routes.push({ path: 'shop', component:ShopComponent });
routes.push({ path: 'tables', component:TablesComponent });
routes.push({ path: 'settings', component:SettingsComponent });

routes.push({ path: 'login', loadChildren: () => import('projects/core/src/common/login/login.module').then(m => m['LoginModule']) });

//!!!!!! NECESSARIO PER GARANTIRE LE CORRETTE DIPENDENZE !!!!!!
routes.push({ path: 'admin-booking', loadChildren: () => import('projects/c1-backend/src/app/views/booking/booking.module').then(m => m.BookingModule) });
routes.push({ path: 'admin-address', loadChildren: () => import('projects/c1-backend/src/app/views/address/address.module').then(m => m.AddressModule) });
routes.push({ path: 'admin-project', loadChildren: () => import('projects/c1-backend/src/app/views/projects/projects.module').then(m => m.ProjectsModule) });
routes.push({ path: 'admin-ads', loadChildren: () => import('projects/modules/src/app/ads/ads.module').then(m => m.AdsModule) });
routes.push({ path: 'admin', loadChildren: () => import('projects/c1-backend/src/app/app.module').then(m => m.AppModule) });



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
