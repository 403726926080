import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from 'projects/c1-backend/src/app/db/image';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { ProductCategories } from 'projects/c1-backend/src/app/db/productcategories';
import { ProductcategoriesService } from 'projects/c1-backend/src/app/services/productcategories.service';
import { ProductsService } from 'projects/c1-backend/src/app/services/products.service';
import { Globals } from 'projects/core/src/globals';
import { UploadService } from 'projects/core/src/lib/upload.service';

@Component({
  selector: 'setting-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})

export class SettingProductsComponent implements OnInit {

  @ViewChild("modalEdit")
  modalEdit:TemplateRef<any>;
  modalEditRef;

  list=[];

  selected_category:ProductCategories=new ProductCategories();
  categories=[];

  item_selected:Product=new Product();

  fileToUpload: File = null;
  constructor(
    private productcategoriesService: ProductcategoriesService,

    private productsService: ProductsService,
    private uploadService:UploadService,

    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    this.productcategoriesService.getCategories().subscribe((result)=>{
      
      let categories=[];

      for(let c of result){
        let category:ProductCategories=new ProductCategories();
        Object.assign(category,c);
        categories.push(category);
      }
      
      this.categories=categories;
      if(this.categories.length>0)
        this.selected_category=this.categories[0];

        this.getProducts()
    })
   
  }

  getProducts(){
    this.productsService.getCategory(this.selected_category.id).subscribe((result)=>{
       //converti nella classe Product
       let products=[];
       for(let item of result){
         let product:Product=new Product();
         Object.assign(product,item);
         products.push(product);
       }
       this.list=products;
    })
  }

  openModalEdit (record=null){
    Object.assign(this.item_selected,record)
    this.modalEditRef=this.modalService.open(this.modalEdit,{size:"lg"});
  }

  closeModalEdit(){
    this.modalEditRef.close();
  }

  newRecord(){
    this.item_selected=new Product();
    this.item_selected.id_category=this.selected_category.id;
    this.item_selected.tax=this.selected_category.tax;

    
    this.openModalEdit(this.item_selected);
  }


  delete(item){
    Globals.modal.showConfirm("Sei sicuro di voler eliminare l'articolo "+item.name+"?","",()=>{
      this.productsService.delete([item.id]).subscribe(()=>{
        this.getProducts();
      })
    },"Elimina","Annulla","danger")
  }


  save() {
    this.productsService.save(this.item_selected,()=>{
      this.closeModalEdit();
      this.getProducts();
    })
  }

  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"products",(result)=>{

      let i:Image=new Image();
      i.file=result;

      this.item_selected.images.push(i);
      Globals.setLoading(false);
     
    },true);
}


removeImage(img:Image){
  for(let i=0;i<this.item_selected.images.length;i++){
    if(img==this.item_selected.images[i]){
      this.item_selected.images.splice(i,1);
      return;
    }
  }
}


}
