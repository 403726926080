<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filterbox">
            <button  title="Aggiungi" (click)="wizard()" class="btn btn-block btn-success d-none d-lg-block my-2"><i class="fas fa-plus mr-2"></i><span class="">Nuovo biglietto</span></button>
            <button  title="Aggiungi" (click)="wizard()" class="btn btn-success btn-circle btn-lg d-block d-lg-none" style="position:fixed;z-index:999;bottom:1.5rem;right:1rem"><i class="fas fa-plus"></i></button>
            <small>
                <app-filterdate [future]="true" [date_type]="98" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
                <ul class="card small-box list-group p-1 mt-lg-2">
                    <li class="list-group-item" [class.active]="filter_id_state.value==''" (click)="filter_id_state.value='';getItems()" style="cursor:pointer;">Tutti gli stati</li>
                    <li class="list-group-item" [class.active]="filter_id_state.value=='1'" (click)="filter_id_state.value='1';getItems()" style="cursor:pointer;">In attesa del pagamento</li>
                    <li class="list-group-item" [class.active]="filter_id_state.value=='2'" (click)="filter_id_state.value='2';getItems()" style="cursor:pointer;">Confermati</li>
                    <li class="list-group-item" [class.active]="filter_id_state.value=='3'" (click)="filter_id_state.value='3';getItems()" style="cursor:pointer;">Annullati</li>
                    <li class="list-group-item" [class.active]="filter_id_state.value=='5'" (click)="filter_id_state.value='5';getItems()" style="cursor:pointer;">Da riprenotare</li>
                    <div class="icon"><i class="fas fa-check"></i></div>
                </ul>
                <ul class="card small-box list-group p-1 mt-lg-2" >
                    <li class="list-group-item" [class.active]="filter_source.value==''" (click)="filter_source.value='';getItems()" style="cursor:pointer;">Tutte le origini</li>
                    <li class="list-group-item" [class.active]="filter_source.value=='telephone'" (click)="filter_source.value='telephone';getItems()" style="cursor:pointer;">Telefono</li>
                    <li class="list-group-item" [class.active]="filter_source.value=='webapp'" (click)="filter_source.value='webapp';getItems()" style="cursor:pointer;">WebApp</li>
                    <div class="icon"><i class="fas fa-phone-alt"></i></div>
                </ul>
                <div class="card small-box p-1 mb-0 my-lg-2 bg-primary">
                    <i>Carta di default</i>
                    <select class="form-control form-control-sm" [(ngModel)]="id_card_default" (change)="setCard($event)">
                        <option *ngFor="let c of cards" value="{{c.id}}">{{c.name}} {{c.cardnumber}}</option>
                    </select>
                </div>
                <ul class="card small-box list-group p-1 mt-lg-2" >
                    <li class="list-group-item" [class.active]="filter_company.value==''" (click)="filter_company.value='';getItems()" style="cursor:pointer;">Tutte le compagnie</li>
                    <li class="list-group-item"  *ngFor="let c of companies" [class.active]="filter_company.value==c.id" (click)="filter_company.value=c.id;getItems()" style="cursor:pointer;">{{c.name}}</li>
                    <div class="icon"><i class="fas fa-bus-alt"></i></div>
                </ul>
            </small>
        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" class="mt-lg-2">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="input-group input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per Ragione sociale, indirizzo, comune, P.IVA, codice fiscale ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-7">
                            <div class="input-group input-group-sm p-1" >
                                <select class="form-control form-control-sm" [(ngModel)]="filter_id_user.value" (ngModelChange)="getItems()">
                                    <option value="">Tutti</option>
                                    <ng-container *ngFor="let u of users">
                                        <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 col-5 text-right py-1">
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-sm float-right mr-1"><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-sm float-right mr-1"><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-sm float-right mr-1"><i class="fas fa-undo"></i></button>
                            <button  title="Esporta" (click)="export()"  class="btn btn-secondary btn-sm float-right mr-1"><i class="fas fa-file-export"></i></button>
                            <!-- <button  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-sm float-right mr-1"><i class="fas fa-plus"></i></button> -->
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                        <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <tr>
                                    <th>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox"
                                            (change)="onChangeAll($event.target.checked)" />
                                        </div>
                                    </th>
                                    <th *ngIf="id_address==0" style="min-width:200px;">
                                        Cliente
                                        <button class="btn btn-tool" (click)="switchOrdering('a.lastname')">
                                            <i class="fas fa-sort {{classOrdering('a.lastname')}}"  ></i>
                                        </button>
                                    </th>
                                    <th>
                                        Biglietto
                                    </th>
                                    <th class="text-center">Compagnia</th>
                                    <th class="text-right" style="min-width: 120px;">
                                        Costo
                                        <button class="btn btn-tool" (click)="switchOrdering('t.cost')">
                                            <i class="fas fa-sort {{classOrdering('t.cost')}}"  ></i>
                                        </button>
                                    </th>
                                    <th class="text-center">
                                        Stato
                                        <button class="btn btn-tool" (click)="switchOrdering('t.status')">
                                            <i class="fas fa-sort {{classOrdering('t.status')}}"  ></i>
                                        </button>
                                    </th>
                                    <th>Aggiunto</th>
                                    <th style="min-width: 120px;"></th>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td><b>TOTALE</b></td>
                                    <td></td>
                                    <td></td>
                                    <td class="text-right">
                                        <b>{{total | currency:'EUR':true }}</b>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                            
                                <!-- elemento ripetuto -->
                                <tr [class.alert-danger]="expired(ticket)" *ngFor="let ticket of list">
                                    <td  title="{{ticket.date | date:'EEEE'}}">
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{ticket.id}}"
                                            (change)="onChange(ticket.id, $event.target.checked)" [checked]="checkedAll" />
                                        </div>
                                    </td>
                                    <td *ngIf="id_address==0">
                                        <a (click)="openAddress(ticket.id_address)" style="cursor:pointer;"><i class="fa fa-user"></i></a>
                                        <a (click)="openDetail(ticket)" style="cursor:pointer;">
                                            <b>{{ticket.addressItem.lastname | uppercase}}</b> {{ticket.addressItem.name | uppercase}}
                                        </a>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="mr-3" *ngFor="let t of ticket.tickets">
                                                <i class="fa fa-bus mr-2"></i><b>{{t.port_from}}</b> - <b>{{t.port_to}}</b><br>
                                                <div class="float-left mr-2">
                                                    <i class="fa fa-calendar-alt mr-2"></i><span class="">{{t.date| date: 'dd-M-yyyy'}}</span><br/>
                                                    <i class="far fa-clock mr-2"></i><span class="">{{t.time_from}} - {{t.time_to}}</span>
                                                </div>
                                                <button *ngIf="t.file!=''" class="btn btn-sm btn-primary float-left" (click)="downloadPDF(t)"  title="Scarica biglietto">
                                                    <i class="fas fa-download"></i></button>
                                                <small *ngIf="t.id_state==10" class="text-primary ml-1">Scaricamento in corso...</small>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <img *ngIf="ticket.companylogo" src="{{ticket.companylogo}}" style="max-height:40px">
                                        <div *ngIf="!ticket.companylogo" style="max-width:120px" class="bg-gray p-3">{{ticket.companyname}}</div>
                                    </td>
                                    <td class="text-right">{{ ticket.cost | currency:'EUR':true}}</td>
                                    <td class="text-center">
                                        <ng-container *ngIf="expired(ticket)">
                                            <small >Scaduto</small>
                                        </ng-container>
                                        <ng-container *ngIf="!expired(ticket)">
                                            <button type="button" *ngIf="ticket.id_state==1" class="btn btn-sm btn-warning" (click)="openConfirmModal(ticket)"  title="Conferma biglietto">
                                                <i *ngIf="ticket.blocked" class="fas fa-lock"></i>
                                                <i *ngIf="!ticket.blocked" class="fas fa-unlock"></i>
                                                &nbsp;
                                                Conferma<br/>
                                            </button>
                                            <br/>
                                            <ng-container *ngIf="ticket.blocked && ticket.id_state==1">
                                                <small>posti bloccati il {{ticket.blocked_time | date:"dd/MM/YY HH:mm"}}</small><br/>
                                            </ng-container>
                                            <i *ngIf="ticket.id_state==2" class="fas fa-2x fa-check text-success"></i>
                                            <i *ngIf="ticket.id_state==3" class="fas fa-2x fa-times text-danger"></i>
                                            <i *ngIf="ticket.id_state==5" class="fas fa-2x fa-times text-danger"></i>
                                            <small *ngIf="ticket.id_state==5"><br>da riprenotare</small>
                                            <small *ngIf="ticket.id_state==9"><br>conferma in corso...</small>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <i>{{ticket.username}}</i><br/>
                                        <small>{{ticket.created | date:'dd/MM/yyyy H:m'}}</small><br/>
                                        <small>{{ticket.source}}</small>
                                    </td>
                                    <td>
                                        <button *ngIf="mode!='modal'" (click)="openDetail(ticket)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                            <i class="fas fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="notify(ticket);" title="Notifica" class="btn btn-primary btn-xs float-right m-1">
                                            <i class="far fa-paper-plane"></i>
                                        </button>
                                        <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(ticket);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </button>
                                        <button *ngIf="ticket.id_state==1 && !expired(ticket)" class="btn btn-xs btn-warning float-right m-1" (click)="bookTicket(ticket)"  title="Blocca posti">
                                            <i class="fas fa-lock"></i></button>
                                       
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td colspan="2"><b>TOTALE</b></td>
                                    <td></td>
                                    <td class="text-right">
                                        <b>{{total | currency:'EUR':true }}</b>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>

                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>


<ng-template #modalPay let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Paga online</h4>
        </div> 
    <div class="modal-body">
        <table class="table">
            <tr>
                <td>Cliente</td>
                <td><strong>{{selectedTicket.addressItem.name}}</strong></td>
            </tr>
            <tr>
                <td>Viaggio</td>
                <td>
                    <ul *ngFor="let t of selectedTicket.tickets">
                        <li>{{t.port_from}} - {{t.port_to}} del {{t.date | date:"d/M/Y"}}</li>
                    </ul>
                </td>
            </tr>
            <tr>
                <td>Costo</td>
                <td>
                    <strong>Costo {{selectedTicket.cost | currency:"&euro;"}}</strong>
                </td>
            </tr>
        </table>
        <app-paymentmethod [btn_pay]="true" [ticket]="selectedTicket" [amount]="selectedTicket.cost" (onPaymentComplete)="paymentComplete($event)"></app-paymentmethod>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-primary" (click)="pay();">Paga ora {{selectedTicket.cost | currency:" &euro;"}}</button>
        <button class="btn btn-sm btn-secondary" (click)="closeModalPay();">Annulla</button>
    </div>
</ng-template>


<ng-template #modalUpload let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Conferma biglietti</h4>
    </div> 
    <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <label>Metodo di pagamento</label>
                    <select class="form-control" name="method_payment" [(ngModel)]="selectedTicket.method_payment" >
                        <option value="postepay" selected="selected">Ricarica Postepay</option>
                        <option value="stripe">Carta (stripe)</option>
                        <!--<option value="Pagonline">Carta di credito (PagoOnline)</option>-->
                    </select>
                </div>
            </div>
            <div  class="row mt-4" *ngIf="selectedTicket.blocked==0">
                <ng-container *ngFor="let t of selectedTicket.tickets;let i=index">
                    <div class="col-12" >
                        <div class="row">
                            <h5>Biglietto {{t.port_from}} {{t.port_to}} del {{t.date | date:"d/M/Y"}}</h5>
                            <div class="col-md-12">
                                <label>PNR</label>
                                <input class="form-control" name="{{t.id}}_pnr" type="text"  [(ngModel)]="t.pnr" >
                            </div>
        
                            <div class="col-md-12">
                                <label>Posti scelti</label>
                                <input class="form-control" name="{{t.id}}_seats" type="text"  [(ngModel)]="t.seats" >
                            </div>
        
                            <div class="col-md-12">
                                <label>PDF biglietto</label>
                                <input type="file"  #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files,t.id)">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="col-md-6" *ngIf="selectedTicket.tickets.length==2">
                    <div  class="row" >
                        <h5>Biglietto di ritorno</h5>
                        <div class="col-md-12">
                            <label>PNR</label>
                            <input class="form-control" #pnr2 type="text"  formControlName="pnr2" >
                        </div>
                        <div class="col-md-12">
                            <label>Posti scelti</label>
                            <input class="form-control" #seats2 type="text"  formControlName="seats2" >
                        </div>
    
                        <div class="col-md-12">
                            <label>PDF biglietto</label>
                            <input             
                                formControlName="file2"
                                id="file2" 
                                type="file" 
                                class="form-control"
                                (change)="onFileSelect2($event)" />
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div class="modal-footer">
        <input class="btn btn-success"  (click)="onConfirm()" value="Conferma"  />
        <button type="button" class="btn btn-light" (click)="closeModalUpload()">Annulla</button>
    </div>
</ng-template>