<ul class="small-box list-group {{class}}">
    <!-- <li *ngIf="title" class="list-group-item bg-info py-1 mb-1">{{title}}</li> -->
    <li class="list-group-item border py-1  text-center mb-1" [class.active]="date_type==99">
        <div *ngIf="title">{{title}}</div>
        <div [class.border-bottom]="period" class="input-group input-group-sm border-bottom">
            <div class="input-group-prepend input-group-prepend-sm">
                <span *ngIf="period" style="width: 1.5rem;" (dblclick)="filter_date.value='2000-01-01';date_type=99;update()" class="py-1"><small >Dal</small></span>
            </div>
            <input class="form-control form-control-sm border-0" title="Selezionare la data di inizio ricerca" type="date" [(ngModel)]="filter_date.value" name="date_from" (focusout)="date_type=99;update()" (key.enter)="date_type=99;update()" >
            <div class="input-group-append">
                <!-- <span class="btn btn-sm bg-white" title="Cerca dall'inizio" (click)="filter_date.value='2000-01-01';date_type=99;update()"><i class="fas fa-level-down-alt"></i></span> -->
                <span [class.text-light]="date_type==99" class="btn pr-1 btn-white" title="Cerca solo questo giorno" (click)="filter_date.value2=filter_date.value;date_type=99;update()"><i class="fas fa-search"></i></span>
            </div>
        </div>
        <div class="input-group input-group-sm" *ngIf="period">
            <div class="input-group-prepend input-group-prepend-sm">
                <span style="width: 1.5rem;" (dblclick)="filter_date.value2='2999-12-31';date_type=99;update()" class="py-1"><small>Al</small></span>
            </div>
            <input class="form-control form-control-sm border-0" title="Selezionare la data di fine ricerca" type="date" [(ngModel)]="filter_date.value2"  name="date_to" (focusout)="date_type==99;update()" (key.enter)="date_type=99;update()">
            <div class="input-group-append">
                <!-- <span class="btn btn-sm bg-white" title="Cerca fino alla fine" (click)="filter_date.value2='2999-12-31';date_type=99;update()"><i class="fas fa-level-up-alt"></i></span> -->
                <span [class.text-light]="date_type==99" class="btn pr-1 btn-white" title="Cerca periodo" (click)="date_type=99;update()"><i class="fas fa-search-plus"></i></span>
            </div>
        </div>
        <!-- <i class="fas fa-search" (click)="date_type=99;update()" title="Avvia ricerca" style="position: absolute; padding: 1.5rem 0.25rem; right: 0; top: 0.25rem; cursor: pointer;"></i> -->
    </li>
    <li class="d-none d-lg-block list-group-item pointer" [class.active]="date_type==100" (click)="setDate(100)">Tutto</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="past" [class.active]="date_type==6" (click)="setDate(6)">Fino ad oggi</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="past" [class.active]="date_type==5" (click)="setDate(5)">Ultimi 90 gg</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="past" [class.active]="date_type==4" (click)="setDate(4)">Ultimi 30 gg</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="past" [class.active]="date_type==3" (click)="setDate(3)">Ultimi 7 gg</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="past" [class.active]="date_type==2" (click)="setDate(2)">Ieri</li>
    <li class="d-none d-lg-block list-group-item pointer" [class.active]="date_type==1" (click)="setDate(1)">Oggi</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="future" [class.active]="date_type==7" (click)="setDate(7)">Domani</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="future" [class.active]="date_type==8" (click)="setDate(8)">Fino a 7 gg</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="future" [class.active]="date_type==9" (click)="setDate(9)">Fino a 30 gg</li>
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="future" [class.active]="date_type==10" (click)="setDate(10)">Fino a 90 gg</li>
    
    <li class="d-none d-lg-block list-group-item pointer" *ngIf="future" [class.active]="date_type==11" (click)="setDate(11)">Tutti i successivi</li>
    <li class="d-none d-lg-block list-group-item pointer" [class.active]="date_type==98" (click)="date_type==98"><span (click)="setDate(98)">Anno<i *ngIf="date_type!=98" class="fas fa-chevron-down ml-1"></i></span>
        <div *ngIf="date_type==98">
            <select class="form-control form-control-sm" [(ngModel)]="year" name="year" (change)="setDate(98);">
                <option *ngFor="let y of years" [ngValue]="y">{{y}}</option>
            </select>
        </div>
    </li>

    <li class="d-none d-lg-block list-group-item pointer" [class.active]="date_type==97" (click)="date_type==97"><span (click)="setDate(97)">Mese<i *ngIf="date_type!=97" class="fas fa-chevron-down ml-1"></i></span>
        <div *ngIf="date_type==97">
            <select class="form-control form-control-sm" [(ngModel)]="month" name="month" (change)="setDate(97);">
                <option [ngValue]="0">gennaio</option>
                <option [ngValue]="1">febbraio</option>
                <option [ngValue]="2">marzo</option>
                <option [ngValue]="3">aprile</option>
                <option [ngValue]="4">maggio</option>
                <option [ngValue]="5">giugno</option>
                <option [ngValue]="6">luglio</option>
                <option [ngValue]="7">agosto</option>
                <option [ngValue]="8">settembre</option>
                <option [ngValue]="9">ottobre</option>
                <option [ngValue]="10">novembre</option>
                <option [ngValue]="11">dicembre</option>
            </select>
        </div>
    </li>

<!--
    <li class="list-group-item pointer" [class.active]="date_type==99" (click)="setDate(99)">Personalizza
        <div *ngIf="date_type==99">
            <input class="form-control form-control-sm" type="date" [(ngModel)]="filter_date.value" name="date_from"  >
            <input class="form-control form-control-sm" type="date" [(ngModel)]="filter_date.value2" >
            <button class="btn btn-sm btn-secondary mt-1 float-right" (click)="update()">Applica</button>
        </div>
    </li>
-->

    <div class="d-none d-lg-block icon"><i class="far fa-calendar-alt"></i></div>
</ul>
