import { Component, OnInit } from '@angular/core';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { ProductsService } from 'projects/c1-backend/src/app/services/products.service';

@Component({
  selector: 'setting-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class SettingGeneralComponent implements OnInit {


  covercharge:Product=new Product();

  constructor(
    private productsService: ProductsService
  ) { }

  ngOnInit(): void {
    this.covercharge.codeinternal="COVERCHARGE";
    this.covercharge.name="Coperto";
    this.covercharge.id_brand=0;
    this.covercharge.id_category=0;
    this.covercharge.tax=10;
    this.getCoverCharge();
  }


  getCoverCharge(){
    this.productsService.getProductsByCodeInternal("COVERCHARGE").subscribe((results)=>{
      
      if(results.length > 0){
        let p:Product=new Product();
        Object.assign(p,results[0]);
        this.covercharge =p;
     
      }
      
    })
  }


  save(){
    this.productsService.save(this.covercharge,()=>{

    });
  }

}
