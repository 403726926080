import { Injectable } from '@angular/core';
import { WSService } from 'projects/core/src/include/service';
import { Order } from '../restaurantcashregister';

@Injectable({
  providedIn: 'root'
})
export class RestaurantordersService extends WSService<Order> {

  constructor() { super("restaurantorders")}
}
