import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist';
import { RestauranttablesService } from 'projects/modules/src/app/restaurantcashregister/services/restauranttables.service';
import { TableDetailComponent } from './detail/detail.component';
import { Helper } from 'projects/core/src/helper';
import { RestaurantroomsService } from 'projects/modules/src/app/restaurantcashregister/services/restaurantrooms.service';
import { Order } from 'projects/modules/src/app/restaurantcashregister/restaurantcashregister';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent extends ModelList<any> implements OnInit {

  constructor(
    private restaurantroomsService: RestaurantroomsService,
    private restauranttablesService: RestauranttablesService,
    private fb1: FormBuilder,
  ) {
    super(restauranttablesService,fb1);
    this.detailViewModal=TableDetailComponent
  }

  tables=[];
  rooms=[];

  selected_id_room=1;
  filter_id_state=0;

  ngOnInit(): void {

    this.restaurantroomsService.getRooms().subscribe((result)=>{
      this.rooms=result;
      this.getTableOrders();
    });

    
  }

  getTableOrders(){
    this.restauranttablesService.getTableOrders(this.selected_id_room,Helper.convertDateControl(new Date()),this.filter_id_state).subscribe((result)=>{
      this.tables=result;
    })
  }


  openOrder(tableOrder){
    if(tableOrder.order){
      this.openDetail(tableOrder.order,{"name":"id_restaurant_table","value":tableOrder.id},()=>{
        this.getTableOrders();
      },'xxl');
    }else{

     


      this.openDetail(null,[{"name":"id_restaurant_table","value":tableOrder.id},{"name":"tablename","value":tableOrder.title}],()=>{
        this.getTableOrders();
      },'xxl'); //nuovo record

    }
  }
}
