import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'lib-confirmmodal',
  templateUrl: './confirmmodal.component.html',
  styleUrls: ['./confirmmodal.component.css']
})
export class ConfirmmodalComponent implements OnInit {

  @Input() public title = "";

  @Input() public description = "";

  //@Input() public child:Modal;

  @Input() public class:string="";

  // @Input() public showConfirmButton=true;

  @Input() public lblConfirmButton="Ok";
  @Input() public lblCancelButton="Annulla";
  @Input() public confirmButtonType="success";
  
  // @Input() public mode="confirm";

  constructor() { }

  ngOnInit(): void {
  }

  close(reason="cancel"){
    this['modalWindow'].close(reason);
  }

}
