import { Component, OnInit, TemplateRef, ViewChild  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { Tables } from 'projects/modules/src/app/restaurantcashregister/restaurantcashregister';
import { RestaurantroomsService } from 'projects/modules/src/app/restaurantcashregister/services/restaurantrooms.service';
import { RestauranttablesService } from 'projects/modules/src/app/restaurantcashregister/services/restauranttables.service';

@Component({
  selector: 'setting-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class SettingTablesComponent implements OnInit {

  @ViewChild("modalEdit")
  modalEdit:TemplateRef<any>;
  modalEditRef;

  list=[];
  rooms=[];

  selected_id_room=1;
  item_selected:Tables=new Tables();

  constructor(
    private tableService: RestauranttablesService,
    private roomService:RestaurantroomsService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    this.roomService.getRooms().subscribe((result)=>{
      this.rooms=result;
      if(this.rooms.length>0)
        this.selected_id_room=this.rooms[0].id;
    })
   this.getTables()

  }
  

  getTables(){
    this.tableService.getTables(this.selected_id_room).subscribe((result)=>{
      this.list=result;
    })
  }

  openModalEdit (record=null){
    Object.assign(this.item_selected,record)
    this.modalEditRef=this.modalService.open(this.modalEdit,{size:"lg"});
  }

  closeModalEdit(){
    this.modalEditRef.close();
  }

  newRecord(){
    this.item_selected=new Tables();
    this.item_selected.id_room=this.selected_id_room;
    this.openModalEdit(this.item_selected);
  }


  delete(item){
    Globals.modal.showConfirm("Sei sicuro di voler eliminare il tavolo "+item.title+"?","",()=>{
      this.tableService.delete([item.id]).subscribe(()=>{
        this.getTables();
      })
    },"Elimina","Annulla","danger")
  }


  save() {
    this.tableService.save(this.item_selected,()=>{
      this.closeModalEdit();
      this.getTables();
    })
  }

}
