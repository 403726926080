<app-windowlist [model]="this">

    <div class="card card-outline mt-1">
        <div class="p-0">
            <div class="row" >
                <div class="col-9">
                    <div class="input-group input-group-info input-group-sm p-1">
                        <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="nome cliente o descrizione" [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                        <div class="input-group-append ">
                            <button type="button" class="btn btn-default"  title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <button  (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i></button>
                    <button  (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i></button>
                    <button (click)="openDetail(null)"  class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                </div>
            </div>
            
            <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
            </div>
            <form *ngIf="list && list.length>0" [formGroup]="form">
                <table class="table table-nowrap table-sm table-striped table-hover m-0 text-sm">
                    <thead class="bg-light">
                        <th width="20px">
                            <input #checkboxselectall type="checkbox"
                            (change)="onChangeAll($event.target.checked)" />
                        </th>
                        <th>Nome</th>
                        <th>Tabella di riferimento</th>
                        <th></th>
                    </thead>
                    <tbody>
                    
                        <!-- elemento ripetuto -->
                        <ng-container *ngFor="let record of list">
                            <tr>
                                <td>
                                    <input type="checkbox" #checkrecord id_record="{{record.id}}"  title="{{record.id}}"
                                    (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                    />
                                </td>
                                <td>{{record.name}}</td>
                                <td>{{record.table}}</td>
                                <td>
                                    <button type="button" *ngIf="mode!='modal'"  (click)="openDetail(record)" routerLinkActive="active"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                        <i class="fas fa-pencil-alt"></i></button>
                                </td>
                            </tr>
                        </ng-container>

                    </tbody>
                </table>
            </form>
        </div>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>
        </div>
       
    </div>

</app-windowlist>
