<div class="row my-1">
  <div class="col-lg-2 pr-lg-0" *ngIf="filterbox">
    <div class="row">
        <div class="col-4 col-lg-12">
            <small>
                <app-treecategories [class]="'card p-1'" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [id]="filter_id_category.value" [showCountProduct]="false" [editButton]="false" #tree [list]="categories"></app-treecategories>
                <ul class="card small-box list-group p-1 mt-2">
                    <li class="list-group-item pointer" [class.active]="filter_id_inventory.value==''" (click)="filter_id_inventory.value='';getItems()">
                        Tutti i magazzini<span  *ngIf="user.isSuperUser()" class="float-right" (click)="openInventoryManager()"><i class="fa fa-cog"></i></span></li>
                    <li class="list-group-item pointer" [class.active]="filter_id_inventory.value==i.id" *ngFor="let i of inventories" (click)="filter_id_inventory.value=i.id;getItems()">{{i.name}}</li>
                    <div *ngIf="shops && shops.length>2" class="icon"><i class="fas fa-boxes"></i></div>
                </ul>
            </small>
        </div>
        <div class="col-4 col-lg-12 mt-2">
            <small>
                <app-filterdate [date_type]="100" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
            </small>
        </div>
        <div class="col-4 col-lg-12 mt-2">
            <small>
                <ul class="card small-box list-group p-1">
                    <li class="list-group-item pointer" [class.active]="filter_type.value==''" (click)="filter_type.value='';getItems()">
                        Carico + Scarico<span *ngIf="filter_type.value==''" class="float-right" (click)="openInventoryManager()"><i class="fa fa-cog"></i></span></li>
                    <li class="list-group-item pointer" [class.active]="filter_type.value=='1'" (click)="filter_type.value='1';getItems()">Solo Carico</li>
                    <li class="list-group-item pointer" [class.active]="filter_type.value=='2'" (click)="filter_type.value='2';getItems()">Solo Scarico</li>
                    <div class="icon"><i class="fas fa-exchange-alt"></i></div>
                </ul>
            </small>
        </div>
        <div class="col-4 col-lg-12 mt-2">
            <small>
                <ul class="card small-box list-group p-1">
                    <li class="list-group-item pointer" [class.active]="filter_table.value==' '" (click)="filter_table.value=' ';getItems()">Tutte i riferimenti</li>
                    <li class="list-group-item pointer" *ngIf="isModuleEnabled('documents')" [class.active]="filter_table.value=='documents'" (click)="filter_table.value='documents';filter_typedocument.enabled=true;getItems()">Documenti</li>
                    <li class="list-group-item pointer" *ngIf="isModuleEnabled('booking')" [class.active]="filter_table.value=='booking'" (click)="filter_table.value='booking';filter_typedocument.enabled=false;getItems()">Prenotazioni</li>
                    <li class="list-group-item pointer" *ngIf="isModuleEnabled('address')" [class.active]="filter_table.value=='addresses'" (click)="filter_table.value='addresses';filter_typedocument.enabled=false;getItems()">Anagrafica</li>
                    <li class="list-group-item pointer" *ngIf="isModuleEnabled('installations')" [class.active]="filter_table.value=='installations'" (click)="filter_table.value='installations';filter_typedocument.enabled=false;getItems()">Seriali</li>
                    <li class="list-group-item pointer" [class.active]="filter_table.value==''" (click)="filter_table.value='';getItems()">Nessun riferimento</li>
                    <div class="icon"><i class="far fa-file"></i></div>
                </ul>
            </small>

            <small *ngIf="filter_typedocument.enabled">
                <ul class="card small-box list-group p-1">
                    <li class="list-group-item pointer" [class.active]="filter_typedocument.value==''" (click)="filter_typedocument.value='';getItems()">Tutti i documenti</li>
                    
                    <li class="list-group-item pointer"  (click)="setFilterTypeValue('filter_typedocument',1);setFilterTypeValue('filter_typedocument',2);setFilterTypeValue('filter_typedocument',12)">Fattura</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',1)" (click)="setFilterTypeValue('filter_typedocument',1)">-- Fattura semplice</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',2)" (click)="setFilterTypeValue('filter_typedocument',2)">-- Fattura accompagnatoria</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',12)" (click)="setFilterTypeValue('filter_typedocument',12)">-- Fattura acconto</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',3)" (click)="setFilterTypeValue('filter_typedocument',3)">Nota di credito</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',4)" (click)="setFilterTypeValue('filter_typedocument',4)">Preventivo</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',5)" (click)="setFilterTypeValue('filter_typedocument',5)">DDT</li>
                    <!--<li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',10)" (click)="setFilterTypeValue('filter_typedocument',10)">Trasferimento</li>-->
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',6)" (click)="setFilterTypeValue('filter_typedocument',6)">Scontrino</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',7)" (click)="setFilterTypeValue('filter_typedocument',7)">Ordine Cliente</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',8)" (click)="setFilterTypeValue('filter_typedocument',8)">Ordine Fornitore</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',9)" (click)="setFilterTypeValue('filter_typedocument',9)">Non fiscale</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',11)" (click)="setFilterTypeValue('filter_typedocument',11)">Amministrativo</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',10)" (click)="setFilterTypeValue('filter_typedocument',10)">Trasferimento</li>
                    <li class="list-group-item pointer" [class.active]="checkFilterTypeValue('filter_typedocument',13)" (click)="setFilterTypeValue('filter_typedocument',13)">Inventario</li>


                    
                    <div class="icon"><i class="far fa-document"></i></div>
                </ul>
            </small>

        </div>
    </div>
  </div>
  <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
    <div class="card card-outline">
        <div class="p-0">
            <div class="row" >
                <div class="col-lg-4" >
                    <div *ngIf="filterbox" class="input-group input-group-info input-group-sm p-1">
                        <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome prodotto o seriale" [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                        <div class="input-group-append ">
                            <button type="submit" class="btn btn-default"  title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                            <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 pl-0">
                    <div class="input-group input-group-sm m-1" >
                        <select class="form-control form-control-sm" [(ngModel)]="filter_product_id_type.value" (ngModelChange)="getItems()" [class.alert-info]="filter_product_id_type.value">
                            <option value="">Tutte le tipologie</option>
                            <option *ngFor=" let r of type_products" [ngValue]="r.id">{{r.name}}</option>
                        </select>
                        
                    </div>
                </div>

                <div class="col-lg-3">
                    <select class="form-control form-control-sm m-1" [(ngModel)]="filter_id_user.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_user.value">
                        <option value="">Tutti gli operatori</option>
                        <ng-container *ngFor="let u of users">
                            <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-3" >
                    <div class="dropdown dropleft show"  *ngIf="user.isAdmin()">
                        <a class="btn btn-default btn-xs float-right mr-1 mt-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" title="Altre operazioni" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                        </a>
                        <div class="dropdown-menu py-0 bg-light" aria-labelledby="dropdownMenuLink">
                            <a type="button"   class="btn btn-xs dropdown-item" (click)="createInventoryDocument()">Crea documento inventario</a>
                            <!--<a type="button" class="btn btn-xs dropdown-item" (click)="insertToDocument()">Inserisci in un documento inventario</a>-->
                        </div>
                    </div>

                    <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1 mr-1"><i class="far fa-trash-alt"></i></button>
                    <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right  ml-1 mt-1"><i class="fas fa-trash"></i></button>
                    <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                    <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                    <button (click)="openDetail(null,[{'name':'id_product','value':id_product},{'name':'sn','value':sn},{'name':'id_table','value':id_table},{'name':'table','value':table}])"  class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
        
                    <!-- <button *ngIf="id_product==0" (click)="load()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-upload"></i> Carica</button> -->
                    <!-- <button *ngIf="id_product==0" (click)="unload()" class="btn btn-danger btn-xs float-right m-1"><i class="fas fa-download"></i> Scarica</button> -->
                    <!--<button class="btn btn-success btn-xs float-right m-1" (click)="fastout()"><i class="fas fa-plus"></i></button>-->
                </div>
            </div>

            <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
            </div>
            <form *ngIf="list && list.length>0" [formGroup]="form">
                <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                    <thead class="bg-light">
                        <th width="20px">
                            <input #checkboxselectall  type="checkbox" 
                            (change)="onChangeAll($event.target.checked)" />
                        </th>
                        <th width="120px">
                            Data
                            <button class="btn btn-tool" (click)="switchOrdering('i.date')">
                                <i class="fas fa-sort {{classOrdering('i.date')}}"  ></i>
                            </button>
                        </th>
                        <th >
                            Prodotto
                            <button class="btn btn-tool" (click)="switchOrdering('i.reference')">
                                <i class="fas fa-sort {{classOrdering('i.reference')}}"  ></i>
                            </button>
                        </th>
                        <th class="text-center d-none d-sm-table-cell" width="70px">
                            QT
                            <button class="btn btn-tool" (click)="switchOrdering('i.typedocument')">
                                <i class="fas fa-sort {{classOrdering('i.typedocument')}}"  ></i>
                            </button>
                        </th >
                        <th class="d-none d-sm-table-cell">
                            Prezzo
                        </th>
                        <th class="text-center" width="120px">
                            Magazzino
                            <button class="btn btn-tool" (click)="switchOrdering('i.inventoryname')">
                                <i class="fas fa-sort {{classOrdering('i.amount')}}"  ></i>
                            </button>
                        </th>
                        <th *ngIf="id_product>0">Giacenza</th>
                        <th>Documento</th>
                        <th width="80px"></th>
                    </thead>
                    <thead>
                        <tr *ngIf="total!=null">
                            <td></td>
                            <td><b>TOTALE</b></td>
                            <td></td>
                            <td><span ><b>{{total.quantity}}</b></span></td>
                            <td colspan="4"><span ><b>{{total.amount | currency:"&euro; "}}</b></span></td>
                        </tr>
                    </thead>
                    <tbody>
                    
                        <!-- elemento ripetuto -->
                        <tr *ngFor="let inventory of list">
                            <td>
                                <input type="checkbox" #checkrecord id_record="{{inventory.id}}"
                                (change)="onChange(inventory.id, $event.target.checked)" [checked]="checkedAll" />
                            </td>
                            <td>
                                <a [class.trashed]="inventory.status == 2" (click)="openDetail(inventory)"  title="{{inventory.date | date:'EEEE'}}">
                                    {{inventory.date | date:'dd/M/yyyy'}}
                                </a><br>
                                <span *ngIf="inventory.type==1" title="Carico" class="badge badge-success"><i class="fa fa-upload mr-2"></i>carico</span>
                                <span *ngIf="inventory.type==2" title="Scarico" class="badge badge-danger"><i class="fa fa-download mr-2"></i>scarico</span>
                            </td>
                            <td>
                                <small title="ID prodotto" class="text-primary mr-1">({{inventory.id_product}})</small><br>
                                {{inventory.description}}<br>
                                <span *ngIf="inventory.sn" class="badge border mr-2">{{inventory.sn}}</span>
                                <i class="fa fa-pencil-alt pointer" title="Dettagli prodotto" (click)="openProduct(inventory.id_product)"></i>
                                <ng-container *ngIf="id_product==0">
                                    &nbsp;<i class="fa fa-boxes pointer"title="Cronologia"  (click)="openHistory(inventory.id_product)"></i>
                                </ng-container>
                                <br/><small>{{inventory.note}}</small>
                            </td>
                            <td class="text-center d-none d-sm-table-cell">
                                <span *ngIf="inventory.type==1">+</span>
                                <span *ngIf="inventory.type==2">-</span>
                                {{inventory.quantity}}
                            </td>
                            <td class="d-none d-sm-table-cell">
                                <span *ngIf="inventory.type==1">-</span>
                                <span *ngIf="inventory.type==2">+</span>
                                {{calculateTotalRow(inventory) | currency:"&euro; "}}
                            </td>
                            <td class="text-center">
                                {{inventory.inventoryname}}<br/>
                                <div class="badge border" *ngIf="inventory.username"><b>{{inventory.username}}</b></div>
                            </td>
                            <td *ngIf="id_product>0" class="text-center">
                                <h5 [class.text-success]="inventory.stock>-1" [class.text-danger]="inventory.stock < 0"> {{inventory.stock}}</h5></td>
                            <td>
                                <app-reference [reference]="inventory.reference" [table]="inventory.table"></app-reference>
                            </td>
                            <td>
                                <button type="button" (click)="openDetail(inventory)" title="Modifica / Vedi" class="btn btn-secondary btn-xs ml-1 mb-1 float-right">
                                    <i class="fas fa-pencil-alt"></i></button>
                                <button type="button" (click)="move(inventory)"  title="Sposta gli elementi selezionati in un altro magazzino" class="btn btn-primary btn-xs float-right ml-1 mb-1">
                                    <i class="fas fa-arrows-alt-h"></i></button>
                            </td>
                            
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr *ngIf="total!=null">
                            <td></td>
                            <td><b>TOTALE</b></td>
                            <td></td>
                            <td><span ><b>{{total.quantity}}</b></span></td>
                            <td colspan="4"><span ><b>{{total.amount | currency:"&euro; "}}</b></span></td>
                        </tr>
                    </tfoot>
                </table>
            </form>
            <div class="card-footer clearfix py-1">
                <app-pagination [model]="this" ></app-pagination>
            </div>

        </div>
    </div>
  </div>
</div>
<div class="modal-footer" *ngIf="modalWindow">
    <button class="btn btn-secondary" (click)="modalWindow.close()">Chiudi</button>
</div>