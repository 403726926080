<div class="card mb-0">
    <div class="card-header text-center bg-primary">
        <span class=" text-white m-auto">Seleziona da quale punto <b>{{projectname}}</b> stai operando</span>
    </div>
    <div class="card-body" *ngIf="list!=null && list.length>0">
        <ul class="list-group" *ngFor="let r of list">
            <li class="list-group-item mb-1 border pointer" (click)="confirm(r)">
                <div class="leaflet-marker-icon extra-marker extra-marker-circle-blue-dark ml-2 d-none d-lg-block"><i class="initials"></i></div>
                <div class="ml-lg-5">
                    <div style="max-width:80%;vertical-align: middle;" class="float-left text-truncate">
                        <div *ngIf="!r.city" class="py-1"></div>
                        <b class="text-primary">{{r.name}}</b><br>
                        <small *ngIf="r.city">{{r.city}}</small></div>
                    <div class="float-right">
                        <h3 class="mb-0 mt-1"><i class="text-light fas fa-angle-right"></i></h3>
                    </div>
                    <br>
                </div>
            </li>
        </ul>
    </div>
</div>
