<app-windowlist [model]="this">
    <div class="row selectPeriodCalendar">
        <div class="col-lg-12">
          <div class="p-2">
            <div class="row mb-2">
                <div class="col-12 text-center">
                    <div class="btn-group" role="group" aria-label="Visualizzazione">
                        <button type="button" class="btn btn-sm px-5 btn-primary" [class.bg-light]="modeView==2" [class.active]="modeView==1" (click)="modeView=1">Mensile</button>
                        <button type="button" class="btn btn-sm px-5 btn-primary" [class.bg-light]="modeView==1" (click)="modeView=2;getItemsByMonth()">Annuale</button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row" *ngIf="modeView==2">
                <div class="col-12">
                    <div class="row">
                        <div class="col-4"></div>
                        <div class="col-1 text-right">
                            <label>Anno</label>
                        </div>
                        <div class="col-2 text-center">
                            <input class="form-control form-control-sm" type="year" name="year" [(ngModel)]="year">
                        </div>
                        <div class="col-1">
                            <button class="btn" (click)="getItemsByMonth()"><i class="fa fa-search"></i></button>
                        </div>
                    </div>

                    <table class="table table-sm table-striped ">
                        <thead>
                            <th>Mese</th>
                            <th>Scadenze</th>
                            <th class="text-right">Totale</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of list_month">
                                <td style="vertical-align:middle">{{r.label}}</td>
                                <td>
                                    <ng-container>
                                        <div class="row" >
                                            <table class="table table-sm mb-0">
                                                <tr *ngFor="let d of r.deadlines" (click)="monthClicked(d);" class="pointer" title="Visualizza lista">
                                                    <td>
                                                        <small>
                                                            <div class="badge bg-gray border mr-1">{{d.count}}</div>{{d.type}}
                                                        </small>
                                                    </td>
                                                    <td  class="text-right"><small [class.text-danger]="d.total<0">{{d.total | currency:"&euro;"}}</small></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </ng-container>
                                </td>
                                <td class="text-right" style="vertical-align:middle">
                                    <b [class.text-danger]="r.total<0">{{r.total | currency:" &euro;"}}</b>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td><b>ANNO</b></td>
                            <td></td>
                            <td class="text-right"><b [class.text-danger]="total<0">{{total | currency:" &euro;"}}</b></td>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div class="row" *ngIf="modeView==1">
                <div class="col-4 btn-previous pr-0 text-right">
                    <div class="btn btn-sm btn-secondary float-right" mwlCalendarPreviousView [view]="view"  (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="text-light fas fa-angle-left"></i></div>
                    <div class="btn btn-sm btn-outline-secondary float-right px-5 mx-1" mwlCalendarToday [(viewDate)]="viewDate">Oggi</div>
                </div>
                <div class="col-4 pt-1 date text-center bg-light">
                    <b>{{ viewDate | calendarDate:(view + 'ViewTitle'):'It'}}</b>
                </div>
                <div class="col-4 btn-next pl-0 text-left">
                    <div class="btn btn-sm btn-secondary" mwlCalendarNextView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate"><i class="text-light fas fa-angle-right"></i></div>
                </div>
                <div class="col-12 mt-2 calendarView" title="Visualizza dettagli" style="cursor: pointer;">
                    <mwl-calendar-month-view-header ></mwl-calendar-month-view-header>
                    <mwl-calendar-month-view
                    [viewDate]="viewDate"
                    [events]="events"
                    [locale]="locale"
                    [cellTemplate]="customCellTemplate"
                    (dayClicked)="dayClicked($event.day)"  >
                    </mwl-calendar-month-view>
                </div>
            </div>
          </div>
        </div>
    </div>


    <ng-template  #customCellTemplate let-day="day" let-locale="locale">
            <span class="px-1 m-1 text-center text-lg">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            <ng-container *ngIf="day.events.length>0">
                <div class="p-1 border-bottom" *ngFor="let e of day.events" >
                    <div class="row">
                        <div [class.col-lg-7]="e.meta.total!=0" [class.col-12]="e.meta.total==0">
                            <small><div class="badge bg-gray border mr-1" *ngIf="e.meta.count>1">{{e.meta.count}}</div>{{e.meta.type}}</small>
                        </div>
                        <div class="col-lg-5 pl-lg-0 text-right" *ngIf="e.meta.total!=0">
                            <small [class.text-danger]="e.meta.total<0">{{e.meta.total | currency:"&euro;"}}</small>
                        </div>
                    </div>
                </div>
            </ng-container>
    </ng-template>
</app-windowlist>