<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filter_box">
            <small>
                <app-filterdate [date_type]="'100'" [future]="true" [class]="'card mt-2 p-1'" [filter_date]="filter_date" (onSelect)="updateList()" [period]="false" [start]="false" [title]="'In scadenza al'"></app-filterdate>
                <ul class="card small-box p-1 list-group mt-2">
                    <li class="list-group-item" [class.active]="filter_phase.value==''" (click)="filter_phase.value='';updateList()">Tutte le fasi</li>
                    <li class="list-group-item" [class.active]="filter_phase.value=='1'" (click)="filter_phase.value='1';updateList()">Attivo</li>
                    <li class="list-group-item" [class.active]="filter_phase.value=='2'" (click)="filter_phase.value='2';updateList()">Concluso</li>
                    <li class="list-group-item" [class.active]="filter_phase.value=='3'" (click)="filter_phase.value='3';updateList()">Annullato</li>
                </ul>
                <ul *ngIf="type_contract && type_contract.length>1" class="card small-box p-1 list-group mt-2 f{{type_contract.length}}">
                    <li class="list-group-item" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';updateList()">Tutte le tipologie</li>
                    <li class="list-group-item" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_contract" (click)="filter_id_type.value=t.id;updateList()"> {{t.name}}</li>
                </ul>
                <ul class="card small-box p-1 list-group my-2">
                    <li class="list-group-item" [class.active]="filter_no_installments.enabled==false" (click)="filter_no_installments.enabled=false;updateList()">Tutti contratti</li>
                    <li class="list-group-item" [class.active]="filter_no_installments.enabled==true" (click)="filter_no_installments.enabled=true;updateList()">Solo i contratti senza piano rate</li>
                </ul>
            </small>
        </div>
        <div [class.col-lg-10]="filter_box" [class.col-lg-12]="!filter_box" >
            <div class="card card-outline mt-2">
                <div class="p-0">
                    <div class="row">
                        <div class="col-9">
                            <div class="input-group input-group-sm m-1" >
                                <input type="text" name="table_search" class="form-control float-right" placeholder="Cerca per ubicazione o descrizione contratto..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="Annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-3 py-1" *ngIf="mode!='modal'">
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1 mr-1"><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                        <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-sm table-head-fixed table-hover text-nowrap text-sm m-0" *ngIf="list.length>0">
                            <thead class="bg-light">
                                <tr>
                                    <th>
                                        <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                            <input type="checkbox"
                                            (change)="onChangeAll($event.target.checked)" />
                                        </div>
                                        ID
                                    </th>
                                    <th>
                                        Tipologia
                                        <button class="btn btn-tool" (click)="switchOrdering('c.id_type')">
                                            <i class="fas fa-sort {{classOrdering('c.id_type')}}"  ></i>
                                        </button>
                                    </th>
                                    <th class="d-none d-sm-table-cell" *ngIf="id_shop==0">Ubicazione</th>
                                    <th class="text-center">Numero rate</th>
                                    <th class="text-right">Preventivato</th>
                                    <th class="text-right">Incassato</th>
                                    <th class="text-right">Da incassare</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td colspan="3"><b>TOTALE</b></td>
                                    <td class="text-right text-success">
                                        <b>{{total.total_deadlines | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-warning">
                                        <b>{{total.total_activities | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-danger">
                                        <b>{{total.total_topaid | currency:'EUR':true }}</b>
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                        {{record.id}}
                                    </td>
                                    <td>
                                        <i *ngIf="record.phase==1" class="fas fa-clock text-warning"></i>
                                        <i *ngIf="record.phase==2" class="fas fa-check text-success"></i>
                                        <i *ngIf="record.phase==3" class="fas fa-times text-danger"></i>
                                        <a class="ml-2" [class.trashed]="record.status == 2" (click)="openDetail(record)">
                                            <b>{{record.type}}</b></a>
                                        <br/>
                                        <small>{{record.start | date:'dd/M/yyyy'}} - {{record.end | date:'dd/M/yyyy'}}</small>
                                        <div class="d-lg-none">
                                            <small>{{record.start | date:'dd/M/yyyy'}} - {{record.end | date:'dd/M/yyyy'}}</small>
                                            <div *ngIf="record.phase==1 && testDate(record.end)" class="badge badge-warning">Scaduto</div>
                                        </div>
                                        <br/>
                                        <div class="badge badge-info" >{{record.gross | currency:"&euro;"}}/mese</div>
                                    </td>
                                    <td class="d-none d-sm-table-cell"  *ngIf="id_shop==0">{{record.shop.name}} </td>
                                    <td class="text-center">{{record.count_installments_activities.count}}/{{record.count_installments_deadlines.count}}</td>
                                    <td class="text-right text-success">{{record.count_installments_deadlines.total | currency:" &euro;"}}</td>
                                    <td class="text-right text-warning">{{record.count_installments_activities.total | currency:" &euro;"}}</td>
                                    <td class="text-right" [class.text-danger]="record.count_installments_deadlines.total-record.count_installments_activities.total>0">{{record.count_installments_deadlines.total-record.count_installments_activities.total | currency:" &euro;"}}</td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right m-1" (click)="selectRecord(record)">
                                            <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="notify(record)" title="Notifica" class="btn btn-primary btn-xs float-right ml-1">
                                            <i class="far fa-paper-plane"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="newform(record)" title="Modulo" class="btn btn-info btn-xs float-right ml-1">
                                            <i class="fab fa-wpforms"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td colspan="3"><b>TOTALE</b></td>
                                    <td class="text-right text-success">
                                        <b>{{total.total_deadlines | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-warning">
                                        <b>{{total.total_activities | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-danger">
                                        <b>{{total.total_topaid | currency:'EUR':true }}</b>
                                    </td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>