<app-windowlist [model]="this">
    <div class="card">
        <div class="row p-1">
            <div class="col-lg-4">
                <app-selecttables [name]="'filter_customfields'" [value]="filter_customfields.value" [show_all]="true" (onchange)="filter_customfields.value=$event;getItems()"></app-selecttables>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <button class="btn btn-success btn-sm float-right m-1" data-toggle="modal" data-target="#modalOptionCustomField" (click)="addCustomField()"><i class="fa fa-plus"></i></button>
            </div>
        </div>
        <div class="card-body p-0">
            <form>
                <table class="table table-sm table-head-fixed  text-nowrap m-0" >
                <thead>
                    <th>ID</th>
                    <th>Nome</th>
                    <th class="d-none d-sm-table-cell">Tipologia</th>
                    <th>Tabella</th>
                    <th>Ordine</th>
                    <th></th>
                </thead>
                <tbody>

                <!-- elemento ripetuto -->
                <tr *ngFor="let record of list" [class.font-weight-bold]="record.type=='separator'">
                    <td>
                        <i *ngIf="record.status==1" class="fa fa-check text-success"></i>
                        <i *ngIf="record.status==0" class="fas fa-times text-danger"></i>
                        {{record.id}}
                    </td>
                    <td>
                        {{record.label}}
                        <span class="badge badge-success" *ngIf="record.access=='superuser,admin,manager,agent'">Pubblico</span>
                        <span class="badge badge-warning" *ngIf="record.access=='superuser,admin'">Privato</span>
                        <div class="dlg-none pl-3 mt-3"><i>{{record.type}}</i></div>
                    </td>
                    <td class="d-none d-sm-table-cell">
                        <i>{{record.type}}</i>
                    </td>
                    <td>
                        {{record.table}}
                    </td>
                    <td >
                        <div class="input-group" style="max-width: 50px;">
                            <input  type="text" class="form-control" placeholder="..." name="ordering[{{record.id}}]" [(ngModel)]="record.ordering" (keyup)="record['changed']=true">
                        </div>
                    </td>
                    <td class="pr-2" style="width: 150px;">
                        <button  type="button"  title="Elimina" class="btn btn-danger btn-sm float-right m-1"  (click)="confirm_delete(record.id)">
                            <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span>
                        </button>
                        <button type="button" class="btn btn-secondary btn-sm float-right m-1" data-toggle="modal" data-target="#modalOptionCustomField" (click)="setCustomField(record)"><i class="fas fa-cog"></i>&ensp;</button>
                        <button type="button" *ngIf="record['changed']!=undefined"  title="Salva" class="btn btn-warning btn-sm float-right m-1"  (click)="save(record)">
                            <i class="fas fa-save"></i><span class="d-none d-sm-inline-block">&ensp;</span>
                        </button>
                    </td>
                </tr>
                </tbody>
                </table>
            </form>
        </div>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>

        </div>
    </div>
</app-windowlist>

<div class="modal fade" id="modalOptionCustomField" tabindex="-1" role="dialog" >
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header py-1 bg-primary">
                <b>Impostazione campo</b>
            </div>
            <div class="modal-body" *ngIf="selectedCustomField">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="form-group form-group-sm">
                            <label>Nome</label>
                            <input class="form-control form-control-sm" type="text" name="label" [(ngModel)]="selectedCustomField.label"/>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Stato</label>
                                <select name="status" class="form-control form-control-sm" name="status" [(ngModel)]="selectedCustomField.status">
                                    <option value="0">Non pubblicato</option>
                                    <option value="1">Pubblicato</option>
                                </select>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Tabella</label>
                            <app-selecttables [name]="'table'" [value]="selectedCustomField.table" [show_all]="false" (onchange)="selectedCustomField.table=$event;"></app-selecttables>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Tipologia</label>
                                <select name="type"  class="form-control form-control-sm" [(ngModel)]="selectedCustomField.type" (change)="changeType()">
                                    <option value="text"        >Casella di Testo</option>
                                    <option value="textarea"        >Area di Testo</option>
                                    <option value="number"      >Numero</option>
                                    <option value="select"      >Elenco</option>
                                    <option value="date"        >Data</option>
                                    <option value="checkbox"    >Casella di controllo</option>
                                    <option value="currency"    >Valuta</option>
                                    <option value="url"         >URL</option>
                                    <option value="separator"   >Separatore</option>
                                    <option value="multi"       >Multivalore</option>
                                    <option value="tax_code"    >Codice Fiscale</option>
                                    <option value="image"       >Immagine</option>
                                    <option value="hidden"      >Nascosto</option>
                                </select>
                            </div>
                    </div>
                    <div class="col-lg-12" *ngIf="selectedCustomField.type=='select' || selectedCustomField.type=='tax_code'" >
                        <div class="form-group form-group-sm">
                            <label>Opzioni <small>(aggiungere i valori e premere invio)</small></label>
                            <ngx-tags-input *ngIf="selectedCustomField.type=='select'" displayField="item" class="form-control form-control-sm" [(ngModel)]="selectedCustomField.option" name="option"></ngx-tags-input>
                            <textarea class="form-control form-control-sm" *ngIf="selectedCustomField.type=='tax_code'" [(ngModel)]="selectedCustomField.option" name="option" ></textarea>
                            <!--<input *ngIf="selectedCustomField.type=='select'" class="form-control" [(ngModel)]="selectedCustomField.option" name="option"/>-->
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group form-group-sm">
                            <label>Condizione di visualizzazione <small>(in base al valore ID)</small></label>
                            <input class="form-control form-control-sm" name="condition_id" [(ngModel)]="selectedCustomField.condition_id"/>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group form-group-sm">
                            <label>Condizione di visualizzazione <small>(in base al campo di riferimento)</small></label>
                            <input class="form-control form-control-sm" name="condition_field" [(ngModel)]="selectedCustomField.condition_field"/>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group form-group-sm">
                            <label>Al salvataggio replica valore nel campo</label>
                            <select class="form-control form-control-sm" name="replicate_to" [(ngModel)]="selectedCustomField.replicate_to">
                                <option value="0">Nessuno</option>
                                <option *ngFor="let c of customfieldsList" [ngValue]="c.id">{{c.label}} ({{c.table}})</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group form-group-sm">
                            <label>Tag</label>
                            <input class="form-control form-control-sm" name="condition_tag" [(ngModel)]="selectedCustomField.tag"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Accesso</label>
                            <div class="input-group input-group-sm" >
                                <select name="status" class="form-control form-control-sm" name="access" [(ngModel)]="selectedCustomField.access">
                                    <option value="superuser,admin,manager,agent,guest">Pubblico</option>
                                    <option value="superuser,admin">Privato</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group form-group-sm">
                            <label>Mostra etichetta</label>
                            <select class="form-control form-control-sm" name="show_label" [(ngModel)]="selectedCustomField.show_label">
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmOptionField()"><i class="fas fa-save mr-1"></i> Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Annulla</button>
            </div>
        </div>
    </div>
</div>
