import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '../include/structures'
import { WSService } from '../include/service'
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})

export class UserService  extends WSService<User> {

  constructor() { super("users") }


  login(username:string,password:string):Observable<User>{
    return Globals.ws.requestUrl<User>("client","login",[username,password]);
  }

  getUsers():Observable<User[]>{
    return  Globals.ws.requestUrl<User[]>("users","getItems",["NOT role='guest'","name ASC"],true,0,1000000);
    
  }

  getUser(username,password):Observable<User>{
    return  Globals.ws.requestUrl<User>("users","getUser",[username,password]);
    
  }

  getUserByContact(contact,password):Observable<User>{
    return  Globals.ws.requestUrl<User>("users","getUserByContact",[contact,password]);
    
  }

  getUserByAddress(id_address:number):Observable<User[]>{
    return  Globals.ws.requestUrl<User[]>("users","getItems",["id_address="+id_address.toString()]);
    
  }

  checkPassword(id_user:number,password:string):Observable<User[]>{
    return  Globals.ws.requestUrl<User[]>("users","getItems",["u.id="+id_user.toString()+" AND u.password='"+password+"'"]);
  }


  resendPassword(username:string):Observable<boolean>{
    return  Globals.ws.requestUrl<boolean>("users","resendPassword",[username]);
    
  }


}
