import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { Room } from 'projects/modules/src/app/restaurantcashregister/restaurantcashregister';
import { RestaurantroomsService } from 'projects/modules/src/app/restaurantcashregister/services/restaurantrooms.service';

@Component({
  selector: 'setting-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.css']
})
export class SettingRoomsComponent implements OnInit {

  @ViewChild("modalEdit")
  modalEdit:TemplateRef<any>;
  modalEditRef;

  list=[];


  item_selected:Room=new Room();
  constructor(
    private roomsService: RestaurantroomsService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
   this.getRooms()
  }

  getRooms(){
    this.roomsService.getRooms().subscribe((result)=>{
      this.list=result;
    })
  }

  openModalEdit (record=null){
    Object.assign(this.item_selected,record)
    this.modalEditRef=this.modalService.open(this.modalEdit,{size:"lg"});
  }

  closeModalEdit(){
    this.modalEditRef.close();
  }

  newRecord(){
    this.item_selected=new Room();
    this.item_selected['tables']=10;
    this.item_selected['seats']=4;
    this.openModalEdit(this.item_selected);
  }


  delete(item){
    Globals.modal.showConfirm("Sei sicuro di voler eliminare la sala "+item.name+"?","",()=>{
      this.roomsService.delete([item.id]).subscribe(()=>{
        this.getRooms();
      })
    },"Elimina","Annulla","danger")
  }


  save() {
    this.roomsService.save(this.item_selected,()=>{
      this.closeModalEdit();
      this.getRooms();
    })
  }

}
