<div>
    <button class="btn text-md btn-success px-3" (click)="newRecord()"><i class="fa fa-plus mr-2"></i>Aggiungi categoria</button>
    <ul class="list-group mt-2" style="overflow-y:auto; height:calc(100vh - 190px)">
        <li class="list-group-item w-100" *ngFor="let item of list">
            <i class="fa fa-pencil-alt px-2" (click)="openModalEdit(item)"></i>
            {{item.name}} ({{item.tax}}%)
            <i class="fa fa-trash-alt text-danger float-right px-2" (click)="delete(item)"></i>
        </li>
    </ul>
</div>

<ng-template #modalEdit>
    <div class="modal-body bg-color0 border">
        <label>Titolo</label>
        <input type="text" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.name">

        <label>IVA</label>
        <input type="text" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.tax">
       
        <div class="row mt-4">
            <div class="col-6">
                <button class="btn btn-success btn-block" (click)="save()"><i class="fas fa-save mr-2"></i>Salva</button>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary btn-block" (click)="closeModalEdit()"><i class="fas fa-times mr-2"></i>Annulla</button>
            </div>
        </div>
    </div>
</ng-template>