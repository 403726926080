<app-windowlist [model]="this">
    <div class="card">
        <div class="row p-1">
            <div class="col-lg-4">
                <app-selecttables [name]="'filter_type'" [value]="filter_type.value" [show_all]="true" (onchange)="filter_type.value=$event;getItems()"></app-selecttables>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4">
                <button class="btn btn-success btn-sm float-right m-1" data-toggle="modal" data-target="#modalRule" (click)="addAlarm()"><i class="fa fa-plus"></i></button>
            </div>
        </div>
        <div class="card-body table-responsive-sm p-0">
            <form>
                <table class="table table-sm table-head-fixed  text-nowrap m-0" >
                    <thead>
                        <th>Nome</th>
                        <th>Tabella</th>
                        <th></th>
                    </thead>
                    <tbody>

                    <!-- elemento ripetuto -->
                    <tr *ngFor="let record of list">
                        <td class="pl-2">
                            <i *ngIf="record.status==1" class="fa fa-check text-success"></i>
                            <i *ngIf="record.status==0" class="fas fa-times text-danger"></i>
                            {{record.name}}
                        </td>
                        <td><small><i>{{record.table}}</i></small></td>
                        <td class="pr-2">
                            <button type="button"  title="Elimina" class="btn btn-danger btn-xs float-right ml-1"  (click)="confirm_delete(record.id)">
                                <i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  type="button"  title="Modifica" class="btn btn-secondary btn-xs float-right ml-1"  data-toggle="modal" data-target="#modalRule" (click)="setRule(record)">
                                <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            </td>
                    </tr>
                    
                    </tbody>
                </table>
                <!-- /.table-responsive -->
            </form>
        </div>
        <div class="card-footer clearfix py-1">
            <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small>
        </div>
    </div>
</app-windowlist>

<div class="modal fade" id="modalRule" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header py-1 bg-primary">
                <b>Impostazione record</b>
            </div>
            <div class="modal-body" *ngIf="selectedAlarm">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Nome</label>
                            <input class="form-control form-control-sm" type="text" name="name" [(ngModel)]="selectedAlarm.name"/>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Stato</label>
                            <div class="input-group" >
                                <select name="status" class="form-control form-control-sm" name="status" [(ngModel)]="selectedAlarm.status">
                                    <option value="0">Non pubblicato</option>
                                    <option value="1">Pubblicato</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Tabella</label>
                            <app-selecttables [name]="'table'" [value]="selectedAlarm.table" [show_all]="false" (onchange)="selectedAlarm.table=$event;"></app-selecttables>
                        </div>
                    </div>
                </div>
                <h5 class="mt-2">Condizioni</h5>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Metodo</label>
                            <input type="text" class="form-control form-control-sm" name="method" [(ngModel)]="selectedAlarm.method">
                        </div>
                    </div>
                </div>
                

                <small>Indicare le condizioni che si devono verificare per applicare la regola</small>
                <table class="table">
                    <thead>
                        <th>Campo</th>
                        <th></th>
                        <th>Valore</th>
                        <th><button type="button" class="btn btn-xs btn-success float-right" (click)="addCondition()"><i class="fa fa-plus"></i> </button></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of selectedAlarm.conditionsArray">
                            <td>
                                <input type="text" class="form-control form-control-sm" [(ngModel)]="c.field"/>
                                <lib-btn-select-field [table]="selectedAlarm.table" (fieldSelected)="c.field=$event"></lib-btn-select-field>
                            </td>
                            <td>
                                <select class="custom-select custom-select-sm" [(ngModel)]="c.condition">
                                    <option value="ugual"> uguale </option>
                                    <option value="desigual"> non uguale </option>
                                    <option value="major"> maggiore </option>
                                    <option value="majorugual"> maggiore e uguale</option>
                                    <option value="minor"> minore </option>
                                    <option value="minorugual"> minore e uguale</option>
                                    <option value="contain"> contiene </option>
                                    <option value="notcontain"> non contiene </option>
                                    
                                </select>
                            </td>
                            <td><input type="text" class="form-control form-control-sm" [(ngModel)]="c.value"/></td>
                            <td><button type="button" class="btn btn-xs btn-danger float-right" (click)="removeCondition(c)"><i class="fa fa-trash"></i></button></td>
                        </tr>
                    </tbody>
                </table>
                <div class="form-group form-group-sm mt-4">
                    <label>Messaggio</label>
                    <div class="input-group">
                        <input type="text" class="form-control form-control-sm"  placeholder="..." [(ngModel)]="selectedAlarm.message" >
                    </div>
                </div>
                <div class="form-group form-group-sm">
                    <label>Tipo messaggio</label>
                    <div class="input-group">
                        <select class="form-control form-control-sm" [(ngModel)]="selectedAlarm.type">
                            <option value="info">Info</option>
                            <option value="danger">Danger</option>
                            <option value="warning">Warning</option>
                            <option value="success">Success</option>
                            <!-- <option value="primary">Primary</option> -->
                            <!-- <option value="secondary">Secondary</option> -->
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmRule()"><i class="fas fa-save mr-1"></i>Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Annulla</button>
            </div>
        </div>
    </div>
</div>