<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filterbox">
            <small>
                <app-filterdate [date_type]="100" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
            </small>
        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row" *ngIf="filterbox">
                    </div>
                    <div class="row" *ngIf="filterbox">
                        <div class="col-sm-6">
                            <div class="input-group input-group-info input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="nome cliente o descrizione" [(ngModel)]="this.filter_search.value" (keyup.enter)="updateList()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default"  title="avvia ricerca" (click)="updateList()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 py-1">
                            <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_phase.value" (change)="getItems()">
                                <option value="0">Tutte le fasi</option>
                                <option value="1">preventivato</option>
                                <option value="2">in corso</option>
                                <option value="3">concluso</option>
                                <option value="4">annullato</option>
                            </select>
                        </div>
                        <div class="col-sm-3 py-1">
                            <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="far fa-trash-alt"></i></button>
                            <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                            <button (click)="openDetail(null)"  class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                        <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th width="20px">
                                        <input #checkboxselectall type="checkbox"
                                        (change)="onChangeAll($event.target.checked)" />
                                </th>
                                <th>
                                    Data
                                    <button type="button" class="btn btn-tool" (click)="switchOrdering('i.date')">
                                        <i class="fas fa-sort {{classOrdering('a.date')}}"  ></i>
                                    </button>
                                </th>
                                <th>Titolo</th>
                                <th class="d-none d-sm-table-cell" *ngIf="id_address==0">
                                    Anagrafica
                                </th>
                                <th class="text-center">Fase</th>
                                <th class="text-right">Budget</th>
                                <th class="text-right">Costi</th>
                                <th class="text-right">Pagato</th>
                                <th width="60px"></th>
                            </thead>
                            <thead class="bg-white">
                                <tr>
                                    <td></td>
                                    <td colspan="4"><b>TOTALE</b></td>
                                    <td class="text-right">
                                        <b>{{total | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right">
                                        <b>{{total_cost | currency:'EUR':true }}</b>
                                       
                                    </td>
                                    <td class="text-right">
                                        <b>{{total_paid | currency:'EUR':true }}</b>
                                       
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list" [class.bg-doneline]="record.phase==3 || record.phase==4">
                                    <td>
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                        />
                                    </td>
                                    <td>
                                        <a [class.trashed]="record.status == 2" (click)="openDetail(record)" title="{{record.date | date:'EEEE'}}" class="pointer">
                                            {{record.date | date:'dd/M/yyyy'}}
                                        </a>
                                    </td>
                                    <td>
                                        {{record.name}}<br/>
                                        <small><i>{{record.note}}</i></small>
                                    </td>
                                    <td class="d-none d-sm-table-cell" *ngIf="id_address==0" >
                                        <ng-container *ngIf="record.addressItem">{{ record.addressItem.name }}</ng-container><br/>
                                    </td>
                                    <td class="text-center">
                                        <span class="badge bg-gray" *ngIf="record.phase==1">preventivato</span>
                                        <span class="badge badge-warning" *ngIf="record.phase==2">in corso</span>
                                        <span class="badge badge-success" *ngIf="record.phase==3">concluso</span>
                                        <span class="badge badge-danger" *ngIf="record.phase==4">annullato</span>
                                    </td>
                                    <td class="text-right">
                                        {{record.amount | currency:"&euro;"}}
                                    </td>
                                    <td class="text-right">
                                        {{record.cost | currency:"&euro;"}}

                                    </td>
                                    <td class="text-right">
                                        {{record.paid | currency:"&euro;"}}

                                    </td>


                                    <td>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="openDetail(record)" routerLinkActive="active"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right mr-1">
                                            <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                                    
                                        <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="5"><b>TOTALE</b></td>
                                    <td class="text-right">
                                        <b>{{total | currency:'EUR':true }}</b>
                                       
                                    </td>
                                    <td class="text-right">
                                        <b>{{total_cost | currency:'EUR':true }}</b>
                                       
                                    </td>
                                    <td class="text-right">
                                        <b>{{total_paid | currency:'EUR':true }}</b>
                                       
                                    </td>


                                    <td></td>
                                </tr>
    
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>
