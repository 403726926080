import { Component, OnInit,ViewChild,PipeTransform, Pipe ,HostListener} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import {NgbDate, NgbCalendar, NgbModalRef, NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { User } from 'projects/core/src/include/structures';
import { Port } from '../../port';
import { TravelDate } from '../../travelDate';
import { Travel } from '../../travel';
import { Seat } from '../../seat';
import { Ticket, TicketDetail } from '../../ticket';
import { UserService } from 'projects/core/src/lib/user.service';
import {NotificationService} from '../../../../../../c1-backend/src/app/services/notification.service';
import { Globals } from 'projects/core/src/globals';
import { OwlOptions } from 'ngx-owl-carousel-2';
import { Address } from 'projects/c1-backend/src/app/db/address';
import { Contact } from 'projects/c1-backend/src/app/db/contact';
import { PortService } from '../../services/port.service';
import { TravelService } from '../../services/travel.service';
import { TicketsService } from '../../services/tickets.service';
import { AddressesService } from 'projects/c1-backend/src/app/services/addresses.service';
import { CardsService } from '../../services/cards.service';
import { Card } from '../../card';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  styles: [`
    .custom-day {
      text-align: center;
      padding: 0.185rem 0.25rem;
      display: inline-block;
      height: 2rem;
      width: 2rem;
    }
    .custom-day.focused {
      background-color: #e6e6e6;
    }
    .custom-day.range, .custom-day:hover {
      background-color: var(--primary);
      color: white;
    }
    .custom-day.faded {
      background-color: var(--primary) !important;
      opacity: 0.7;
    }
  `]
})


export class SearchComponent implements OnInit {

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if(e.data)
      if('task' in e.data)
        if (e.data.task == "successPayment") {
          this.modalService.dismissAll("success");
          this.goTo(this.ORDER_COMPLETE);
          // TODO da controlalre AppComponent.app.getTickets();
        }

        if (e.data.task == "errorPayment") {
          this.modalService.dismissAll("success");
          alert("C'è stato un errore nel pagamento");
        }
  }


  paygatewayurl:string="";


  @ViewChild("modalPassword")
  modalPassword:NgbModal;


  currentModalClientList:NgbModalRef;


  @ViewChild("modalPay")
  modalPay:NgbModal;


  travelSlidesStart:NgbCarousel;
  travelSlidesReturn:NgbCarousel;

  companies=[];

  get totalTicket(){
   return this.calculateTotal(this.passengers_start)+this.calculateTotal(this.passengers_return);
  }

 @ViewChild('travelSlidesStart' , {static : false}) set content1(content: NgbCarousel) {
    if(content) { // initially setter gets called with undefined
        this.travelSlidesStart = content;
    }
 };


 @ViewChild('travelSlidesReturn' , {static : false}) set content2(content: NgbCarousel) {
  if(content) { // initially setter gets called with undefined
      this.travelSlidesReturn = content;
  }
};


 
  public STATUS_CHOICE_BUS_COMPANY=0;
  public STATUS_SEARCH_TRAVEL=1;
  public STATUS_SELECT_RACE_START=2;
  public STATUS_SELECT_RACE_RETURN=3;
  
  public STATUS_SELECT_SEATS=4;
  public STATUS_INSERT_PASSENGERS=99;
  public STATUS_CONFIRM_TICKET=5;
  public STATUS_PAY=6;
  public ORDER_CONFIRMED=7;
  public ORDER_COMPLETE=8;
  public title="";
  


  public ONLY_START=12;
  public START_AND_RETURN=13;

  travelsOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    items:1,
    nav: true,
    navText:[
      "<i class='fa fa-arrow-left mr-2'></i>giorno precedente",
      "giorno successivo<i class='fa fa-arrow-right ml-2'></i>"
    ]
  }

  passengersOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    items:1,
    nav: true,
    navText:[
      "<i class='fa fa-arrow-left mr-2'></i>passeggero precedente",
      "passeggero successivo<i class='fa fa-arrow-right ml-2'></i>"
    ]
  }

  user:User={} as User;
  
  status=this.STATUS_CHOICE_BUS_COMPANY;

  ports_from: Port[]=[];
  ports_to: Port[]=[];
  travelsstart:TravelDate[]=[];
  travelsreturn:TravelDate[]=[];


  date_start_selected:NgbDate;
  date_return_selected:NgbDate | null = null;
  hoveredDate: NgbDate | null = null;

  travel_selected_start:Travel;
  travel_selected_return:Travel;
  
  
  matrix_seat_layout:Seat[][];
  seats_selected:Seat[]=[];

  ticket:Ticket=new Ticket();

  client:Object={} as Object;
  currentClient:Address;
  card:Card;
  cards:Card[];
  listClientsSearched:Address[];

  contacts:Contact[];
  passwordClient:string;
  condition:string;
  onloadPayment=true;

  passengers_start:Seat[]=[];
  passengers_return:Seat[]=[];
  

  onSaving=false;
  id_type_telephone:number;
  id_type_email:number;

  constructor(
    private userService: UserService,
    private portService: PortService,
    private travelService: TravelService,
    private ticketsService:TicketsService,
    private addressesService:AddressesService,
    private cardsService:CardsService,
    private modalService: NgbModal,
    private notificationService:NotificationService,
    private httpClient:HttpClient,
    calendar: NgbCalendar
    
    ) {
      this.date_start_selected = calendar.getToday();
      this.date_return_selected = calendar.getNext(calendar.getToday(), 'd', 10);
      
    }


  ngOnInit(): void {
    this.ticket.tickets.push(new TicketDetail());
    this.id_type_telephone=Globals.parameters.get("busticket").getParam("id_telephone",0);
    this.id_type_email=Globals.parameters.get("busticket").getParam("id_email",0);

    this.ONLY_START=Globals.parameters.get("busticket").getParam("id_only_start",0);
    this.START_AND_RETURN=Globals.parameters.get("busticket").getParam("id_start_and_return",0);


    this.ticket.id_type=this.ONLY_START;
    this.ticket.adults=1;
    this.ticket.children=0;
    this.ticket.cost=0;

    this.cardsService.getCards().subscribe((items)=>{
      this.cards=items;
      for(let c of this.cards){
        if(c.default){
          this.ticket.id_card=c.id;
        }
      }
    })

    this.ticketsService.getCompanies().subscribe((items)=>{
      this.companies=items;
    })

    

    

    const requestOptions: Object = {
        /* other options here */
        responseType: 'text'
    }

  }

  getPortsFrom():void {
    if(this.ports_from.length==0){
     this.portService.getPorts("",this.ticket.id_company.toString()).subscribe(ports=>{
       this.ports_from=ports;
       this.ticket.PortFrom=ports[0];
       //this.ticket.port_from=ports[0].stop;
       //this.ticket.gps_port_from=ports[0].lat.toString()+","+ports[0].lon.toString();
       this.getPortsTo();
      });
    }
  }

  getPortsTo():void {
    this.ports_to=[];
    this.portService.getPorts(this.ticket.PortFrom.id,this.ticket.id_company.toString()).subscribe(ports=>{
      this.ports_to=ports;
      this.ticket.PortTo=ports[0];
      //this.ticket.port_to=this.ports_to[0].stop;
      //this.ticket.gps_port_to=ports[0].lat.toString()+","+ports[0].lon.toString();
    });
  }

  goTo(status):void{
  
    switch(status){

      case this.STATUS_CHOICE_BUS_COMPANY:
        this.title="";
        this.ports_from=[];
        this.ports_to=[];
        break;

      case this.STATUS_SEARCH_TRAVEL:
        this.getPortsFrom();
        break;

      case this.STATUS_SELECT_RACE_START:
        this.title="Seleziona la corsa di andata";

        //avvia la ricerca 
        let date_start=new Date(this.date_start_selected.year,this.date_start_selected.month-1,this.date_start_selected.day);
        let date_return=null;
        if(this.ticket.id_type==this.START_AND_RETURN)
          date_return=new Date(this.date_return_selected.year,this.date_return_selected.month-1,this.date_return_selected.day);

        this.travelsstart=[];
        this.travelsreturn=[];

        this.travelService.getTravel(this.ticket._port_from.id,this.ticket._port_to.id,date_start,date_return,this.ticket.adults,this.ticket.children,this.ticket.id_company.toString()).subscribe(travels=>{
          
          if(travels.start.length==0){
            alert("Non ci sono corse programmate per la partenza di andata selezionata");
            this.goTo(this.STATUS_SEARCH_TRAVEL);
          }
          for(var i=0;i<travels.start.length;i++){
            travels.start[i]=this.parsingSmartbus(travels.start[i]);
            //calcola lo sconto
            travels.start[i]=this.calculateDiscount(travels.start[i]);
            //calcola il totale 
            travels.start[i].cost=(travels.start[i].PRICES[0].ADULT*(1-travels.start[i].discount/100))*this.ticket.adults+travels.start[i].PRICES[1].CHILDREN*this.ticket.children;
          }

          this.travelsstart=this.groupDate(travels.start); //raggruppa tutte le corse di una stessa data

          if("return" in travels){
            for(var i=0;i<travels.return.length;i++){
              travels.return[i]=this.parsingSmartbus(travels.return[i]);
              //calcola lo sconto
              travels.return[i]=this.calculateDiscount(travels.return[i]);
              travels.return[i].cost=(travels.return[i].PRICES[0].ADULT*(1-travels.return[i].discount/100))*this.ticket.adults+travels.return[i].PRICES[1].CHILDREN*this.ticket.children;
            }
            this.travelsreturn=this.groupDate(travels.return);
          }
        });
        break;

      case this.STATUS_SELECT_RACE_RETURN:
        this.title="Seleziona la corsa di ritorno";
        break;

      case this.STATUS_SELECT_SEATS:
          
        this.passengers_start=this.addToPassengersArray(this.passengers_start,this.travel_selected_start);
        this.passengers_return=this.addToPassengersArray(this.passengers_return,this.travel_selected_return);

        /*
          if(this.passengers.length==0){
            for(let i=0;i<this.ticket.adults;i++){
              let s:Seat={} as Seat;
              s.name="";
              s.lastname="";
              s.type="ADULT";
              if(this.travel_selected_start){
                s.cost=this.travel_selected_start.PRICES[0].ADULT*(1-this.travel_selected_start.discount/100)+(this.ticket.id_type==this.START_AND_RETURN?this.travel_selected_return.PRICES[0].ADULT*(1-this.travel_selected_return.discount/100):0);
                s.offer=this.travel_selected_start.id_offer;
                s.discount=this.travel_selected_start.discount;
              }
              s.id=i+1;
              
              this.passengers.push(s);
            }

            for(let i=0;i<this.ticket.children;i++){
              let s:Seat={} as Seat;
              s.name="";
              s.lastname="";
              s.type="CHILDREN";
              if(this.travel_selected_start){
                s.cost=this.travel_selected_start.PRICES[1].CHILDREN+(this.ticket.id_type==this.START_AND_RETURN?this.travel_selected_return.PRICES[1].CHILDREN:0);
              }
              s.id=i+1
              s.offer="0";
              this.passengers.push(s);
            }
          }
          */



          this.ticket.cost=this.calculateTotal(this.passengers_start)+this.calculateTotal(this.passengers_return);


          this.title="Seleziona i posti";
          break;

      case this.STATUS_INSERT_PASSENGERS:
        this.title="Indica i nominativi dei passeggeri";
        break;

      case this.STATUS_CONFIRM_TICKET:
        this.title="Riepilogo biglietti";
        break;

      case this.STATUS_PAY:

        this.user=Globals.user;

        if(this.user==undefined || typeof(this.user['name'])=="undefined" || (this.user['name']=="")){
          this.user={} as User;
          this.user.name=this.passengers_start[0].name;
          //this.user.lastname=this.seats_selected[0].lastname;

        }else{
          /*DA eliminare e sostituire con la raccolta informazioni dal centralino
          
          this.ticket.id_address=this.user.id;
          //prendi il numero di telefono
          for (let c of this.user['contacts']){
            if(c['id_type']==4){
              this.client['telephone']=c['value'];
            }
          }
          this.client['name']=this.user['name'];
          this.client['lastname']=this.user['lastname'];
          this.client['email']=this.user['email'];
          */

        }
        break;

      case this.ORDER_CONFIRMED:
        this.openPayMethod();
        break;

  }


  this.status=status;
 }


 calculateDiscount(travel:Travel){
  
  travel.discount=0;
  travel.id_offer="0";
  travel.discount_description="";
  for(let d of travel.DISCOUNTS){
    //calcola la percentuale di sconto
    if(d.AMOUNT){
      travel.discount=parseFloat(d.AMOUNT.replace("%",""));
      travel.id_offer=d.OFFER;
      travel.discount_description=d.AMOUNT;
    }
  }

  return travel;
 }

  goToBack():void{

    if(this.status==this.STATUS_SEARCH_TRAVEL)
      if(this.ticket.id_company==1)
        this.ticket.port_from="";
        this.ticket.port_to="";

    if(this.status==this.STATUS_SELECT_SEATS)
      if(this.ticket.id_type==this.ONLY_START)
        this.status=this.status-1;

    if(this.status==this.STATUS_INSERT_PASSENGERS)
      if(this.ticket.id_company>1)
        this.status=this.STATUS_SELECT_RACE_START;

    this.goTo(this.status-1);

  }

  goToNext():void{

    this.goTo(this.status+1);

  }


  groupDate(travels:Travel[]):TravelDate[]{
  
    var group:TravelDate[]=[];

    for(var i=0;i<travels.length;i++){
      //cerca la data all'interno del raggruppamento
      var idx=-1;

      for(var j=0;j<group.length;j++){
        if(group[j].date==travels[i].TRIP_DATE){
          idx=j;
          continue;
        }
      }

      if(idx>-1){
        //verifica che non ci sia un viaggio con lo stesso SID
        var to_insert=true;
        for(var j=0;j<group[idx].travel.length;j++){
          if(group[idx].travel[j].SID==travels[i].SID){
            to_insert=false;
            continue;
          }
        }
        if(to_insert)
          group[idx].travel.push(travels[i]);
      }else{
        let td={} as TravelDate;
        td.date=travels[i].TRIP_DATE;
        td.travel=[];
        td.travel.push(travels[i]);
        td.id=group.length+1;
        group.push(td);
      }

    }
 
  return group;
  }

  onDateSelection(date: NgbDate) {
    if (!this.date_start_selected && !this.date_return_selected) {
      this.date_start_selected = date;
    } else if (this.date_start_selected && !this.date_return_selected && date.after(this.date_start_selected)) {
      this.date_return_selected = date;
    } else {
      this.date_return_selected = null;
      this.date_start_selected = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.date_start_selected && !this.date_return_selected && this.hoveredDate && date.after(this.date_start_selected) && date.before(this.hoveredDate);
  }


  isInside(date: NgbDate) {
    return this.date_return_selected && date.after(this.date_start_selected) && date.before(this.date_return_selected);
  }

  isRange(date: NgbDate) {
    return date.equals(this.date_start_selected) || (this.date_return_selected && date.equals(this.date_return_selected)) || this.isInside(date) || this.isHovered(date);
  }

  onSelectTravel(travel:Travel,type="Andata",onGo:boolean=true){
      
    this.passengers_start=[];
    this.passengers_return=[];
    
    if(type=="Andata"){
      this.travel_selected_start=travel;
    }
    if(type=="Ritorno"){
      this.travel_selected_return=travel;
    }

    if(onGo){
      if(this.ticket.id_type==this.ONLY_START)
        this.goTo(this.STATUS_SELECT_SEATS);
      else
        if(this.status==this.STATUS_SELECT_RACE_START)
          this.goTo(this.STATUS_SELECT_RACE_RETURN);
        else
          this.goTo(this.STATUS_SELECT_SEATS);
    }
      
  }


  parsingSmartbus(t:Travel):Travel{
    try{
      t.LIST_SEATS=t.AVAILABLE_SEATS.split(",").map(Number);
    }catch{
      t.LIST_SEATS=[];
      if(t.AVAILABLE_SEATS!="")
        t.LIST_SEATS.push(parseInt(t.AVAILABLE_SEATS));
    }

    t.COUNT_SEATS=t.LIST_SEATS.length;

    try{
      t.LIST_NOT_BUY_SEATS=t.POSTIOCCUPATICURR.split(",").map(Number);
    }catch{
      t.LIST_NOT_BUY_SEATS=[];
      if(t.POSTIOCCUPATICURR!="")
        t.LIST_NOT_BUY_SEATS.push(parseInt(t.POSTIOCCUPATICURR));
    }

    let start:Date=new Date(t.TRIP_DATE+" "+t.START_TIME);
    let stop:Date=new Date(t.TRIP_DATE+" "+t.STOP_TIME);;
    
    t.TRAVEL_TIME=stop.getTime()-start.getTime();

    return t;
  }


 calculateTotal(seats:Seat[]):number{
  let cost:number=0;
   for(var i=0;i<seats.length;i++){
     cost+=seats[i].cost;
   }

   return cost;
  }

 confirmPassengers():void{

  this.ticket.cost=this.calculateTotal(this.passengers_start)+this.calculateTotal(this.passengers_return);


  for(var i=0;i<this.passengers_start.length;i++){

    

    if(this.passengers_start[i].name==""){
      alert("Inserire il nome del "+this.passengers_start[i].id.toString()+"° passeggero "+(this.passengers_start[i].type=='ADULT'?"adulto":"bambino"));
      return;
    }

    if(this.passengers_start[i].lastname==""){
      alert("Inserire il cognome del "+this.passengers_start[i].id.toString()+"° passeggero "+(this.passengers_start[i].type=='ADULT'?"adulto":"bambino"));
      
      return;
    }
   }

   this.goTo(this.STATUS_CONFIRM_TICKET);



 }

 registerUser():void{
   /*this.userService.registerUser(this.user).subscribe(user=>{
      console.log(user);
   })*/
 }


 addQuantity(fieldname){
  this.ticket[fieldname]= this.ticket[fieldname]+1;

 }

 reduceQuantity(fieldname){
   if(this.ticket[fieldname]>0)
   this.ticket[fieldname]=this.ticket[fieldname]-1;
 }



 confirmTicket(){

  if(this.client['telephone']=="" || this.client['telephone']==undefined){
    alert("Inserisci numero di telefono valido");
    return;
  }

  this.ticket.id_state=1;
  
  this.ticket.created_by=Globals.user.id;
  this.ticket.source="telephone";
  this.ticket.method_payment="Carta di pagamento";


  
  this.ticket.tickets=[];


  this.ticket.tickets.push(this.createTicket(this.travel_selected_start,"start"));
  if(this.ticket.id_type==this.START_AND_RETURN){
    this.ticket.tickets.push(this.createTicket(this.travel_selected_return,"return"));
  }
  
  
  for(var i=0;i<this.passengers_start.length;i++){
    
    this.passengers_start[i].telephone=this.client['telephone'];
    this.passengers_start[i].seat=this.passengers_start[i].id;
    if(this.ticket.id_type==this.START_AND_RETURN){ //aggiorna i parametri dell'elenco passeggeri
      this.passengers_return[i].name=this.passengers_start[i].name;
      this.passengers_return[i].lastname=this.passengers_start[i].lastname;
      this.passengers_return[i].type=this.passengers_start[i].type;
    }
    
    
  }
  //this.ticket.listpassengers=JSON.stringify(this.seats_selected);

  


  //verifica che i dati del cliente sono stati inseriti
  if(this.ticket.id_address==0 || this.ticket.id_address==undefined){
   


    //controlla se il cliente non è registrato (se il numero di telefono non è associato a nessun cliente)
    this.addressesService.searchByContact(this.client['telephone']).subscribe((items)=>{
      if(items.length>0){
        this.listClientsSearched=items;

        this.currentModalClientList=this.modalService.open(this.modalPassword);
        
        return;
      }else{
        //è un nuovo cliente

        if(this.client['name']=="" || this.client['name']==undefined){
          alert("Inserisci il tuo nome");
          return;
        }
    
       
    
        

        

        let c:Address={} as Address;
        c.id=0;
        c.name=this.client['name'];
        c.id_type=1;
        c.contacts=[];
        let t:Contact={} as Contact;
        t.id_type=this.id_type_telephone;
        t.value=this.client['telephone'];
        c.contacts.push(t);
        if(this.client['email']){
          let e:Contact={} as Contact;
          e.id_type=this.id_type_email;
          e.value=this.client['email'];
          c.contacts.push(e);
        }


        this.ticketsService.registerNewUser(c,(user)=>{
       
          this.ticket.id_address=user.id_address;
          this.ticket.addressItem=c;
          this.contacts=c.contacts;
          this.saveTicket()
        });
      }

      
    });

  }else{
    this.saveTicket();
  }

   
   
 }

 saveTicket(){
  this.onSaving=true;
  this.ticketsService.save(this.ticket,(id)=>{
      this.ticket.id=id;

      let ids:number[]=[];
      for(let t of this.ticket.tickets){
        ids.push(t.id);
      }

      //blocca i posti
      this.ticketsService.bookAll(this.ticket.id,this.ticket.id_company.toString()).subscribe((result)=>{
        if(result){
          //this.goTo(this.ORDER_CONFIRMED);
          this.modalService.dismissAll("success");
          /*if(AppComponent.app.savingTicketsEvent)
            AppComponent.app.savingTicketsEvent();*/
        }else{
          alert("Errore durante il bloccaggio dei posti");
          this.modalService.dismissAll("success");
         /* if(AppComponent.app.savingTicketsEvent)
            AppComponent.app.savingTicketsEvent();*/
        }

        this.notificationService.showPreviewNotification(this.contacts,this.ticket,"tickets",()=>{

        });
    
       

        this.onSaving=false;
      });

  });
  
  
 }

 selectClient(item:Address){
    //verifica che la password inserita corrisponde ad un utente
   

          this.ticket.id_address=item.id;
          this.ticket.addressItem=item;
          this.client['name']=item.name;
            for(let c of item.contacts){
              if(c.id_type==this.id_type_telephone)
                if(this.client['telephone']=="" || this.client['telephone']==undefined)
                  this.client['telephone']=c.value;
              
              if(c.id_type==this.id_type_email)
                if(this.client['email']=="" || this.client['email']==undefined)
                  this.client['email']=c.value;
          }
          this.contacts=item.contacts;
          
          this.currentModalClientList.dismiss("success");
          
      
 }

 openPayMethod(){
  this.modalService.open(this.modalPay,{size:'lg'});
  this.ticketsService.pay(this.ticket.id).subscribe(result=>{
    if(result.status){
      this.paygatewayurl=result.url;
      
      
    }
  })
 }

 createTicket(travel:Travel,type="start"){
  
  let t={} as TicketDetail;
  let ticketpassengers=[];

  if(type=="start"){

    ticketpassengers=this.addToTicketPassengersArray(this.passengers_start,travel,ticketpassengers);
    /*
    for(var i=0;i<this.passengers.length;i++){
      let s:Seat={} as Seat;
      s.name=this.passengers[i].name;
      s.lastname=this.passengers[i].lastname;
      s.type=this.passengers[i].type;
      if(this.passengers[i].cost==0)
        s.cost=s.type=='ADULT'?travel.PRICES[0].ADULT*(1-travel.discount/100):travel.PRICES[1].CHILDREN;
      else
        s.cost=this.passengers[i].cost;
      s.offer=this.passengers[i].offer;
      s.telephone=this.client['telephone'];
      ticketpassengers.push(s);
      
    }*/

  }else{
    ticketpassengers=this.addToTicketPassengersArray(this.passengers_return,travel,ticketpassengers);
    
  }

  //biglietto di andata
  t.date=travel.TRIP_DATE;
  t.port_from=travel.START;
  t.port_to=travel.STOP;
  t.gps_station_from=this.ticket.gps_port_from;
  t.gps_station_to=this.ticket.gps_port_to;
  t.station_from=travel.START_ADDRESS;
  t.station_to=travel.STOP_ADDRESS;
  t.time_from=travel.START_TIME;
  t.time_to=travel.STOP_TIME;
  t.listpassengers=JSON.stringify(ticketpassengers);
  t.sid=travel.SID;
  t.cost=this.calculateTotal(ticketpassengers);
  return t;
 }

 onLoadIframePay(myIframe){
  //verifica in che stadio è il frame
  if(myIframe.src!=""){
    this.onloadPayment=false;
  }

  if(myIframe.src.indexOf("notify.php")>0){
    this.modalService.dismissAll("success");
  }
 }


 openYourTickets(){
   //TODO da controllare AppComponent.app.goToLink("/tickets");
 }

 searchClientByName(){
   this.addressesService.searchName(this.client['name']).subscribe((items)=>{
    this.listClientsSearched=items;
    this.currentModalClientList=this.modalService.open(this.modalPassword);
    
   });
 }

 close(){
  this['modalWindow'].close("success");
 }


 addToPassengersArray(list,travel_selected){
  if(list.length==0){
    for(let i=0;i<this.ticket.adults;i++){
      let s:Seat={} as Seat;
      s.name="";
      s.lastname="";
      s.type="ADULT";
      if(travel_selected){
        s.cost=travel_selected.PRICES[0].ADULT*(1-this.travel_selected_start.discount/100);
        s.offer=travel_selected.id_offer;
        s.discount=travel_selected.discount;
      }
      s.id=i+1;
      
      list.push(s);
    }

    for(let i=0;i<this.ticket.children;i++){
      let s:Seat={} as Seat;
      s.name="";
      s.lastname="";
      s.type="CHILDREN";
      if(travel_selected){
        s.cost=travel_selected.PRICES[1].CHILDREN;
      }
      s.id=i+1
      s.offer="0";
      list.push(s);
    }
  }
  return list;
 }

 addToTicketPassengersArray(passengers,travel,ticketpassengers){
  for(var i=0;i<passengers.length;i++){
    let s:Seat={} as Seat;
    s.name=passengers[i].name;
    s.lastname=passengers[i].lastname;
    s.type=passengers[i].type;
    if(passengers[i].cost==0)
      s.cost=s.type=='ADULT'?travel.PRICES[0].ADULT*(1-travel.discount/100):travel.PRICES[1].CHILDREN;
    else
      s.cost=passengers[i].cost;
    s.offer=passengers[i].offer;
    s.telephone=this.client['telephone'];
    ticketpassengers.push(s);
    
  }
  return ticketpassengers;
 }
}
