<app-windowlist [model]="this">
    <div class="row">
        <div class="col-12">
            <div class="card card-main px-1">
                    <div class="row" *ngIf="filterbox">
                        <div class="col-lg-4">
                            <div class="input-group input-group-sm p-1">
                                <div *ngIf="mode!='embedded'" #checkboxselectall class="m-1 icheck-primary d-inline mr-3">
                                    <input  type="checkbox"
                                    (change)="onChangeAll($event.target.checked)" />
                                </div>
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per titolo o sottotitolo ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="input-group input-group-sm p-1">
                                <select class="form-control form-control-sm" name="filter_id_shop_start" [(ngModel)]="filter_id_shop_start.value" (change)="getItems()" [class.alert-info]="filter_id_shop_start">
                                    <option value="">Tutti i magazzini</option>
                                    <option *ngFor="let s of shops" value="{{s.id}}">{{s.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-group input-group-sm p-1">
                                <select class="form-control form-control-sm" name="filter_id_type" [(ngModel)]="filter_id_type.value" (change)="getItems()" [class.alert-info]="filter_id_type.value">
                                    <option value="">Tutte le tipologie</option>
                                    <option *ngFor="let t of types" value="{{t.id}}">{{t.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <ng-container *ngIf="mode!='embedded' && (user.role!='external' && user.role!='agent')">
                                <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i></button>
                                <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i></button>
                                <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i></button>
                                <button  title="Esporta" (click)="export()"  class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i></button>
                                <button  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i></button>
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                        <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <!-- List viewmode -->
                        <ul class="list-group" *ngIf="false">
                            <li class="list-group-item" *ngFor="let record of list">
                                <div class="row pb-2 border-bottom">
                                    <div class="d-none d-lg-block col-lg-1 px-lg-0">
                                        <input *ngIf="mode!='embedded'" type="checkbox" #checkrecord id_record="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" class="m-1" [checked]="checkedAll"
                                        />
                                        <small class="badge badge-primary float-right">{{record.code}}</small>
                                    </div>
                                    <div class="col-8 col-lg-5">
                                        <b (click)="openPreview(record)" class="text-primary mb-0 pointer"  title="Prenota">{{record.title}}</b><br>
                                        <span>{{record.subtitle}}</span><br>
                                        <small *ngIf="record.dates && record.dates.length>0">Partenze dal <b>{{record.dates[0].date_start | date:"dd/MM/YYYY"}}</b> da <b>{{record.dates[0].location_start}}</b></small>
                                    </div>
                                    <div class="d-none d-lg-block col-2">
                                        <img (click)="openPreview(record)" src="{{getCoverImage(record)}}" alt="nessun immagine" style="max-width:120px">
                                    </div>
                                    <div class="d-none d-lg-block col-lg-2">
                                        <ng-container *ngFor="let f of parseJSON(record.features)" >
                                            <small>{{f.feature}}: <b>{{f.value}}</b></small><br>
                                        </ng-container>
                                    </div>
                                    <div class="col-4 col-lg-2 pr-lg-0 text-lg-right">
                                        <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </button>
                                        <button type="button" *ngIf="mode!='modal' && mode!='embedded' && (user.role!='external' && user.role!='agent')" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                            <i class="fas fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openPreview(record)"  title="Prenota" class="btn btn-outline-primary btn-xs float-right m-1">
                                            <i class="fas fa-ticket-alt mr-1"></i><span class="d-none d-sm-inline-block">Prenota</span>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- Card viewmode -->
                        <div class="row mt-2" >
                            <ng-container *ngFor="let record of list">
                                <div *ngIf="user.isBackendUser() || record.type==1" class="col-12 col-xl-6">
                                    <div class="card bg-light">
                                        <div *ngIf="user.isBackendUser()" class="card-header py-1 pr-1 text-right">
                                            <div class="row">
                                                <div class="col-lg-7"></div>
                                                <div class="col-lg-5">
                                                    <div  class="input-group input-group">
                                                        <label class="m-1">Ordinamento</label>
                                                        <input type="number" class="form-control form-control-sm" name="{{i}}_ordering" [(ngModel)]="record.ordering">
                                                        <div class="input-group-append">
                                                            <button type="button" class="btn btn-sm btn-secondary" (click)="updateOrdering(record);"  title="Ordina"><i class="fas fa-sort-numeric-down"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body py-0">
                                            <div class="row">
                                                <div class="col-7 py-3 pb-1" style="min-height:230px">

                                                    <small class="badge bg-gray mr-2">{{record.code}}</small>
                                                    <span *ngIf="user.isBackendUser() && mode!='embedded'">
                                                        <i *ngIf="record.type==2" class="fas fa-eye-slash text-danger"  title="Non visibile"></i>
                                                        <i *ngIf="record.type!=2" class="fas fa-eye text-success"  title="Visibile"></i>
                                                    </span><br>
                                                    <b>{{record.title}}</b><br>
                                                    <span>{{record.subtitle}}</span><br>
                                                    <small *ngIf="record.dates && record.dates.length>0">Partenze dal <b>{{record.dates[0].date_start | date:"dd/MM/YYYY"}}</b><br>
                                                        da <b>{{record.dates[0].location_start}}</b>
                                                    </small><br><br>
                                                    <ng-container *ngFor="let f of parseJSON(record.features)">
                                                            <small class="text-gray">{{f.feature}}: <b>{{f.value}}</b></small><br>
                                                    </ng-container>
                                                </div>
                                                <div class="col-5 px-0 my-1 text-right" 
                                                    style="background-image: url({{getCoverImage(record)}});background-repeat:no-repeat; background-position:center; background-size:cover;">
                                                    <input *ngIf="mode!='embedded' && filterbox" type="checkbox" #checkrecord id_record="{{record.id}}"
                                                        (change)="onChange(record.id, $event.target.checked)" class="m-1" [checked]="checkedAll"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer py-1 pr-1 text-right">
                                            <span class="text-md mt-2 float-left" *ngIf="record.dates && record.dates.length>0">A partire da <b class="text-lg mt-2">{{record.dates[0].min_price | currency:" &euro;"}}</b>/<small>persona</small></span>
                                            <span class="text-md mt-2 float-left" *ngIf="record.dates && record.dates.length==0">Nessuna data disponibile!</span>
                                            <a type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-sm" (click)="selectRecord(record);">
                                                <span class="d-none d-sm-inline-block mx-1">Seleziona</span><i class="fas fa-chevron-right"></i></a>
                                            <a type="button" *ngIf="mode!='modal' && mode!='embedded' && (user.role!='external' && user.role!='agent' && user.role!='guest')" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-sm mx-1">
                                                <i class="fas fa-pencil-alt"></i></a>
                                            <a type="button" *ngIf="mode!='modal'" (click)="openPreview(record)"  title="Prenota" class="btn btn-success btn-sm mx-1">
                                                <span *ngIf="record.dates && record.dates.length==0">Info</span>
                                                <span *ngIf="record.dates && record.dates.length>0">Prenota</span>
                                                <i class="fas fa-chevron-right ml-2"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </form>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>

