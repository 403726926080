<div class="wrapper" *ngIf="currentIsLogin==true">
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand fixed-top elevation-1 p-0 ml-0">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item" >
        <a class="nav-link" routerLink="/shop" routerLinkActive="active" ariaCurrentWhenActive="page">VENDITA</a>
      </li>
      <li class="nav-item" >
        <a class="nav-link" routerLink="/tables" routerLinkActive="active" ariaCurrentWhenActive="page">TAVOLI</a>
      </li>
   
      <li class="nav-item" >
        <a class="nav-link" routerLink="/settings" routerLinkActive="active" ariaCurrentWhenActive="page">IMPOSTAZIONI</a>
      </li>
    </ul>
    <div class="navbar-nav" >
      <h5 class="text-secondary m-0" *ngIf="history.length > 0"><b>{{history[history.length-1].label}}</b></h5>
    </div>
    
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">

       <!-- Connector -->
       <li class="nav-item text-center pt-2 mx-3" style="color: rgba(0, 0, 0, 0.5)"  title="C1 Connector" *ngIf="enableC1Connector">
        <i [class.text-success]="c1connector_status" class="fas fa-network-wired"></i><br>
          <span class="float-left" style="font-size:7pt">{{c1connector_c1connectorid}}</span>
      </li>
     
      <!-- User login -->
      <li class="nav-item dropdown" *ngIf="true">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fas fa-user-tie mr-2"></i>
          <small  title="{{currentUser.name}}" class="d-none d-sm-inline-block">{{currentUser.name}} </small><br/>
          <small  title="{{currentUser['shop']['name']}}" style="max-width:120px; font-size:7pt" class="d-none d-sm-inline-block float-left text-uppercase text-truncate" 
            *ngIf="currentUser['shop']">{{currentUser['shop']['name']}}
            <!-- <span class="badge badge-warning navbar-badge">0</span> -->
          </small>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right pt-0">
          <a *ngIf="enableSelectPoint" (click)="openSelectPoint()" class="dropdown-item" style="cursor:pointer;"><i class="fas fa-map-marker-alt mr-2"></i>Cambia ristorante</a>
          <a (click)="logout()" class="dropdown-item"  style="cursor:pointer;"><i class="fas fa-sign-out-alt mr-2"></i>Logout</a>
        </div>
      </li>

      <!-- Impostazioni -->
      <li class="nav-item dropdown" *ngIf="menu_settings.length>0 && currentUser.isAdmin()" >
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="fas fa-cog"></i>
          <!-- <span class="badge badge-warning navbar-badge">0</span> -->
        </a>
        <div class="dropdown-menu dropdown-menu-right pt-0">
          <span class="dropdown-item dropdown-header bg-primary"><h6 class="mb-0">Impostazioni</h6></span>
          <a *ngFor="let m of menu_settings"  (click)="goToView(m)" class="btn btn-light dropdown-item">
            <i class="{{m.icon}} mr-2"></i>{{m.label}}
          </a>  
        </div>
      </li>


    </ul>
  </nav>
  <!-- /.navbar -->

  
  <div id="sidebar-overlay"><div></div></div>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper ml-0 pt-1">
      <router-outlet></router-outlet>
      <div>
        <ng-template #mainContent>
  
        </ng-template>
      </div>
  </div>
  <!-- /.content-wrapper -->
  
  
  <!-- Control Sidebar 
  <aside class="control-sidebar control-sidebar-light">
    <div class="p-3 control-sidebar-content">
      
    </div>
  </aside>-->
  <!-- /.control-sidebar -->

  <!-- Main Footer width: calc(100% - 250px); -->
  <footer class="main-footer fixed-bottom py-0">
    <div class="float-left mx-2"><b>{{config.softwarename}}</b></div>
    <div class="float-left mr-5">versione <span class="">{{config.softwareversion}}</span></div>
    <div *ngIf="copyright" class="float-left mr-5 d-none d-lg-block">is a <a href="http://codingict.it" target="_blank">Coding</a> software</div>
    <div class="float-right d-none d-sm-inline-block ml-5">Licenza:&ensp;<span  title="Server: {{config.serverUrl}}" class="">{{company.name}}<span *ngIf="company.vat_number"> (P.IVA: {{company.vat_number}})</span></span></div>
  </footer>

</div>


<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>