<app-windowlist [model]="this">
    <div class="row">
        <div class="col-12">
            <div class="card px-1 card-outline">
               
                    <div class="row p-1">
                        <div class="col-md-7">
                            <div class="input-group input-group-sm">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per Ragione sociale, indirizzo, comune, P.IVA, codice fiscale ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"  title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                    <button class="btn btn-default"   title="Regole" data-toggle="collapse" data-target="#advancedSearchPanel"  aria-expanded="false" aria-controls="advancedSearchPanel" ><span id="searchclear" class="fas fa-sliders-h"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-5 col-md-2">
                            <div class="input-group input-group-sm">
                                <select class="custom-select" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_type.value">
                                    <option value="0">Tutti</option>
                                    <option *ngFor="let t of type_address" [ngValue]="t.id">
                                        {{t.name}}</option>
                                </select>
                            </div>
                        </div>
                        <!--<div class="col-2">
                            <div class="input-group input-group-sm m-1" >
                                <select class="custom-select" [(ngModel)]="filter_status.value" (ngModelChange)="getItems()">
                                    <option value="1">Attivi</option>
                                    <option value="2">Cestinati</option>
                                    <option value="3">Non attivi</option>
                                </select>
                            </div>
                        </div>-->
                        <div class="col-7 col-md-3">
                            <div *ngIf="mode!='modal'">
                                <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                                <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                                <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                                <button  title="Unisci"  [disabled]="form.value.id.length < 2" (click)="joinAddresses()" class="btn btn-primary btn-xs float-right ml-1"><i class="fas fa-user-friends"></i></button>
                                <button  title="Esporta" (click)="export()"  class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                            </div>
                            <button  title="Aggiungi" (click)="newAddress()" class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button>
                        </div>
                        <div class="col-12 mb-1">
                            <small class="ml-2">
                                <input type="checkbox" name="filter_search_contact" [(ngModel)]="filter_search_contact.enabled">
                                    <i>&nbsp;cerca anche nei contatti</i>
                            </small>
                            <small *ngIf="mode!='modal'" #checkboxselectall class="float-right mr-2">
                                <i>seleziona tutti&nbsp;</i>
                                <input  type="checkbox" (change)="onChangeAll($event.target.checked)" />
                                
                            </small>
                            <small class="float-right mr-3">
                                <i>visualizzazione lista&nbsp;</i>
                                <input type="checkbox"  (change)="listmode==true?listmode=false:listmode=true">
                            </small>
                        </div>
                        <div class="col-12">
                            <div  class="small-box bg-light m-1 p-1 collapse" id="advancedSearchPanel">
                                <div class="row">
                                    <div class="col-xs-12 col-lg-11">
                                        <app-advancedsearch [id_field]="'a.id'" [table]="'addresses'" [filters]="advancedFilter"></app-advancedsearch>
                                    </div>
                                    <div class="col-xs-12 col-lg-1 pl-1">
                                        <button title="Filtra" class="btn btn-outline-secondary btn-block btn-sm float-right" (click)="getItems()"><i class="fa fa-filter"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                        <!-- <br><a type="button" (click)="" class="btn btn-outline-secondary btn-sm m-3">Azzera filtri di ricerca</a> -->
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">

                        <!-- List viewmode -->
                        <ul class="list-group" *ngIf="listmode">
                            <li class="list-group-item list-group-item-action border-bottom" *ngFor="let record of list"  >
                                <div class="row">
                                    <div class="col-1 p-1 d-md-block d-none text-center" (click)="openDetail(record)" style="cursor: pointer">
                                        <img *ngIf="record.logo" class="w-75" src="{{record.logo}}">
                                        <i *ngIf="!record.logo" class="fa fa-user-circle fa-3x text-light"></i>
                                    </div>
                                    <div class="col-9 pl-1 col-md-5">
                                        <h6 class="mb-0 text-capitalize text-primary" (click)="openDetail(record)" style="cursor: pointer;">
                                            <strong>{{record.name}}</strong>
                                        </h6>
                                        <small>
                                            <span class="text-capitalize">{{record.address}}</span>
                                            <span *ngIf="record.address">, </span>
                                            <b class="text-capitalize">{{record.city}}</b><span class="text-uppercase" *ngIf="record.city && record.country && record.country!='EE'"> {{record.country}}</span><br/>
                                            <span *ngIf="record.vat_number && record.vat_number!='XXXXXXXXXXX'">P.IVA: <span class="text-uppercase">{{record.vat_number}}</span>
                                                <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">  /  </span>
                                            </span>
                                            <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">Cod. Fisc.: <span class="text-uppercase">{{record.tax_code}}</span></span>
                                        </small>
                                    </div>
                                    <div class="col-2 d-md-block d-none">
                                        <div *ngIf="record.balance"  title="Saldo">
                                            <!-- <i class="fas fa-coins mr-2"></i> -->
                                            <div class="badge border text-right" [class.text-red]="record.balance < 0" [class.text-success]="record.balance > 0">
                                                {{record.balance | currency: "&euro; "}}</div>
                                        </div>
                                    </div>
                                    <div class="col-2 d-md-block d-none">
                                        <div *ngIf="record.notes.length>0"  title="">
                                            <i class="fas fa-exclamation mr-2"></i>
                                            <small class="text-right">{{record.notes.length}}
                                                <span *ngIf="record.notes.length>1">note</span>
                                                <span *ngIf="record.notes.length==1">nota</span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-3 col-md-2 px-0 text-right">
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}"   title="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"/>
                                        
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right mr-1">
                                            <i class="fas fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="notify(record);" title="Notifica" class="btn btn-primary btn-xs float-right mr-1">
                                            <i class="far fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- Card viewmode -->
                        <div class="row mt-2" *ngIf="!listmode">
                            <div class="col-12 col-lg-6 col-xl-4" *ngFor="let record of list">
                                <div class="card">
                                    <div class="card-body py-0">
                                        <div class="row">
                                            <div class="col-3 pr-0 d-flex align-items-center justify-content-center">
                                                <img *ngIf="record.logo" class="w-75" src="{{record.logo}}">
                                                <i *ngIf="!record.logo" class="fa fa-user-circle fa-3x text-light"></i>
                                            </div>
                                            <div class="col-9 pr-0" style="min-height:120px">
                                                <input type="checkbox" #checkrecord id_record="{{record.id}}"   title="{{record.id}}"
                                                    (change)="onChange(record.id, $event.target.checked)" class="mt-1 float-right" [checked]="checkedAll"/>
                                                    <br>
                                                <h6 class="mb-0 text-capitalize" (click)="openDetail(record)" style="cursor: pointer;">
                                                    <strong>{{record.name}}</strong>
                                                </h6>
                                                <small>
                                                    <span class="text-capitalize">{{record.address}}</span>
                                                    <span *ngIf="record.address">, </span>
                                                    <b class="text-capitalize">{{record.city}}</b><span class="text-uppercase" *ngIf="record.city && record.country && record.country!='EE'"> {{record.country}}</span><br/>
                                                    <span *ngIf="record.vat_number && record.vat_number!='XXXXXXXXXXX'">P.IVA: <span class="text-uppercase">{{record.vat_number}}</span>
                                                        <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">  /  </span>
                                                    </span>
                                                    <span *ngIf="record.tax_code && record.tax_code!='XXXXXXXXXXX'">Cod. Fisc.: <span class="text-uppercase">{{record.tax_code}}</span></span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer p-1 text-right">
                                        <div class="float-left mt-1" *ngIf="record.balance" >
                                            <!-- <i class="fas fa-coins text-gray mr-1"></i> -->
                                            <div *ngIf="record.balance!=0" title="Saldo" class="badge border" [class.text-red]="record.balance < 0" [class.text-success]="record.balance > 0">
                                                {{record.balance | currency: "Euro"}}</div>
                                            <ng-container *ngIf="record.notes.length>0">
                                                &ensp;
                                                <i class="far fa-sticky-note text-gray ml-4 mr-1"></i>
                                                <small title="Ci sono delle annotazioni">{{record.notes.length}}</small>
                                            </ng-container>
                                        </div>
                                        <a type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-sm" (click)="selectRecord(record);">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                        </a>
                                        <a type="button" *ngIf="mode!='modal'" (click)="notify(record);" title="Notifica" class="btn btn-primary btn-sm mr-1">
                                            <i class="far fa-paper-plane"></i>
                                        </a>
                                        <a type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-sm mr-1">
                                            <i class="fas fa-pencil-alt"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>
