import { Injectable } from '@angular/core';
import { WSService } from 'projects/core/src/include/service';
import { Room, Tables } from '../restaurantcashregister';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})
export class RestaurantroomsService extends WSService<Room> {

  constructor() { super("restaurantrooms")}

   /** Restituisce l'elenco delle sale attive */
   getRooms():Observable<any[]>{
    return Globals.ws.requestUrl<any>("restaurantrooms","getItems",[]);
  }

  

}
