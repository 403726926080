import { Injectable } from '@angular/core';
import { WSService } from 'projects/core/src/include/service';
import { Tables } from '../restaurantcashregister';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})
export class RestauranttablesService extends WSService<Tables> {

  constructor() { super("restauranttables")}

  /** Restituisce l'elenco dei tavoli con lo stato dell'ordine attuale */
  getTableOrders(id_room,date,filter_id_state):Observable<any[]>{
    return Globals.ws.requestUrl<any>("restauranttables","getTableOrders",[id_room,date,filter_id_state]);
  }

   /** Restituisce l'elenco delle sale attive */
   getTables(id_room):Observable<any[]>{
    return Globals.ws.requestUrl<any>("restauranttables","getItems",["id_room="+id_room]);
  }

}
