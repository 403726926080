import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'projects/core/src/globals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentDetail } from 'projects/c1-backend/src/app/db/documents';
import { DocumentsType } from 'projects/c1-backend/src/app/db/documents';
import { Documents } from 'projects/c1-backend/src/app/db/documents';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { ProductcategoriesService } from 'projects/c1-backend/src/app/services/productcategories.service';
import { ProductsService } from 'projects/c1-backend/src/app/services/products.service';
import { Model } from 'projects/core/src/include/model';
import { Course, Good, Order } from 'projects/modules/src/app/restaurantcashregister/restaurantcashregister';
import { RestaurantordersService } from 'projects/modules/src/app/restaurantcashregister/services/restaurantorders.service';
import { KeyboardComponent } from '../../modules/keyboard/keyboard.component';
import { DocumentsService } from 'projects/c1-backend/src/app/services/documents.service';
import { Helper } from 'projects/core/src/helper';

@Component({
  selector: 'app-table-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class TableDetailComponent extends Model<Order> implements OnInit {

  @ViewChild("modalSendCommand")
  modalSendCommand:TemplateRef<any>;
  modalSendCommandRef;

  @ViewChild("modalCloseAccount")
  modalCloseAccount:TemplateRef<any>;
  modalCloseAccountRef;

 

  @Input()
  id_restaurant_table=0;

  @Input()
   tablename=""

  categories=[];
  selected_category=0;
  products=[];
  selected_course=1;
  current_course=1;
  goods=[];
  covercharge:Product=new Product();
  document:Documents;

  divider=1;


  cashed=0;

  get course_count(){
    let count=0;
    for(let c of this.record.courses){
      if(c.goods)
        count=count+c.goods.length
    }

    return count;
  }

  get showSendCourses(){
    for(let c of this.record.courses){
      if(c.state==3 && c.goods && c.goods.length>0){
        return true;
      }
    }

    return false;
  }

  constructor(
    private route1: ActivatedRoute,
    private restaurantorderService: RestaurantordersService,
    private productsService:ProductsService,
    private productCategories:ProductcategoriesService,
    private modalService:NgbModal,
    private documentsService:DocumentsService
    
  ) {
    super(route1,restaurantorderService);
  }

  ngOnInit(): void {
    this.record=new Order();
    this.record.id_restaurant_table=this.id_restaurant_table;
    this.record.tablename=this.tablename;
    this.productCategories.getCategories().subscribe((result)=>{
      this.categories=result;
    });
    this.getItem()

    //carica il prodotto per il coperto
   this.getCoverCharge();

    this.afterGetItem=()=>{
       //definisci a quale portata ti trovi
   
      for(let c of this.record.courses){

        if(c.state==3){
          this.current_course=c.number;
          this.selected_course=this.current_course;
          break;
        }
      }

    }

  }

  getCoverCharge(){
    this.productsService.getProductsByCodeInternal("COVERCHARGE").subscribe((results)=>{
      
      if(results.length > 0){
        let p:Product=new Product();
        Object.assign(p,results[0]);
        this.covercharge =p;
     
      }
      
    })
  }

 

  //mostra i prodotti relativi alla categoria selezionata
  onSelectCategory(id_category){
    Globals.loading=true;
    this.productsService.getCategory(id_category).subscribe((result)=>{
      //converti nella classe Product
      let products=[];
      for(let item of result){
        let product:Product=new Product();
        Object.assign(product,item);
        products.push(product);
      }
      this.products=products;
    })
    Globals.loading=false;
  }

  //aggiunge un prodotto selezionato alla portata corrente
  addProduct(product:Product){
      //ricerca se lo stesso prodotto è stato già inserito nella portata selezionata
      if(this.record.courses[this.selected_course-1].goods){
        for(let g of this.record.courses[this.selected_course-1].goods){
          if(g.id_product==product.id){
            g.quantity=g.quantity+1;
            return;
          }
        }
      }

      //se il prodotto non è stato già aggiunto, aggiungilo
      let good:Good=new Good();
      good.course=this.selected_course;
      good.id_product=product.id;
      good.product=product;
      good.quantity=1;
      good.net_price=product.getPrice(good['quantity']);
      good.tax=product.tax;

      if(this.record.courses[this.selected_course-1].goods==undefined){
        this.record.courses[this.selected_course-1].goods=[];
      }

      this.record.courses[this.selected_course-1].goods.push(good);

  }

  //rimuove un prodotto dall'elenco
  removeGood(course,id_product){
    //cerca il prodotto nella portata corrispondente
    for(let i=0;i<this.record.courses[course-1].goods.length;i++){
      if(this.record.courses[course-1].goods[i]['id_product']==id_product){
        if(this.record.courses[course-1].goods[i]['quantity']>1){
          this.record.courses[course-1].goods[i]['quantity']=this.record.courses[course-1].goods[i]['quantity']-1;
        }else{
          this.record.courses[course-1].goods.splice(i,1);
        }

        return;
      }
    }
  }
  

  setState(id_state){
    this.record.state=id_state;
    this.saveAndClose();
  }

  openModalSendCommand (){
    this.modalSendCommandRef=this.modalService.open(this.modalSendCommand,{size:"md"});
  }
  closeModalSendCommand(){
    this.modalSendCommandRef.close();
  }
  
  /** Imposta le comande come servite ed invia alla stampa */
  sendCommand(course=0,print=false){
    if(course==0){ //invia tutte le comande
      for(let i=0;i<this.record.courses.length;i++){
        this.record.courses[i].state=4; //Servito
      }
    }else{
      this.record.courses[course-1].state=4;
    }

    this.closeModalSendCommand();
    this.saveAndClose();

  }


  createDocument(){
    this.document=new Documents();
    this.document.typedocument=DocumentsType.SCONTRINO;
    this.document.description="Tavolo "+this.record.tablename;
    this.document.id_table=this.record.id;
    this.document.table="restaurant";


    //inserisci i beni nell'elenco
    this.document.details=[];

    for(let c of this.record.courses){
      if(c.goods){
        for(let g of c.goods){

          let detail:DocumentDetail=new DocumentDetail();
          detail.id_product=g.id_product;
          detail.quantity=g.quantity;
          detail.product=g.product;
          detail.netprice=g.net_price;
          detail.tax=g.tax;

          this.document.addDetail(detail,true);

        }
      }
    }
    //inserisce il coperto
    if(this.covercharge.netprice>0){
      let detail:DocumentDetail=new DocumentDetail();
        detail.id_product=this.covercharge.id;
        detail.quantity=this.record.adults;
        detail.product=this.covercharge;
        detail.netprice=this.covercharge.netprice;
        detail.tax=this.covercharge.tax;

        this.document.addDetail(detail,true);
    }

    this.document.calculateTotals();
    this.cashed=this.document.total-this.document.discount;

  }


  openCloseAccount(){
    this.createDocument();
    this.modalCloseAccountRef=this.modalService.open(this.modalCloseAccount,{size:"lg"});
  }
  closeCloseAccount(){
    this.modalCloseAccountRef.close();
  }



  discount(){

    let modal=this.modalService.open(KeyboardComponent);
    modal.componentInstance['data'].subscribe((res)=>{
      modal.close();
      this.document.discount=res.value;
    });
    

   
  }


  divide(){}

  percapita(){}

  preaccount(){

    this.closeCloseAccount();
    this.record.state=5;
    this.saveAndClose();

  }

  rest(){
    let modal=this.modalService.open(KeyboardComponent);
    modal.componentInstance['data'].subscribe((res)=>{
      modal.close();
      this.cashed=res.value;
    });
    
  }

  receipt(){
    this.documentsService.save(this.document,()=>{
      this.closeCloseAccount();
      this.record.state=6;
      this.record.checkout=Helper.convertDateControl(new Date(),true);
      this.saveAndClose();
      
    });
  }


  delete(){

    this.closeCloseAccount();
    this.record.state=6;
    this.saveAndClose();

  }


}
