<div class="modal-body">

    <ng-container *ngIf="state_cashregister==0">
        <h5>Ricerca registratore di cassa in corso...</h5>
    </ng-container>


    <ng-container *ngIf="state_cashregister==1">
        <h5>Stampa in corso su registratore {{rt}}...</h5>
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn btn-secondary" (click)="closePrintModal('error')">Annulla</button>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="state_cashregister==2">
        <div class="text-danger text-center m-5">
            <i class="fas fa-exclamation-triangle mr-2"></i>Nessun registratore di cassa collegato al sistema
        </div>
        <h5>Confermare lo scontrino comunque?</h5>
        <p>Procedi con l'emissione dello scontrino direttamente dal registratore e indica qui di seguito il numero dello scontrino</p>
        <div class="row">
            <div class="col-12">
                <label>Numero di scontrino</label>
                <input type="text" autofocus name="reference" class="form-control form-control-sm" placeholder="Indicare il numero di scontrino" [(ngModel)]="document.reference" />
            </div>
            <div class="col-12 mt-4 text-right">
                <button class="btn btn-success ml-2" (click)="confirmManual()">Procedi</button>
                <button class="btn btn-secondary ml-2" (click)="closePrintModal('error')">Annulla</button>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="state_cashregister==3">
        <h5>Errore durante la stampa</h5>
        <div class="row">
            <div class="col-12 text-right">
                <button class="btn btn-success" (click)="state_cashregister=1;handleDocument()">Riprova</button>
                <button class="btn btn-primary" (click)="state_cashregister=2">Manuale</button>
                <button class="btn btn-secondary" (click)="closePrintModal('error')">Annulla</button>
            </div>
        </div>
    </ng-container>

</div>
