import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'projects/core/src/globals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentDetail } from 'projects/c1-backend/src/app/db/documents';
import { DocumentsType } from 'projects/c1-backend/src/app/db/documents';
import { Documents } from 'projects/c1-backend/src/app/db/documents';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { ProductcategoriesService } from 'projects/c1-backend/src/app/services/productcategories.service';
import { ProductsService } from 'projects/c1-backend/src/app/services/products.service';
import { Model } from 'projects/core/src/include/model';
import { Course, Good, Order } from 'projects/modules/src/app/restaurantcashregister/restaurantcashregister';
import { RestaurantordersService } from 'projects/modules/src/app/restaurantcashregister/services/restaurantorders.service';
import { KeyboardComponent } from '../modules/keyboard/keyboard.component';
import { DocumentsService } from 'projects/c1-backend/src/app/services/documents.service';
import { Helper } from 'projects/core/src/helper';
import { ProductCategories } from 'projects/c1-backend/src/app/db/productcategories';
import { MethodpaymentComponent } from 'projects/c1-backend/src/app/views/inventories/methodpayment/methodpayment.component';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {

  categories=[];
  selected_category:ProductCategories=null;
  products=[];

  document:Documents=new Documents();

  keyboardShow=false;

  constructor(
    private productsService:ProductsService,
    private productCategories:ProductcategoriesService,
    private modalService:NgbModal,
    private documentsService:DocumentsService
  ) { }

  ngOnInit(): void {
    this.productCategories.getCategories().subscribe((result)=>{
      let categories=[];
      for(let r of result){
        let c:ProductCategories =new ProductCategories();
        Object.assign(c,r);
        categories.push(c);

      }


      this.categories=categories;
      if(this.categories.length>0)
        this.onSelectCategory(this.categories[0]);

    });
  }

   //mostra i prodotti relativi alla categoria selezionata
   onSelectCategory(category:ProductCategories){
    this.selected_category=category;
    Globals.loading=true;
    this.productsService.getCategory(category.id).subscribe((result)=>{
      //converti nella classe Product
      let products=[];
      for(let item of result){
        let product:Product=new Product();
        Object.assign(product,item);
        products.push(product);
      }
      this.products=products;
    })
    Globals.loading=false;
  }

  //aggiunge un prodotto selezionato alla portata corrente
  addProduct(product:Product){
    
    //ricerca se lo stesso prodotto è stato già inserito nella portata selezionata
     
      for(let g of this.document.details){
        if(g.id_product==product.id){
          g.quantity=g.quantity+1;
          this.document.calculateTotals();
          return;
        }
      }
     

      //se il prodotto non è stato già aggiunto, aggiungilo
      let good:DocumentDetail=new DocumentDetail();
      
      good.id_product=product.id;
      good.product=product;
      good.quantity=1;
      good.netprice=product.getPrice(good['quantity']);
      good.tax=product.tax;

      this.document.details.push(good);
      this.document.calculateTotals();
  }


  addCustomProduct(event){
    let good:DocumentDetail=new DocumentDetail();
    good.tax=this.selected_category.tax;
    good.description=this.selected_category.name;
    good.id_product=0;
    good.netprice=event.value/(1+good.tax/100);
    good.quantity=event.quantity;
    good.discount1=event.percentual;
    
    
    this.document.details.push(good);


    // this.keyboardShow=false;
    this.document.calculateTotals();
  }

  removeGood(d:DocumentDetail){
    for(let i=0;i<this.document.details.length;i++){
      if(d==this.document.details[i]){

        if(this.document.details[i].quantity>1){
          this.document.details[i].quantity=this.document.details[i].quantity-1;
        }else{
          this.document.details.splice(i,1);
        }
        this.document.calculateTotals();
        
        return;
      }
    }
  }


  removeAll(){
    this.document.details=[];
    this.document.calculateTotals();
  }

  receipt(){

    this.document.typedocument=DocumentsType.SCONTRINO;
    this.document.enable_inventory=true;
    
    
    Globals.modal.showModal(MethodpaymentComponent,[{"name":"total","value":this.document.total}],(instance)=>{
      if(instance==null){
        return;
      }
      this.document.methodpayment=instance["listMethods"];
      this.document.paid=instance['total_paid'];
      
      this.documentsService.save(this.document,(return_id)=>{
        this.reset();
          let m={};
          m['where']=return_id;
          /*
          this.templatesService.openTemplate(this.id_template_receipt,JSON.stringify(m)).subscribe((file_path)=>{
            printFile(file_path);
            
            
          });*/
      });
  
    },"sm");

    /*
    this.documentsService.save(this.document,()=>{
      this.document=new Documents();
      
    });
    */
  }

  reset(){
    this.document=new Documents();
  }

}
