import { Address } from "projects/c1-backend/src/app/db/address";
import { Product } from "projects/c1-backend/src/app/db/product";
import { Shops } from "projects/c1-backend/src/app/db/shops";
import { Globals } from "projects/core/src/globals";
import { Helper } from "projects/core/src/helper";
import { File } from "projects/core/src/include/structures";

export class BookingDetail{
    id:number;
    id_product:number;
    id_booking:number;
    quantity:number;
    max_quantity:number;
    product:Product;
    price:number;
    gross:number;
    discount:number;
    tax:number;
    id_inventory:number;
    addressItem:Address;
    shop_start:string;
    shop_end:string;
    description:string;
    
   
    
}


export class Booking{

    id:number;
    code:string;
    id_address:number;
    id_user:number;
    created:Date;
    status:number;
    state:number;
    id_shop_start:number;
    id_shop_end:number;
    date_start:string;
    date_end:string;
    time_start:string;
    time_end:string;
    amount:number;
    netWithTax:number;
    discount:number;
    discountValue:number;
    tax:number;
    taxWithoutDiscount:number;
    details:BookingDetail[];
    _shop_start:Shops;
    _shop_end:Shops;
    addressItem:Address;
    methodpayment:string;
    tracking:string;
    adults:number;
    children:number;
    id_tour:number;
    checkin:string;
    checkout:string;
    checkin_iduser:number;
    checkout_iduser:number;
    typeprice:number;
    confirmDetail:any;
    username:string;
    checkin_username:string;
    checkout_username:string;
    type:number;
    tour:any;
    note:string;
    id_rentalprice:number;
    rentalprice:string;
    documents:any[]=[];
    files:File[];
    clientname:string="";
    clientnote:string="";
    clientemail:string="";
    clientphone:string="";

    
    id_supplier:number;
    supplierItem:Address;
    source:string;
    type_address:number=0; //0 == b2c ; 1== b2b
    
    get shop_start(){return this._shop_start;}
    set shop_start(value){
        this._shop_start=value;
        if(value && value.id) 
            this.id_shop_start=value.id
    }
    get shop_end(){return this._shop_end;}
    set shop_end(value){
        
        this._shop_end=value;
        if(value && value.id)
            this.id_shop_end=value.id
        
    }
    
    

    
    

    constructor(type=1){

        this.type=type;

        this.shop_start=new Shops();
        this.shop_end=new Shops();
        this.source=Globals.config.id;

        const today_start =  new Date(new Date().setDate(new Date().getDate()));
        const today_end =  new Date(new Date().setDate(new Date().getDate()));
        const today_last =  new Date(new Date().setDate(new Date().getDate()));

        const tomorrow_start =  new Date(new Date().setDate(new Date().getDate() + 1));
        const tomorrow_end =  new Date(new Date().setDate(new Date().getDate() + 1));

        tomorrow_start.setHours(8,30,0,0);
        tomorrow_end.setHours(19,30,0,0);

        today_start.setHours(8,30,0,0);
        today_end.setHours(19,30,0,0);
        today_last.setHours(18,30,0,0);

        
        //calcola l'orario di prelievo un'ora dopo l'attuale
        const now=new Date();
        if(now>today_last){
            today_start.setDate(today_start.getDate()+1);
            today_end.setDate(today_end.getDate()+1);
            
            
        }else{
            today_start.setHours(now.getHours()+1,30,0,0);
        }
            
            

      

        
       

        if(type==2){ // tour
            this.date_start=Helper.convertDateControl(tomorrow_start);
            this.date_end=Helper.convertDateControl(tomorrow_end);

            this.time_start=tomorrow_start.getHours().toString().padStart(2, "0")+":"+tomorrow_start.getMinutes().toString().padStart(2, "0") ;
            this.time_end=tomorrow_end.getHours().toString().padStart(2, "0")+":"+tomorrow_end.getMinutes().toString().padStart(2, "0") ;
        
            
        


        }else{
            

            this.date_start=Helper.convertDateControl(today_start);
            this.date_end=Helper.convertDateControl(today_end);

             this.time_start=today_start.getHours().toString().padStart(2, "0")+":"+tomorrow_start.getMinutes().toString().padStart(2, "0") ;
            this.time_end=today_end.getHours().toString().padStart(2, "0")+":"+tomorrow_end.getMinutes().toString().padStart(2, "0") ;
        
        }
        this.typeprice=1; //calcola euro/persona/gg 2= euro/persona
        
        
        this.amount=0;
        this.discount=0;
        this.adults=1;
        this.children=0;
        this.tax=0;
        this.details=[];

        this.addressItem=new Address();
        this.methodpayment="Contanti";

        if(Globals.user.isExternal())
            this.type_address=1;


    }


    get duration():number{

        if(this.typeprice==2)
            return 1;
        else{

            var date1 = Helper.convertString2Date(this.date_start.toString()+" "+this.time_start+":00");
            var date2 = Helper.convertString2Date(this.date_end.toString()+" "+this.time_end+":00");
            var Time = date2. getTime() - date1. getTime();
            var timestamp=Time / (1000 * 3600 * 24);
            var Days = Math.ceil(timestamp); //Diference in Days.
    
            return Days;
        }
    }

    get durationHours():number{

        if(this.typeprice==2)
            return 1;
        else{

            var date1 = Helper.convertString2Date(this.date_start.toString()+" "+this.time_start+":00");
            var date2 = Helper.convertString2Date(this.date_end.toString()+" "+this.time_end+":00");
            var Time = date2. getTime() - date1. getTime();
            var timestamp=Time / (1000 * 3600);
            var hours = Math.ceil(timestamp); //Diference in Days.
    
            return hours;
        }
    }

    checkDates(){
        var date1 = Helper.convertString2Date(this.date_start.toString()+" "+this.time_start+":00");
        var date2 = Helper.convertString2Date(this.date_end.toString()+" "+this.time_end+":00");
       
        if(date2. getTime()>= date1. getTime())
            return true;
        else
            return false;


    }

    checkDates2(){
        var date1 = Helper.convertString2Date(this.date_start.toString()+" "+this.time_start+":00");
        var date2 = new Date();
       
        if(date1. getTime()>= date2. getTime())
            return true;
        else
            return false;


    }

    checkCancel(){
        var date1 = Helper.convertString2Date(this.date_start.toString()+" "+this.time_start+":00");
        var tomorrow=new Date();
        var numOfHours=24;
        tomorrow.setHours(tomorrow.getHours() + numOfHours);
        if(date1>tomorrow){
            return true;
        }else{
            return false;
        }

    }

    //calcola la durata della prenotazione basandosi dai dati di checkin e checkout
    get realDurationHour(){
        var date1 = Helper.convertString2Date(this.checkin);
        var date2 = Helper.convertString2Date(this.checkout!='0000-00-00 00:00:00'?this.checkout:Helper.convertDateControl(null,true));
        var Time = date2. getTime() - date1. getTime();
        var timestamp=Time / (1000 * 3600);
        var hours = parseFloat(timestamp.toFixed(2)); //Diference in Days.

        return hours;

    }

    get countTotalQuantity(){
        let t=0;
        for(let d of this.details){
            t=t+d.quantity;
        }
        return t;
    }

    get countPax(){
        return parseInt(this.adults.toString())+parseInt(this.children.toString());

    }

    get passengers(){
        try{
            let j=JSON.parse(this.confirmDetail);
            return j['passengers'];
        }catch{
            return [];
        }
        
    }

    get signature(){
        try{
            let j=JSON.parse(this.confirmDetail);
            return Globals.config.serverUrl+ j['signature'];
        }catch{
            return "";
        }
    }



    get dateStart():string{
        let d=this.date_start.split("-");
        return d[2]+"/"+d[1]+"/"+d[0]
    }

    set dateStart(value){
        let d=value.split("/");
        this.date_start=d[2]+"-"+d[1]+"-"+d[0]
        
    }

    get dateEnd():string{
        let d=this.date_end.split("-");
        return d[2]+"/"+d[1]+"/"+d[0]
    }

    set dateEnd(value){
        let d=value.split("/");
        this.date_end=d[2]+"-"+d[1]+"-"+d[0]
        
    }
}