<!-- <app-window [title]="record.title+' | '+record.subtitle"> -->
<div class="modal-header bg-light" *ngIf="showDescription">
    <h5 class="mb-0">{{record.title+' | '+record.subtitle}}</h5>
    <div class="card-tools">
        <button *ngIf="isModal" type="button" class="btn btn-tool float-right" (click)="close();" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>

<div class="modal-body">

    <div class="row">
        <div class="col-lg-5" *ngIf="showDescription">
            <!-- <div><i>{{record.subtitle}}</i></div> -->
            <div class="row">
                <div class="col-12 mt-2">
                    <span class="text-xs" [innerHTML]="record.description"></span>
                </div>
                <div class="col-12 my-1">
                    <strong>Fotografie</strong>
                </div>
                <div class="col-md-6 text-center mb-2" *ngFor="let i of record.images" >
                    <a href="{{i.imagePath}}" target="_blank"  title="Visualizza">
                        <img src="{{i.imagePath}}" class="img-thumbnail" style="max-height:200px;" >
                    </a>
                </div>
            </div>
        </div>

        <div [class.col-lg-7]="showDescription" [class.col-lg-12]="!showDescription">
            <div class="card">
                <!-- <div class="card-header bg-gray p-1">
                    Calendario prenotazione
                </div> -->
                <div class="card-body bg-light p-1">
                    <div class="row">
                        <div class="col-12">
                            <h5>Seleziona una data:</h5>
                        </div>
                        <div class="col-4 btn-previous pr-0 text-right">
                            <div class="btn btn-sm btn-secondary" mwlCalendarPreviousView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate">
                                <i class="fas fa-angle-left"></i>
                            </div>
                        </div>
                        <div class="col-4 pt-1 date text-center bg-white">
                            <b class="text-uppercase">{{ viewDate | calendarDate:(view + 'ViewTitle'):'It'}}</b>
                        </div>
                        <div class="col-4 btn-next pl-0 text-left">
                            <div class="btn btn-sm btn-secondary" mwlCalendarNextView [view]="view" (viewDateChange)="handleEvent($event)" [(viewDate)]="viewDate">
                                <i class="fas fa-angle-right"></i></div>
                            <div class="btn btn-sm btn-info float-right" mwlCalendarToday [(viewDate)]="viewDate">Oggi</div>
                        </div>
                        <div class="col-12 mt-2 calendarView">
                            <div class="border">
                                <mwl-calendar-month-view-header ></mwl-calendar-month-view-header>
                                <mwl-calendar-month-view
                                [viewDate]="viewDate"
                                [events]="events"
                                [locale]="locale"
                                [cellTemplate]="customCellTemplate"
                                (dayClicked)="dayClicked($event.day)"   >
                                </mwl-calendar-month-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>    
<!--  </app-window>
<app-toolbarmodel [model]="this" [showSave]="false"></app-toolbarmodel> -->

<ng-template  #customCellTemplate let-day="day" let-locale="locale">
    <span [class.bg-warning]="day.date==date_selected" class="bg-gray px-1 text-center text-lg">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    
    <ng-container *ngIf="day.events.length==0 || (day.date.getTime()+86400000) < today.getTime()">
        <small class="text-center">
            <h4 class="text-gray mt-4">&#10005;</h4>
        </small>
    </ng-container>
    
    <ng-container *ngIf="day.events.length>0 && (day.date.getTime()+86400000) >= today.getTime()">
        <ng-container *ngIf="currentUser.role!='guest'">
            <small class="d-none d-lg-inline px-1 border-bottom" *ngFor="let ev of day.events">
                <small>
                    <span class="mr-2">
                        <i class="fas fa-clock text-gray"></i>
                        {{ev.meta.time_start.substr(0,5)}}
                    </span>
                    <span class="">
                        <i class="fas fa-male text-gray mr-1"></i>
                        <span *ngIf="ev.meta.busy>0"><b class="text-primary">{{ev.meta.busy}}</b>/</span><span>{{ev.meta.quantity}}</span>
                    </span>
                </small>
            </small>
            <small class="d-block d-lg-none" *ngFor="let ev of day.events">
                <small class="text-center">
                    <h5 class="text-gray mt-3 mb-0"><b class="text-primary">{{day.events.length}}</b><br><small><small><small>Tour</small></small></small></h5>
                </small>
            </small>
        </ng-container>

        <ng-container *ngIf="currentUser.role=='guest'">
                <small class="text-center">
                    <h5 class="text-gray mt-3 mb-0"><b class="text-primary">{{day.events.length}}</b><br><small><small><small>Tour</small></small></small></h5>
                </small>
        </ng-container>

    </ng-container>
</ng-template>


<ng-template #modalTour>
    <div class="modal-header bg-light">
        <h5 class="mb-0">Prenotazione per il <b>{{date_selected | date:"dd/MM/YYYY"}}</b></h5>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalTour()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6">
                <span>Ora e luogo di partenza</span>
            </div>
            <div class="col-lg-6">
                <select class="form-control form-control-sm" name="time_start" [(ngModel)]="event_selected" (ngModelChange)="onSelectEvent($event)">
                    <option *ngFor="let te of tour_events" [ngValue]="te">{{te.meta.time_start.substr(0,5)}} ({{te.meta.location_start}})</option>
                </select>
            </div>
        </div>
        <div class="row mt-1" *ngIf="tour_selected">
            <div class="col-lg-6">
                <span>Adulti</span>
            </div>
            <div class="col-6 col-lg-3">
                <input autofocus type="number" min="1" class="form-control form-control-sm" name="adults" [(ngModel)]="tour_selected.dates[0].adults" (change)="calculateTotal()">
            </div>
            <div class="col-6 col-lg-3 text-right">
                <span class="form-control form-control-sm bg-light">{{tour_selected.dates[0].price_adult*tour_selected.dates[0].adults | currency:' &euro;'}}</span>
            </div>
        </div>
        <div class="row mt-1" *ngIf="tour_selected && tour_selected.dates[0].price_children>0">
            <div class="col-lg-6">
                <span>Bambini<small> (fino a 12 anni)</small></span>
            </div>
            <div class="col-6 col-lg-3">
                <input type="number" min="0" class="form-control form-control-sm" name="children" [(ngModel)]="tour_selected.dates[0].children" (change)="calculateTotal()">
            </div>
            <div class="col-6 col-lg-3 text-right">
                <span class="form-control form-control-sm bg-light">{{tour_selected.dates[0].price_children*tour_selected.dates[0].children | currency:' &euro;'}}</span>
            </div>
        </div>
        <div class="row mt-1" *ngIf="tour_selected">
            <div class="col-lg-6">
                <b>TOTALE</b>
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-right">
                <span class="form-control form-control-sm bg-light"><b>{{total | currency:" &euro;"}}</b></span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                    <div  class="alert alert-info m-auto" *ngIf="tour_selected.dates[0].price_children==0">
                        <i class="fas fa-exclamation-circle mr-2"></i>Questo Tour non è addatto ai bambini (fino a 12 anni)
                    </div>

                <div  class="alert alert-danger m-auto" *ngIf="(tour_selected.dates[0].adults+tour_selected.dates[0].children)>event_partecipants_available">
                    <i class="fas fa-exclamation-triangle mr-2"></i>Il numero massimo di posti disponibili è {{event_partecipants_available}}
                </div>
                <div class="alert alert-danger m-auto" *ngIf="event_selected.meta.busy>=event_selected.meta.quantity">
                    <i class="fas fa-exclamation-triangle mr-2"></i>Ci dispiace. Non ci sono posti disponibili per questo evento!
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer p-1">
        <button class="btn btn-secondary" (click)="closeModalTour()">Annulla</button>
        <ng-container  *ngIf="tour_selected">
            <button [disabled]="tour_selected.dates[0].partecipants<1 || event_selected.meta.busy>=event_selected.meta.quantity || (tour_selected.dates[0].adults+tour_selected.dates[0].children)>event_partecipants_available" class="btn btn-success" (click)="confirm()">Conferma</button>
        </ng-container>
    </div>
</ng-template>