
<div class="row">
    
    <div class="d-none d-sm-block pt-2 pr-0 col-2" *ngIf="enableHeader"><i class="fas fa-search text-gray ml-1"></i><span class="float-right">Codice</span></div>
    <div class="col-sm-10 ">
        <div class="input-group input-group-sm" >
            <input class="form-control" autofocus="true"  #searchCode type="text" placeholder="SN, Cod. Art, Cod. Ext, EAN ..."    [(ngModel)]="code" (keyup.enter)="searchProduct()" />
            <div class="input-group-prepend " *ngIf="canExploreCatalog">
                <a class="btn btn-sm btn-primary"  title="Cerca nel catalogo" (click)="searchIntoProducts()"><i class="fa fa-search" ></i></a>
            </div>
            <div class="input-group-prepend " *ngIf="canQRCode">
                <a class="btn btn-sm btn-secondary"  title="Cerca con QR-Code" (click)="openQrCodeReader()"><i class="fas fa-qrcode" ></i></a>
            </div>
            <div class="input-group-prepend "  *ngIf="canNewProduct">
                <a class="btn btn-sm btn-success"  title="Nuovo prodotto" (click)="newProduct()"><i class="fas fa-plus" ></i></a>
            </div>
        </div>
    </div>

    <!--
    <div class="col-sm-6" *ngIf="showDescription">
        <div class="form-group">
            <label>Descrizione</label>
            <div class="input-group input-group-sm" >
                <input class="form-control" type="text"   [(ngModel)]="description"  (keyup)="update()" />
            </div>
        </div>
    </div>
    <div class="col-sm-3" >
        <div class="form-group mb-0 row">
            <div class="col-2"><label>SN</label></div>
            <div class="col-10"><input class="form-control form-control-sm" type="text" [(ngModel)]="serial" (keyup)="update()" /></div>
        </div>
    </div>
    <div class="col-sm-3" *ngIf="showSN && serialList!=null && serialList.length>0 && serial!=''">
        <div class="form-group mb-0 row">
            <div class="col-2"><label>SN</label></div>
            <div class="col-10">
                <div class="input-group input-group-sm" >
                    <select class="form-control form-control-sm"  [(ngModel)]="serial" (ngModelChange)="update()">
                        <option *ngFor="let s of serialList" value="{{s.sn}}"><span *ngIf="s.sn==''">***SN non specificato***</span>{{s.sn}} <span *ngIf="s.avaible <=0"> ** NON DISPONIBILE **</span></option>
                    </select>
                    
                </div>
            </div>
        </div>
    </div>
       -->

</div>
