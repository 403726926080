<ul class="list-group list-group-horizontal text-center" style="overflow: auto">
    <li *ngFor="let c of categories" class="list-group-item list-group-item-primary " [class.active]="selected_category==c"
        (click)="selected_category=c;getProducts()">{{c.name}}</li>
</ul>
<button class="btn text-md btn-success px-3 mt-2" (click)="newRecord()"><i class="fa fa-plus mr-2"></i>Aggiungi articolo</button>
<ul class="list-group mt-2" style="overflow-y:auto; height:calc(100vh - 280px)">
    <li class="list-group-item p-0 w-100" *ngFor="let p of list" >
        <div class=" border-right float-left" style="height:100%; width:5rem; background: url('{{p.coverImage().imagePath}}'); background-repeat: no-repeat; background-size: cover">
            </div>
        <div class="p-4 float-left">
            <i class="fa fa-pencil-alt px-2" (click)="openModalEdit(p)"></i>
            {{p.name}} ({{p.netprice*(1+p.tax/100) | currency:" &euro;"}})
            </div>
            <div class="p-4 float-right">
            <i class="fa fa-trash-alt text-danger float-right px-2" (click)="delete(p)"></i>
        </div>
    </li>
</ul>


<!--
<div>
    <button class="btn text-md btn-success px-3" (click)="newRecord()"><i class="fa fa-plus mr-2"></i>Aggiungi articolo</button>
    <div class="row bg-color0" style="height:calc(100vh - 6.5rem); overflow:hidden">

        <div class="col-2 mt-2 scrollable">
            <small>
                <ul class="list-group">
                    <li *ngFor="let c of categories" class="list-group-item list-group-item-primary" [class.active]="selected_category==c.id"
                        (click)="selected_category=c.id;getProducts()">
                        {{c.name}}
                    </li>
                </ul>
            </small>
        </div>

        <div class="col-6 scrollable">
            <div class="row p-2">
                <div class="col-3 card mb-1" *ngFor="let p of list" (click)="openModalEdit(p)" style="box-shadow: none">
                    <div class="card-body bg-light" style="height:15vh; background: url('{{p.coverImage().imagePath}}'); background-repeat: no-repeat; background-size: cover">
                    </div>
                    <div class="card-footer bg-color1 px-1 flex-center">
                        <small>{{p.name}}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->


<ng-template #modalEdit>
    <div class="modal-body bg-color0 border">
        <label>Titolo</label>
        <input type="text" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.name">
        <label>Prezzo netto</label>
        <input type="text" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.netprice"
            (ngModelChange)="item_selected.total=item_selected.netprice*(1+item_selected.tax/100)">
        <label>IVA</label>
        <input type="text" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.tax"
            (ngModelChange)="item_selected.total=item_selected.netprice*(1+item_selected.tax/100)">
        <label>Prezzo lordo</label>
        <input type="text" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.total"
            (ngModelChange)="item_selected.netprice=item_selected.total/(1+item_selected.tax/100)">

        <label>Immagine</label>
        <button *ngIf="item_selected.images.length==0" class="btn text-lg btn-primary d-block" (click)="fileToUpload.click()"><i class="fas fa-upload mr-2"></i>Carica</button>
        <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
        <div *ngIf="item_selected.images.length > 0" style="height:5.5rem">
            <div class=" border float-left" style="height:5rem; width:5rem; background: url('{{item_selected.coverImage().imagePath}}'); background-repeat: no-repeat; background-size: cover">
                </div>
            <button class="btn text-lg btn-danger float-left m-3" (click)="removeImage(item_selected.coverImage())"><i class="fas fa-trash mr-2"></i>Elimina</button>
        </div>

        <div class="row mt-2">
            <div class="col-6">
                <button class="btn btn-success btn-block" (click)="save()"><i class="fas fa-save mr-2"></i>Salva</button>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary btn-block" (click)="closeModalEdit()"><i class="fas fa-times mr-2"></i>Annulla</button>
            </div>
        </div>
    </div>
</ng-template>