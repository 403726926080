import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductCategories } from 'projects/c1-backend/src/app/db/productcategories';
import { ProductcategoriesService } from 'projects/c1-backend/src/app/services/productcategories.service';
import { Globals } from 'projects/core/src/globals';

@Component({
  selector: 'setting-productcategories',
  templateUrl: './productcategories.component.html',
  styleUrls: ['./productcategories.component.css']
})

export class SettingProductcategoriesComponent implements OnInit {

  @ViewChild("modalEdit")
  modalEdit:TemplateRef<any>;
  modalEditRef;

  list=[];

  item_selected:ProductCategories=new ProductCategories();
  
  constructor(
    private productcategoriesService: ProductcategoriesService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
   this.getCategories()
  }


  getCategories(){
    this.productcategoriesService.getCategories().subscribe((result)=>{
      this.list=result;
    })
  }


  openModalEdit (record=null){
    Object.assign(this.item_selected,record)
    this.modalEditRef=this.modalService.open(this.modalEdit,{size:"lg"});
  }
  closeModalEdit(){
    this.modalEditRef.close();
  }


  newRecord(){
    this.item_selected=new ProductCategories();
    this.item_selected.tax=22;
    this.openModalEdit(this.item_selected);
  }


  delete(item){
    Globals.modal.showConfirm("Sei sicuro di voler eliminare la categoria "+item.name+"?","",()=>{
      this.productcategoriesService.delete([item.id]).subscribe(()=>{
        this.getCategories();
      })
    },"Elimina","Annulla","danger")
  }


  save() {
    this.productcategoriesService.save(this.item_selected,()=>{
      this.closeModalEdit();
      this.getCategories();
    })
  }


}
