<ul class="list-group list-group-horizontal text-center" style="overflow: auto">
    <li *ngFor="let r of rooms" class="list-group-item list-group-item-primary " [class.active]="r.id==selected_id_room"
        (click)="selected_id_room=r.id;getTables()">{{r.title}}</li>
</ul>
<button class="btn text-md btn-success px-3 mt-2" (click)="newRecord()"><i class="fa fa-plus mr-2"></i>Aggiungi tavolo</button>
<ul class="list-group mt-2" style="overflow-y:auto; height:calc(100vh - 280px)">
    <li class="list-group-item w-100" *ngFor="let t of list">
        <i class="fa fa-pencil-alt px-2" (click)="openModalEdit(t)"></i>
        <span>{{t.title}}&ensp;<small>({{t.seats}} posti)</small></span>
        <i class="fa fa-trash-alt text-danger float-right px-2" (click)="delete(t)"></i>
    </li>
</ul>

<!-- 
    <div class="row py-2" style="height:calc (100vh - 8rem); overflow:auto">
    <div class="col-2" *ngFor="let t of list">
        <div class="card" >
            <div class="card-header p-0 bg-color1">
                <p class="text-center m-0 p-0">{{t.title}}</p>
            </div>
            <div class="card-body text-center">
                <span><i class="fa fa-chair mr-1"></i>{{t.seats}}</span>

                <i class="fa fa-pencil-alt px-2" (click)="openModalEdit(t)"></i>
                <i class="fa fa-trash-alt text-danger float-right px-2" (click)="delete(t.id)"></i>
            </div>
        </div>
    </div>
</div> -->


<ng-template #modalEdit>
    <div class="modal-body bg-color0 border">
        <label>Titolo</label>
        <input type="text" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.title">
       
        <label>Posti al tavolo</label>
        <input type="number" class="form-control form-control-sm mb-2" [(ngModel)]="item_selected.seats">

        <div class="row mt-4">
            <div class="col-6">
                <button class="btn btn-success btn-block" (click)="save()"><i class="fas fa-save mr-2"></i>Salva</button>
            </div>
            <div class="col-6">
                <button class="btn btn-secondary btn-block" (click)="closeModalEdit()"><i class="fas fa-times mr-2"></i>Annulla</button>
            </div>
        </div>
    </div>
</ng-template>
