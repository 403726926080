import { Component, OnInit, ViewChild,Output,EventEmitter } from '@angular/core';
import { KeyboardComponent as Keyboard} from 'projects/c1-backend/src/app/modules/keyboard/keyboard.component';

@Component({
  selector: 'keyboard-modal',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.css']
})
export class KeyboardComponent implements OnInit {
  @ViewChild("keyboard")
  keyboard:Keyboard

  @Output()
  data:EventEmitter<any>=new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
    this.keyboard.data.subscribe((res)=>{
      this.data.emit(res);
    })

  }

}
