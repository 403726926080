import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { Access } from 'projects/core/src/access';
import { AuthGuard } from 'projects/core/src/authGuard';
import { Config } from 'projects/core/src/config';
import { Globals } from 'projects/core/src/globals';
import { Message } from 'projects/core/src/message';
import { ModalWindow } from 'projects/core/src/modal';
import { NavigationHelper } from 'projects/core/src/navigation';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxLoadingModule } from 'ngx-loading';
import { InitModule } from 'projects/c1-backend/src/app/modules/init/init.module';
import { C1Connector } from 'projects/core/src/c1connector';
import { PointModule } from 'projects/c1-backend/src/app/modules/point/point.module';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { ChatModule } from 'projects/c1-backend/src/app/modules/chat/chat.module';
import { TablesComponent } from './tables/tables.component';
import { TableDetailComponent } from './tables/detail/detail.component';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { KeyboardModule } from 'projects/c1-backend/src/app/modules/keyboard/keyboard.module';
import { KeyboardComponent } from './modules/keyboard/keyboard.component';
import { SettingsComponent } from './settings/settings.component';
import { SettingRoomsComponent } from './settings/rooms/rooms.component';
import { SettingTablesComponent } from './settings/tables/tables.component';
import { SettingProductcategoriesComponent } from './settings/productcategories/productcategories.component';
import { SettingProductsComponent } from './settings/products/products.component';
import { SettingGeneralComponent } from './settings/general/general.component';
import { ShopComponent } from './shop/shop.component';

registerLocaleData(localeIt, 'it');
@NgModule({
  declarations: [
    AppComponent,
    TablesComponent,
    TableDetailComponent,
    KeyboardComponent,
    SettingsComponent,
    SettingRoomsComponent,
    SettingTablesComponent,
    SettingProductcategoriesComponent,
    SettingProductsComponent,
    SettingGeneralComponent,
    ShopComponent
  ],
  
  imports: [
    PointModule,
    InitModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxLoadingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ChatModule,
    ToolbarmodelModule,
    KeyboardModule
  ],
  providers: [AuthGuard,Globals,Config,Access,NavigationHelper,Message,ModalWindow,C1Connector],
  bootstrap: [AppComponent]
})
export class AppModule { }
