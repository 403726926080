import { Component, OnInit ,Output,EventEmitter, AfterViewInit, Input} from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { ModelList } from 'projects/core/src/include/modellist';
import { FormBuilder } from '@angular/forms';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { Deadlines } from '../../../db/deadlines';
import { DeadlinesService } from '../../../services/deadlines.service';
import { DeadlineDetailsComponent } from '../deadline-details/deadline-details.component';
import { Subject } from 'rxjs';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { ListComponent } from '../list/list.component';
import { DeadlinesComponent } from '../deadlines.component';
registerLocaleData(localeIt, 'it');
@Component({
  selector: 'app-deadlines-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent extends ModelList<Deadlines> implements OnInit {

  MODE_VIEW_MONTH=1
  MODE_VIEW_YEAR=2


  @Output()
  periodSelected:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  dateSelected:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  noEvent:EventEmitter<any>=new EventEmitter<any>();

  @Input()
  list=[];

  @Input()
  table="";

  @Input()
  where_join="";

  @Input()
  join="";


  modeView=this.MODE_VIEW_MONTH;

  locale: string = "it";
  today=new Date();
  viewDate: Date = new Date();
  
  date_selected=new Date();
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  list_month=[];
  date_from;
  date_to;

  total=0;

  year=new Date().getFullYear();

  constructor(
    private deadlinesService: DeadlinesService,
    private fb1: FormBuilder,

  ) { 
    super(deadlinesService,fb1);
    this.detailViewModal=DeadlineDetailsComponent;
  }

  ngOnInit(): void {
    this.handleEvent(this.today);

    
  }

  getItems(){
    this.deadlinesService.getItemsGrouped(this.date_from,this.date_to,"day",this.table,this.join,this.where_join).subscribe((items)=>{
      this.events=[];
      for(let i of items){
        let c:CalendarEvent={} as CalendarEvent;
        c.start=new Date(i.date);
        c.title=i.type;    
        
        c.meta=i;
        c.meta.count=i.count;
        c.meta.total=i.total;
        this.events.push(c);
      }
      
      
    });
  }

  getItemsByMonth(){
    this.deadlinesService.getItemsGrouped(this.year+"-01-01",this.year+"-12-31","month",this.table,this.join,this.where_join).subscribe((items)=>{
      this.list_month=[];
      this.total=0;
      
      this.list_month.push({"year":0,"month":1,"label":"Gennaio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":2,"label":"Febbraio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":3,"label":"Marzo","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":4,"label":"Aprile","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":5,"label":"Maggio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":6,"label":"Giugno","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":7,"label":"Luglio","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":8,"label":"Agosto","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":9,"label":"Settembre","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":10,"label":"Ottobre","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":11,"label":"Novembre","deadlines":[],"total":0,"count":0});
      this.list_month.push({"year":0,"month":12,"label":"Dicembre","deadlines":[],"total":0,"count":0});

      console.log(items);

      for(let i of items){
        this.list_month[Helper.convertString2Date(i.date).getMonth()]['deadlines'].push(i);
        this.list_month[Helper.convertString2Date(i.date).getMonth()]['year']=Helper.convertString2Date(i.date).getFullYear();
      }

      for(let m of this.list_month){
        m.total=0;
        m.count=0;
        for(let d of m.deadlines){
          m.total=m.total+parseFloat(d.total);
          m.count=m.count+parseInt(d.count);
        }
        this.total=this.total+m.total;
        
      }



     





    });
  }

  /*
  ngOnChanges(){
    this.events=[];
      for(let d of this.list){


          //verifica se la tipologia di spesa è stata già segnata
          let found=null;
          for(let i=0;i<this.events.length;i++){
            if(this.events[i].meta.date==d.date && this.events[i].meta.id_type==d.id_type){
              found=this.events[i];
            }
          }

          if(found){
            found.meta.count=found.meta.count+1;
            found.meta.total=found.meta.total+d.amount*(1+d.tax/100)*(d.type_amount==2?-1:1);
            found.meta.list.push(d);
          }else{
            let c:CalendarEvent={} as CalendarEvent;
            c.start=new Date(d.date);
            c.title=d.subject;    
            
            c.meta=d;
            c.meta.count=1;
            c.meta.total=d.amount*(1+d.tax/100)*(d.type_amount==2?-1:1);
            c.meta.list=[];
            c.meta.list.push(d);
            this.events.push(c);
          
          }

        
        
        
  
      }
  }
*/

  monthClicked(m:any):void{

    //ricava il periodo temporale
    let date=Helper.convertString2Date(m.date);

    let date_from=Helper.convertString2Date(date.getFullYear()+"-"+ (date.getMonth()+1)+"-01");

    var date_to = new Date(date.getFullYear(),date.getMonth() + 1, 0);


    Globals.modal.showModal(DeadlinesComponent,[
      {"name":"mode","value":"modal"},
      {"name":"filter_box","value":true},
      {"name":"viewMode","value":"table"},
      
      {"name":"date_from","value":date_from},
      {"name":"date_to","value":date_to},
      {"name":"id_type","value":m.id_type.toString()},
      {"name":"default_filterdate","value":99},
      {"name":"table","value":this.table}
      
    ],()=>{

    });
  }

  dayClicked(d:any): void {
    this.date_selected=d.date;

    /*
    //crea la lista da mostrare
    let list=[];
    for(let e of d.events){
      for(let l of e.meta.list){
        list.push(l);
      }
    }
    */
    Globals.modal.showModal(DeadlinesComponent,[
      {"name":"mode","value":"modal"},
      {"name":"filter_box","value":true},
      {"name":"viewMode","value":"table"},
      {"name":"table","value":this.table},
      {"name":"date","value":Helper.convertDateControl(d.date)}
    ],()=>{

    });


  }


  handleEvent(d:any) {

    
  
    this.date_from=Helper.convertDateControl(new Date(d.getFullYear(), d.getMonth(), 1)); 
    this.date_to=Helper.convertDateControl(new Date(d.getFullYear(), d.getMonth()+1, 0)); 

    


   // this.periodSelected.emit({"date_from":Helper.convertDateControl(this.date_from),"date_to":Helper.convertDateControl(this.date_to)});
    this.getItems();
     
   }



}
