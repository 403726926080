import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'projects/core/src/globals';
import { Model } from 'projects/core/src/include/model';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Company } from '../../../company';
import { CompaniesService } from '../../../services/companies.service';
import { TicketsService } from '../../../services/tickets.service';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})
export class CompanyDetailComponent extends Model<Company> implements OnInit {
  fileToUpload: File = null;

  record:any;
  wss=[];
  constructor(
    private route1: ActivatedRoute,
    private companiesService:CompaniesService,
    private ticketsService:TicketsService,
    private uploadService:UploadService,

  ) { 
    super(route1,companiesService); 
  }

  ngOnInit(): void {

    this.title = 'Compagnia Dettaglio';

    super.ngOnInit.apply(this, arguments); 
    if(this.mode!="modal")
      this.route1.params.subscribe((params) => this.id = params.id);
    

    this.ticketsService.getWSList().subscribe((items)=>{
      this.wss=items;
    })

    
    this.afterGetItem=()=>{
      try{
        this.record.routes=JSON.parse(this.record.routes);
        
      }catch{
        this.record.routes=[];
      }

      for(let r of this.record.routes){
        r['expand']=false;
      }

    }

    this.beforeSave=()=>{
      this.record.routes=JSON.stringify(this.record.routes);

      return true;
    }
    
    
    this.getItem();


  }


  addRow(){
    let row={};
    row['code']="";
    row['name']="";
    row['start_time']="";
    row['days']=[];
    row['route']=[];
    this.record.routes.push(row);
  }

  deleteRow(route){
    if(confirm("Sicuro di voler eliminare la linea?")){
      for(let i=0;i<this.record.routes.length;i++){
        if(this.record.routes[i]==route){
          this.record.routes.splice(i,1);
          return;
        }
      }
    }
  }

  addRoute(routes){
    let route={};
    route['port']="";
    route['address']="";
    route['start_time']="";
    route['end_time']="";
    route['latitude']="";
    route['longitude']="";
    route['pickup']="";
    routes.push(route);
    
  }

  deleteRoute(routes,route){
    for(let i=0;i<routes.length;i++){
      if(routes[i]==route){
        routes.splice(i,1);
        return;
      }
    }
  }


  checkDay(route,day){
    for(let i=0;i<route.days.length;i++){
      if(route.days[i]==day){
        return true;
      }
    }
    return false;
  }

  setDay(route,day,event){
    for(let i=0;i<route.days.length;i++){
      if(route.days[i]==day){
        if(!event.target.checked){
          route.days.splice(i,1);
          
        }
        return;
      }
    }

    if(event.target.checked){
      route.days.push(day);
    }
  }


  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"ticketcompanies",(result)=>{
      
      this.record.logo=Globals.config.serverUrl+"/"+result;
      Globals.setLoading(false);
     
    },true);
}

}
