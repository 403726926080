<ng-container *ngIf="!showOnlyTree">
    <span class="form-control form-control-sm pointer" (click)="open();" 
        title="Seleziona conto contabile">{{financialaccount}}<span *ngIf="!financialaccount">Tutti</span><i class="fas fa-coins float-right ml-2 py-1"></i></span>
</ng-container>


<ng-template #modalTree>
    <div class="modal-header bg-light">
        <h5 class="mb-0"><i class="fas fa-coins text-gray mr-2"></i>Seleziona il conto contabile</h5>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="close()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <tree-root [nodes]="financialaccounts" [focused]="true" (activate)="selectNode($event)" [options]="options"></tree-root>
    </div>
   <div class="modal-footer">
        <button class="btn btn-primary btn-block" (click)="confirm()">Conferma</button>
    </div>
</ng-template>