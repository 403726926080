<section class="section">
    <div class="row p-2">
        <div class="col-2">
            <ul class="list-group border-bottom h-100">
                <li class="list-group-item list-group-item-primary " [class.active]="filter_id_state==0" (click)="filter_id_state=0;getTableOrders()">Tutti</li>
                <li class="list-group-item list-group-item-primary " [class.active]="filter_id_state==1" (click)="filter_id_state=1;getTableOrders()">Liberi</li>
                <li class="list-group-item list-group-item-primary " [class.active]="filter_id_state==2" (click)="filter_id_state=2;getTableOrders()">Occupati</li>
                <li class="list-group-item list-group-item-primary " [class.active]="filter_id_state==3" (click)="filter_id_state=3;getTableOrders()">Da servire</li>
                <li class="list-group-item list-group-item-primary " [class.active]="filter_id_state==4" (click)="filter_id_state=4;getTableOrders()">Serviti</li>
                <li class="list-group-item list-group-item-primary " [class.active]="filter_id_state==5" (click)="filter_id_state=5;getTableOrders()">Preconto</li>
                
                
            </ul>
        </div>
        <div class="col-10">
            <ul class="list-group list-group-horizontal text-center" style="overflow: auto">
                <li *ngFor="let r of rooms" class="list-group-item list-group-item-primary " [class.active]="r.id==selected_id_room" (click)="selected_id_room=r.id;getTableOrders()">{{r.title}}</li>
                
            </ul>
            <div class="row py-2" style="height:calc (100vh - 8rem); overflow:auto">
                <!-- TABLE -->
                <div class="col-2" *ngFor="let t of tables">
                    <div class="card" (click)="openOrder(t);">
                        <div class="card-header p-0 bg-color1" [class.bg-gray]="t.state==1">
                            <p class="text-center m-0 p-0">{{t.title}}</p>
                        </div>
                        <div class="card-body text-center"
                            [class.text-secondary]="t.state==1"
                            [class.bg-danger]="t.state==2"
                            [class.bg-warning]="t.state==3"
                            [class.bg-info]="t.state==4"
                            [class.bg-success]="t.state==5"
                            >
                            <small *ngIf="t.state==1"><i class="fas fa-lg fa-utensils mr-2"></i><b>Libero</b></small>
                            <small *ngIf="t.state==2"><i class="fas fa-lg fa-utensils mr-2"></i><b>Occupato</b></small>
                            <small *ngIf="t.state==3"><i class="fas fa-lg fa-utensils mr-2"></i><b>Da servire</b></small>
                            <small *ngIf="t.state==4"><i class="fas fa-lg fa-utensils mr-2"></i><b>Servito</b></small>
                            <small *ngIf="t.state==5"><i class="fas fa-lg fa-utensils mr-2"></i><b>Preconto</b></small>
                            <div class="flex-center mt-2">
                                <span *ngIf="t.order">
                                    <i class="fa fa-clock mr-1"></i>{{t.order.created | date:"H:mm"}}
                                    <i class="fa fa-male mr-1 ml-2"></i>{{t.order.adults}}</span>
                                <span *ngIf="!t.order">
                                    <i class="fa fa-chair mr-1"></i>{{t.seats}}</span>
                            </div>
                        </div>
                       
                       
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>