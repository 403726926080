<div class="keyboard_spacer"></div>
    <footer class="keyboard main-footer fixed-bottom card bg-light py-1 {{classname}}">
        <div class="row">
            <!-- display (mobile) -->
            <div class="col-12 d-lg-none" *ngIf="showFunctions">
                <h3 class="d-inline-block text-truncate bg-black p-1 text-right text-white" style="width:70%;height:44px">{{display}}</h3>
                <h3 class="d-inline-block text-truncate bg-black p-1 text-left" style="width:30%;height:44px">
                    <ng-container *ngIf="type==2">
                        <small class="float-right text-info">EAN</small>
                    </ng-container>
                    <ng-container *ngIf="moltiplicator>0 && state==2">
                        <span class="text-warning"><small>&#10005;</small>{{moltiplicator}}</span>
                    </ng-container>
                    <ng-container *ngIf="percentual>0 && state==3">
                        <span class="text-orange">&#8722;{{percentual}}<small>%</small></span>
                    </ng-container>
                </h3>
            </div>
            <!-- numeri -->
            <div class="col-9 col-lg-4" [class.col-lg-11]="!showFunctions">
                <div class="row">
                    <div class="col-3 px-1">
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(1)">1</button>
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(4)">4</button>
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(7)">7</button>
                    </div>
                    <div class="col-3 px-1">
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(2)">2</button>
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(5)">5</button>
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(8)">8</button>
                    </div>
                    <div class="col-3 px-1">
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(3)">3</button>
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(6)">6</button>
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber(9)">9</button>
                    </div>
                    <div class="col-3 px-1">
                        <button [disabled]="type==2 || !showFunctions" (click)="backspace()" class="btn text-sm btn-block btn-outline-secondary" style="padding: 0.65rem 0">BACK</button>
                        <button class="btn text-lg btn-block btn-outline-secondary" (click)="keyNumber('0')">0</button>
                        <button class="btn text-lg btn-block btn-outline-secondary px-1" (click)="keyNumber('00')">00</button>
                    </div>
                </div>
            </div>
            <!-- cancella, moltiplica, sconto -->
            <div class="col-3 col-lg-1" *ngIf="showOperations">
                <div class="row">
                    <div class="col-lg-12 px-1">
                        <button class="btn text-lg btn-block btn-danger" (click)="clear()">C</button>
                        <button [disabled]="type==2 || !showFunctions" class="btn text-lg btn-block bg-warning" (click)="moltiplicate()">&#10005;</button>
                        <button [disabled]="type==2 || !showFunctions" class="btn text-lg btn-block bg-orange" (click)="percent()">&#37;</button>
                        <!-- <button [disabled]="digits==''" class="btn text-lg btn-block btn-success" (click)="confirm()"><b><i class="fa fa-check"></i></b></button> -->
                    </div>
                </div>
            </div>
            <!-- display e tasti funzione -->
            <div class="col-lg-7" *ngIf="showFunctions">
                <div class="row">
                    <div class="d-none d-lg-inline col-lg-12 px-1">
                        <h3 class="d-inline-block text-truncate bg-black mb-0 p-1 text-right text-white" style="width:80%;height:44px;">{{display}}</h3>
                        <h3  class="d-inline-block text-truncate bg-black mb-0 p-1 text-left text-warning" style="width:20%;height:44px;">
                            <ng-container *ngIf="type==2">
                                <small class="float-right text-info">EAN</small>
                            </ng-container>
                            <ng-container *ngIf="moltiplicator>0 && state==2">
                                <span class="text-warning"><small>&#10005;</small>{{moltiplicator}}</span>
                            </ng-container>
                            <ng-container *ngIf=" state==3">
                                <span class="text-orange">&#8722;{{percentual}}<small>%</small></span>
                            </ng-container>
                        </h3>
                    </div>
                    <div *ngIf="reps.length==0" class="col-9">
                        <div class="row">
                            <div class="my-1 px-1" *ngFor="let rep of reps"
                                [class.col-12]="reps.length==1"
                                [class.col-6]="reps.length==2 || reps.length==4"
                                [class.col-4]="reps.length==3 || reps.length>4"
                                >
                                <button [disabled]="digits==''" class="btn btn-block btn-secondary" (click)="auxFunc(rep)"><div style="height:30px;" class="w-100 text-truncate">{{rep.name}}</div></button>
                            </div>
                        </div>
                    </div>
                    <div [class.col-12]="reps.length==0" class="col-3 my-1 px-1" style="height:98px">
                        <!--<button class="btn text-lg btn-block btn-info" (click)="subtotal()">SUBTOTALE</button>-->
                        <button [disabled]="digits==''" class="h-100 btn btn-block btn-primary" (click)="confirm()">
                            ENTER
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </footer>