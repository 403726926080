import { Component, OnInit, Input, ViewChild, TemplateRef, AfterViewInit, HostListener } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { TypesService } from '../../../services/types.service';
import { Type } from '../../../db/type';
import { Contact } from '../../../db/contact';


import { ActivitiesService } from '../../../services/activities.service';
import { Activities } from '../../../db/activities';
import {DeadlinerulesService} from '../../../services/deadlinerules.service'
import {NotificationService} from '../../../services/notification.service';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, Ordering, OrderingMode, ToastMode, User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { ActivitiesDetailsComponent } from './../activities-details/activities-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeadlineDetailsComponent } from '../../deadlines/deadline-details/deadline-details.component';

import * as L from 'leaflet';
import * as ExtraMarkers from 'leaflet-extra-markers'

import { formatDate } from '@angular/common';
import { DocumentsDetailsComponent } from '../../documents/documents-details/documents-details.component';
import { CashFlowDetailsComponent } from '../../cash-flow/cash-flow-details/cash-flow-details.component';
import { FilterdateComponent } from '../../../modules/filterdate/filterdate.component';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
@Component({
  selector: 'app-activities-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ActivitiesListComponent extends ModelList<Activities> implements OnInit,AfterViewInit {
  
  STATUS_PAID_OK=1
  STATUS_PAID_PARTIAL=2
  STATUS_PAID_NO_PAID=3
  STATUS_PAID_ATTEMPT=4
  
  
  @ViewChild(FilterdateComponent)
  FilterdateComponent:FilterdateComponent;

  @HostListener('document:click', ['$event']) 
  clickout(event) 
  { 
    if(event.target.classList.contains("openActivity")){
      let record2open=new Activities();
      record2open['id']=event.target.getAttribute("data-id");
      this.openDetail(record2open); 
    }
    
  } 
  @Input()
  modeView=1; // 1 == Lista ; 2 == Mappa

  @Input()
  map_height="700px";
  private map:L.Map;
  markerLayer:L.LayerGroup;
  markerUser:L.LayerGroup;

  @ViewChild("rescheduleModal")
  rescheduleModal:TemplateRef<any>
  rescheduleModalRef;
  rescheduleAction;
  rescheduleRecord;

  @ViewChild("modalRescheduleMultiple")
  modalRescheduleMultiple:TemplateRef<any>
  modalRescheduleMultipleRef;
  rescheduleRecords=[];

  @Input()
  id_address:number;

  @Input()
  table:string;

  @Input()
  id_table:number;

  @Input()
  reference:any;

  @Input()
  filter_box:boolean=true;
  
  @Input()
  no_dateend:boolean=false;

  @Input()
  id_contract:number=0;

  @Input()
  default_filterdate=(this.mode!='embedded')?1:100;

  
  date_from: Date=new Date();
  date_to: Date=new Date();
  today=new Date;

  type_activities:Type[]=[];
  status_activities:Type[];
  users:User[];
  total_amount=0;
  total_paid=0;

  filter_search:Filter=new Filter();
  filter_search_city:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_id_type:Filter=new Filter();
  filter_id_user:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_phase:Filter=new Filter();
  filter_id:Filter=new Filter();

  filter_id_address:Filter=new Filter();
  filter_table:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  filter_status_address:Filter=new Filter();
  filter_contract:Filter=new Filter();
  filter_method_payment:Filter=new Filter();

  methodpayments=[];
  isFilterSet:boolean=false;
  loadingMap=false;
  constructor(
    private fb1: FormBuilder,
    private activitiesService: ActivitiesService,
    private typesService: TypesService,
    private userService:UserService,
    private notificationService:NotificationService,
    private methodpaymentsService:MethodpaymentsService,
    private modalService:NgbModal
    
  ) {
    super(activitiesService,fb1);
    this.detailViewModal=ActivitiesDetailsComponent

    


   }

  ngOnInit(): void {
    this.title = 'Attività';
    super.ngOnInit.apply(this, arguments);
    
    this.paging_count = 25;
    
    this.methodpaymentsService.getMethods().subscribe((items_m)=>{
      this.methodpayments=items_m;
    });

    this.typesService.getTypes("activities").subscribe((items)=>{
      this.type_activities=items;
    });

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    });
   
    Globals.events['savingActivityEvent'].subscribe(()=>{
      this.updateList();
    });
    

    this.afterGetItems=()=>{


      this.activitiesService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{
        if(item){
          this.total_amount=item.total_amount;
          this.total_paid=item.total_paid;
        }
      });

      for(let r of this.list)
        try{
          r.missingValues=JSON.parse(r.missingValues.toString());
        }catch{
          r.missingValues=[];
        }


      if(this.modeView==2){
        this.updateMap();
      }
    }

    if(this.modeView==2){
      setInterval(()=>{
        this.getUserPosition();
      },10000);
      this.getUserPosition();
    }

   

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("a.date");
    this.filter_date.value=Helper.convertDateControl(this.date_from);
    this.filter_date.value2=Helper.convertDateControl(this.date_to); 
    this.filter.push(this.filter_date);


    if(!this.filter_box)
      this.updateList();
  }

  ngAfterViewInit(){
    if(this.modeView==2){
      this.initMap();
    }
    
  }

  private initMap():void{

   
    if(this.mode=="embedded")
      return;
   
    this.map = L.map('map', {
      center: [ 38.1037, 15.6369 ],
      zoom: 12,

    });

   
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19
    });

    
    tiles.addTo(this.map);
  }

  setFilters():void{


    

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("a.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);



    /*
    this.date_from=this.today;
    this.date_to.setDate(this.today.getDate()+30);
    */

    
    this.filter_id.mode=FilterMode.normal;
    this.filter_id.fields=[];
    this.filter_id.fields.push("a.id");
    this.filter_id.value="";
    this.filter.push(this.filter_id);


    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("a.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("a.id_user");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);

    
    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("a.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);
    
    this.filter_table.mode=FilterMode.normal;
    this.filter_table.fields=[];
    this.filter_table.fields.push("a.table");
    this.filter_table.value="";
    this.filter.push(this.filter_table);

    this.filter_id_table.mode=FilterMode.normal;
    this.filter_id_table.fields=[];
    this.filter_id_table.fields.push("a.id_table");
    this.filter_id_table.value="";
    this.filter.push(this.filter_id_table);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("addr.name");
    this.filter_search.fields.push("a.description");
    
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_search_city.mode=FilterMode.like;
    this.filter_search_city.fields=[];
    this.filter_search_city.fields.push("a.city");
    this.filter_search_city.fields.push("a.address");
    this.filter_search_city.value="";
    this.filter.push(this.filter_search_city);

    this.filter_method_payment.mode=FilterMode.contain;
    this.filter_method_payment.fields=[];
    this.filter_method_payment.fields.push("a.method_payment");
    this.filter_method_payment.value="";
    this.filter.push(this.filter_method_payment);
/*
    this.filter_status_address.mode=FilterMode.normal;
    this.filter_status_address.fields=[];
    this.filter_status_address.fields.push("addr.status");
    this.filter_status_address.value="1";
    this.filter.push(this.filter_status_address);

*/
    this.filter_phase.mode=FilterMode.normal;
    this.filter_phase.fields=[];
    this.filter_phase.fields.push("a.phase");
    this.filter_phase.value="";
    this.filter.push(this.filter_phase);

    if(this.id_contract>0){

      this.filter_contract.mode=FilterMode.custom;
      this.filter_contract.fields=[];
      this.filter_contract.value="(a.id_table="+this.id_contract.toString()+" AND a.table='contracts') OR (a.id_table IN (SELECT id FROM contractsinstallments WHERE id_contract="+this.id_contract.toString()+") AND a.table='contractsinstallments')";
      this.filter.push(this.filter_contract);
    }


    this.ordering.mode=OrderingMode.discendent;  
    this.ordering.field="a.date,a.time";
    
    let ordering_priority:Ordering={} as Ordering;
    ordering_priority.mode=OrderingMode.discendent;
    ordering_priority.field="a.priority";
    this.orderingList.push(ordering_priority);

    /*
    if(this.mode!="modal")
      Globals.navigation.getStateFilters(this.constructor.name,this.filter);
    */

    this.isFilterSet=true;
  }
  ngOnChanges():void{
    if(this.id_table>0  || this.id_contract>0 || this.id_address>0)
      this.updateList();
  }

  updateList(data_type=0):void{

    if(this.mode=="embedded"){ //non avviare la ricerca fino a quando non si è impostato l'id_table
      if((this.id_table==0 || this.id_table==undefined) && (this.id_address==0 || this.id_address==undefined))
     
        return;

      
    }
    
    if(!this.isFilterSet)
      this.setFilters();

      if(this.id_address>0){
        this.filter_id_address.value=this.id_address.toString();
      }
    if(this.id_table>0 && this.table!=""){
      this.filter_table.value=this.table;
      this.filter_id_table.value=this.id_table.toString();
      
    }

    

    if(data_type==11 || this.mode=="embedded"){
      this.ordering.mode=OrderingMode.ascendent;  
      this.ordering.field="a.date";
    }

    if(this.modeView==2){
      this.paging_count=10000;
    }

    this.getItems();
  }

  add():void{
    
      this.openDetail();
      
  }


  notify(r:Activities){
    let id_address:number;
    let contacts:Contact[];
    
    this.activitiesService.getItem(r.id).subscribe((record)=>{
    
      switch(record.table){
        case "products":
          contacts=record.reference['shop']['addressItem']['contacts'];
          id_address=record.reference['shop']['addressItem']['id'];
          break;
        case "shops":
          contacts=record.reference['addressItem']['contacts'];
          id_address=record.reference['addressItem']['id'];
          break;
        case "contracts":
          contacts=record.reference['shop']['addressItem']['contacts'];
          id_address=record.reference['shop']['addressItem']['id'];
          break;
        case "addresses":
            contacts=record.reference['contacts'];
            id_address=record.reference['id'];
            break;
            
    
        
      }



      this.notificationService.showPreviewNotification(contacts,record,"activities",()=>{

      });

    });

    
    
  }


  confirmActivities(r:Activities){

    //riprendi tutti i dati dell'activities
    this.activitiesService.getItem(r.id).subscribe((record)=>{
      record.phase=3;
      this.activitiesService.save(record,(id)=>{
        let deadlinerulesService:DeadlinerulesService=new DeadlinerulesService();
        deadlinerulesService.getRule("activities",record,()=>{});
      });
    });

    
  }

  reschedule(r:Activities){

    this.activitiesService.getItem(r.id).subscribe((record)=>{
      this.rescheduleRecord=record;
      this.rescheduleModalRef=this.modalService.open(this.rescheduleModal);
    });
  }

  confirmReschedule(){
    if(this.rescheduleAction==1){ //crea agenda
      Globals.modal.showModal(DeadlineDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"date","value":this.rescheduleRecord.reschedule},
        {"name":"id_address","value":this.rescheduleRecord.id_address},
        {"name":"reference","value":this.rescheduleRecord.reference},
        {"name":"id_table","value":this.rescheduleRecord.id_table},
        {"name":"table","value":this.rescheduleRecord.table}
        
      ],(instance)=>{
          if(instance!=null){
            this.getItems();
          }
        });
    }
    if(this.rescheduleAction==2){ //modifica attività
      this.openDetail(this.rescheduleRecord,{"name":"new_date","value":this.rescheduleRecord.reschedule});
    }

    this.closeReschedule();
  }

  closeReschedule(){
    this.rescheduleModalRef.close("success");
  }

  
  private updateMap(): void {
  
    
    if(this.map==undefined){
      this.initMap();
    }else{
      if(this.markerLayer!=undefined)
        this.map.removeLayer(this.markerLayer);
    }

    if(this.list.length==0)
      return;

      this.loadingMap=true;

    this.markerLayer= L.layerGroup();

    let arrayOfLatLngs=[];
    for(let r of this.list){

      let location=this.getLocation(r);

        if(location['name'] && location['latitude']>0 && location['longitude']>0){
        
          let c=L.latLng(location['latitude'],location['longitude']);

         
          let today=this.isToday(r.date);
          let numberDay=this.calculateDiff(Helper.convertString2Date(r.date.toString()));

          let phase="";
          let color;
          if(r.phase==1){
            phase=`<i class="text-warning far fa-lg fa-2x fa-clock"></i>`;
            color="green";
          }
          if(r.phase==2){
            phase=`<i class="text-success fa fa-2x fa-check"></i>`;
            color="yellow";
          }
          if(r.phase==3){
            phase=`<i class="text-success fa fa-lg fa-2x fa-check-double"></i>`;
            color="red";
          }
          if(r.phase==4){
            phase=`<i class="text-success fa fa-lg fa-2x fa-clock"></i>`;
            color="red";
          }

          let icon=L.ExtraMarkers.icon({
            icon: 'fa-user fa-number initials',
            markerColor: color,
            shape:today?'circle':'star',
            prefix: '',
            number:location['name'].substr(0,3).toUpperCase(),
            
          });

          let m=L.marker(c,{icon:icon });
          
         
            
          

          let popup=`
            <div class="row">
              <div class="col-md-10">
                <strong>${today?"Oggi":(numberDay>0?"Fra "+numberDay+" giorni":numberDay+" giorni fa")} ${formatDate(r.date.toString(),"dd/MM/YYYY","it")}</strong><br/>
                <strong>${location['name']}</strong><br/>
                ${r.type!=null?r.type:""} ${r.description} by <span class="badge badge-info">${r.username}</span>
                <br/><br/><button class="btn btn-sm btn-secondary openActivity" data-id='${r.id}'>Apri attività</button>
              </div>
              <div class="col-md-2">
                ${phase}
              </div>
              
            </div>
          
          
          `;
          
          m.bindPopup(popup);
          m.on("click",function(event){
            m.openPopup();
          });

          arrayOfLatLngs.push(c)
          this.markerLayer.addLayer(m);
        }
      
    }

   



    this.map.addLayer(this.markerLayer);
    this.loadingMap=false;
    
  }


  getLocation(record){
    let result={};

    if(record.reference){
      switch(record.table){
        case "installations":
          if(record.reference.shop){
            result['latitude']=record.reference.shop.latitude;
            result['longitude']=record.reference.shop.longitude;
            result['name']=record.reference.shop.name;
          }
          break;

        case "shops":
          if(record.reference){
            result['latitude']=record.reference.latitude;
            result['longitude']=record.reference.longitude;
            result['name']=record.reference.name;
          }
          break;

        case "contracts":
          if(record.reference.shop){
            result['latitude']=record.reference.shop.latitude;
            result['longitude']=record.reference.shop.longitude;
            result['name']=record.reference.shop.name;
          }
          break;
          
      }
    }else{
      result['latitude']=record.latitude;
      result['longitude']=record.longitude;
      result['name']=record.name;
    }
    return result;
  }

  calculateDiff(dateSent){
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())- Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) ) /(1000 * 60 * 60 * 24));
}

  openDocument(document){
    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":document['id']}
    ],()=>{

    })
  }

  openCashflow(id_cashflow){
    if(id_cashflow>0){
      Globals.modal.showModal(CashFlowDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"id","value":id_cashflow}
      ],()=>{

      })
    }
  }

  getUserPosition(){
    this.activitiesService.getGPSposition().subscribe((result)=>{

      if(this.map==undefined){
        this.initMap();
      }else{
        if(this.markerUser!=undefined)
          this.map.removeLayer(this.markerUser);
      }

      this.markerUser= L.layerGroup();

      for(let u of result){

        let c=L.latLng(u['latitude'],u['longitude']);
        
        let icon=L.ExtraMarkers.icon({
          icon: 'fa-user fa-number initials',
          markerColor: "blue",
          shape:"penta",
          prefix: '',
          number:u['user']?(u['user']['name']?u['user']['name'].substr(0,3).toUpperCase():""):"",
          
        });
  
        let m=L.marker(c,{icon:icon });

        let popup=`
        <div class="row">
          <div class="col-md-12">
            
            Operatore: <strong>${u['user']?u['user']['name']:""}</strong><br/>
            Ultimo aggiornamento: <strong>${formatDate(u['date'].toString(),"dd/MM/YYYY HH:mm","it")}</strong><br/>
            
          </div>
          
          
        </div>
      
      
      `;
      
      m.bindPopup(popup);
      m.on("click",function(event){
        m.openPopup();
      });

      this.markerUser.addLayer(m);
    }

      

      this.map.addLayer(this.markerUser);
    

    });
  }

  hasCashflowPaid(record){
    if(record.cashflow){
      if(record.cashflow.paid>0)
        return true;
      else
        return false;
    }

    return false;
  }
  

  openRescheduleMultiple(){

    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno un elemento",ToastMode.WARNING);
      return;
    }
    let ids=this.form.value.id;
    this.rescheduleRecords=[];
    for(let id of ids){
      for(let record of this.list){
        if(record.id==id){
          this.rescheduleRecords.push(record);
        }
      }
    }

    this.modalRescheduleMultipleRef=this.modalService.open(this.modalRescheduleMultiple,{size:'lg'});
  }

  closeRescheduleMultiple(){
    this.modalRescheduleMultipleRef.close("success");
  }

  confirmRescheduleMultiple(){

    this.activitiesService.saveMultiple(this.rescheduleRecords,()=>{
      this.closeRescheduleMultiple();
      this.updateList();
    });

  }

  onSearchEnter(){
    if(this.filter_search.value!="" || this.filter_id.value!="" || this.filter_search_city.value!=""){
      this.FilterdateComponent.setDate(6);
     
    }else{
      this.updateList();
    }
  
  }

  statusPaid(activities){

    let status=this.STATUS_PAID_ATTEMPT;

    if(activities.phase>1){
      if((activities.amount>0 || activities.paid>0) && activities.enable_accounting){
        if(activities.id_cashflow==0){
          status=this.STATUS_PAID_NO_PAID;
        }else{
          //verifica se il pagamento è parziale
          if(activities.cashflow.paid>=activities.paid){
            status=this.STATUS_PAID_OK;
          }else{
            status=this.STATUS_PAID_PARTIAL;
          }
          
        }
      }

      if(activities.documents && activities.documents.length>0){
        if(activities.documents[0].cashflow.length==0){
          if(status!=this.STATUS_PAID_OK && status!=this.STATUS_PAID_PARTIAL)
            status=this.STATUS_PAID_NO_PAID;
        }
        else{
          //verifica il pagamento
          let total_paid=0;
          for(let c of activities.documents[0].cashflow){
            total_paid=total_paid+parseFloat(c.paid);
          }
          if(total_paid>=parseFloat(activities.paid)){
            status=this.STATUS_PAID_OK;
          }else{
            status=this.STATUS_PAID_PARTIAL;
          }
          
        }
          
      }
    }

    return status;
  }

  openAddress(id_address){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id_address}
      
    ],()=>{

    });
  }

}
