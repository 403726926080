<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label>Data</label>
                        <input class="form-control form-control-sm" type="date"  name="date"  [(ngModel)]="record.date"  />
                    </div>
                    <div class="form-group">
                        <label>Tipologia</label>
                        <app-type [mode]="2" [id_type]="record.id_type" [table]="'deadlines'" [record]="record" ></app-type>
                        <!--
                        <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                            <option *ngFor="let t of type_deadline" [ngValue]="t.id">{{t.name}}</option>
                        </select>
                        <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="alert alert-danger">
                            <div *ngIf="id_type.errors.required">Campo obbligatorio.</div>
                        </div>
                        -->
                    </div>
                    <div class="form-group">
                        <label>Elemento collegato</label>
                        <div class="border p-1">
                            <app-reference #referenceRecord  [showDisconnect]="true" [showConnectBtn]="true" (recordConnected)="connectActivity($event)" [reference]="record.reference" [table]="record.table" [mode]="'detail'"></app-reference>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Descrizione</label>
                        <textarea class="form-control form-control-sm" rows="1" onblur="this.rows=1;" onfocus="this.rows=4;"   name="description" [(ngModel)]="record.description"></textarea>
                    </div>
                    <div class="form-group" *ngIf="isModuleEnabled('financialaccounts')">
                        <label>Conti contabili</label>
                        <app-financialaccount [id_financialaccount]="record.id_financialaccount" (selected)="record.id_financialaccount=$event['id'];"></app-financialaccount>
                    </div>
                    <!-- Extra fields -->
                    <app-customfields [list]="record.customfields" [table]="'deadlines'" [id_table]="record.id" [condition_id]="record.id_type" [condition_field]="'id_type'"></app-customfields>
                    <div class="form-group">
                        <label>Note</label>
                        <textarea class="form-control form-control-sm" rows="1" onblur="this.rows=1;" onfocus="this.rows=4;"  name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                    </div>
                    <div class="form-group ">
                        <label>Operatore</label>
                        <div class="input-group input-group-sm">
                            <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" #id_user="ngModel" required>
                                <option *ngFor="let t of users" [ngValue]="t.id">{{t.username}}</option>
                            </select>
                            <div *ngIf="id_user.invalid && (id_user.dirty || id_user.touched)" class="alert alert-danger">
                                <div *ngIf="id_user.errors.required">Campo obbligatorio.</div>
                            </div>
                            <div class="input-group-append">
                                <select class="form-control form-control-sm" name="created_from" #created_from="ngModel" [(ngModel)]="record.created_from">
                                    <option *ngFor="let s of shops" [ngValue]="s.id">{{s.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                   
                </div>
                <div class="col-lg-6">
                    <div class="card card-body bg-light">
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <span>Tipologia di movimento</span>
                            </div>
                            <div class="col-lg-8">
                                <div class="btn-group btn-group-sm w-100">
                                    <button [class.alert-success]="record.type_amount==1" type="button" class="btn btn-default border btn-sm" (click)="record.type_amount=1">Ingresso</button>
                                    <button [class.alert-danger]="record.type_amount==2" type="button" class="btn btn-default border btn-sm" (click)="record.type_amount=2">Uscita</button>
                                </div>
                                <!-- <select class="form-control form-control-sm" name="type_amount" placeholder="..." #type_amount="ngModel" [(ngModel)]="record.type_amount">
                                    <option value="1">Ingresso</option>
                                    <option value="2">Uscita</option>
                                </select> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <span>Metodo di pagamento</span>
                            </div>
                            <div class="col-lg-8">
                                <select class="form-control form-control-sm" name="methodpayment" placeholder="..." #methodpayment="ngModel" [(ngModel)]="record.methodpayment">
                                    <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <span>Cassa / Banca</span>
                            </div>
                            <div class="col-lg-8">
                                <select class="form-control form-control-sm" [(ngModel)]="record.source" name="source" #id_user="ngModel">
                                    <option *ngFor="let s of sources" [ngValue]="s.description">{{s.description}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <span>Descrizione del pagamento</span>
                            </div>
                            <div class="col-lg-8">
                                <input type="text" class="form-control form-control-sm"   name="amount_description"  [(ngModel)]="record.amount_description" >
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <span>Importo</span><br>
                                <small>(su totale di {{record.amount/rate*100 | currency:' ' }})</small>
                            </div>
                            <div class="col-lg-4">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" step="0.01" name="amount"  placeholder="0" [(ngModel)]="record.amount" (change)="calculateGross()" (keyup)="calculateGross()" >
                                </div>
                            </div>
                            <div class="col-lg-4 pl-lg-0">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" step="0.01"  placeholder="100" [(ngModel)]="rate" [ngModelOptions]="{standalone: true}" >
                                    <div class="input-group-append ">
                                        <span class="input-group-text btn btn-default"  title="Calcola la percentuale della rata da pagare" (click)="calculateRate()">Calcola</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-right"><small><small>* per rateizzare il pagamentoo imposta una % e premi Calcola.</small></small></div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <span>IVA</span>
                            </div>
                            <div class="col-lg-4">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" step="1" name="tax" placeholder="..." [(ngModel)]="record.tax" (change)="calculateGross()" (keyup)="calculateGross()" >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4">
                                <span>Totale</span>
                            </div>
                            <div class="col-lg-4">
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" step="0.01" name="gross" placeholder="..." [(ngModel)]="record.gross" (change)="calculateNet()" (keyup)="calculateNet()">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>



