<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-lg-8">
                <div class="form-group">
                    <label>Anagrafica</label>
                    <div class="small-box alert-info">
                        <app-selectfieldcustom  [class]="'form-control form-control-sm'"
                                                [showViewButton]="true" 
                                                [showSearchButton]="true"  
                                                [recordSelected]="record.addressItem" 
                                                [service]="addressesService" 
                                                [fieldRender]="'name'" 
                                                (OnRecordSelected)="OnSelectAddress($event)" 
                                                (OnRecordView)="OnViewAddress($event)" 
                                                (OnExplore)="selectAddress()">
                        </app-selectfieldcustom>
                        <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                            <small>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <small>
                                            <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                <b>INDIRIZZO:</b><br>
                                                {{record.addressItem.address}}<br>
                                                {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                            </ng-container>
                                            <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                            <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                        </small>
                                    </div>
                                    <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                        <small>
                                            <b>CONTATTI:</b><br>
                                            <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                        </small>
                                    </div>
                                </div>
                            </small>
                            <div class="icon"><i class="fa fa-user"></i></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group">
                    <label>Tipologia</label>
                    <div class="input-group">
                        <select class="form-control form-control-sm" name="id_type" [(ngModel)]="record.id_type"  #id_type="ngModel" required>
                            <option *ngFor="let t of type_ticket" [ngValue]="t.id">{{t.name}}</option>
                        </select>
                    </div>
                    <label>Fase</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i *ngIf="!record.id_state" class="fas fa-question text-light"></i>
                                <i *ngIf="record.id_state == 1" class="fas fa-clock text-warning"></i>
                                <i *ngIf="record.id_state == 2" class="fas fa-check text-success"></i>
                                <i *ngIf="record.id_state == 3" class="fas fa-times text-danger"></i>
                                <i *ngIf="record.id_state == 5" class="fas fa-exclamation text-danger"></i>
                            </span>
                        </div>
                        <select class="form-control form-control-sm" [(ngModel)]="record.id_state" name="id_state2" #id_state2="ngModel">
                            <option value="1">In attesa del pagamento</option>
                            <option value="2">Confermato</option>
                            <option value="3">Annullato</option>
                            <option value="5">Biglietto da riprenotare</option>
                        </select>
                    </div>
                    <input type="checkbox" [(ngModel)]="record.blocked" name="blocked">
                    <label class="m-2">posti bloccati</label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2">
                <div class="form-group">
                    <label>Costo </label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend ">
                            <span class="input-group-text">&euro;</span>
                        </div>
                        <input type="currency" class="form-control form-control-sm " required name="cost"  #cost="ngModel" [(ngModel)]="record.cost">
                    </div> 
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label>Metodo di pagamento</label>
                    <select class="form-control form-control-sm" [(ngModel)]="record.method_payment" name="method_payment" #method_payment="ngModel">
                        <option value="postepay">Ricarica postepay</option>
                        <option value="Pagonline">Carta di credito (Pagonline)</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6" *ngIf="record.method_payment=='Pagonline'">
                <div class="form-group">
                    <label>Codice di transazione </label>
                    <input  type="text" class="form-control form-control-sm "  name="transaction_id"  #transaction_id="ngModel" [(ngModel)]="record.transaction_id"   >
                </div>
            </div>
            <div class="col-lg-6" *ngIf="record.method_payment=='postepay'">
                <div class="form-group">
                    <label>Carta per la ricarica</label>
                    <select class="form-control form-control-sm" [(ngModel)]="record.id_card" name="id_card" #id_card="ngModel">
                        <option *ngFor="let c of cards" [(ngValue)]="c.id">{{c.name}} ({{c.cardnumber}})</option>
                        
                    </select>
                </div>
            </div>
            <!-- Extra fields -->
            <app-customfields [list]="record.customfields" [table]="'tickets'" [id_table]="record.id"></app-customfields>
        </div>

        <div  class="card" *ngFor="let t of record.tickets;let i=index" >
            <div class="card-header bg-gray py-1">
                <div class="row">
                    <div class="col-12">
                        <i class="fa-ticket-alt fas mr-2"></i>Biglietto {{i+1}}
                        <small class="float-right d-none d-sm-inline-block">TNX ID: <b class="mr-5">{{t.tnx_id}}</b>SID: <b class="">{{t.sid}}</b></small>
                    </div>
                </div> 
            </div>
            <div class="card-body bg-light table-responsive" >
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Viaggio</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Da</span>
                                </div>
                                <input type="text" class="form-control form-control-sm " required name="{{i}}_{{t.port_from}}_{{t.port_to}}_port_from"  #port_from="ngModel" [(ngModel)]="t.port_from"   >
                            </div>
                            <div class="input-group input-group-sm mt-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">A</span>
                                </div>
                                <input type="text" class="form-control form-control-sm " required name="{{i}}_{{t.port_from}}_{{t.port_to}}_port_to"  #port_to="ngModel" [(ngModel)]="t.port_to"   >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group">
                            <label>Orario</label>
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm " required name="{{i}}_{{t.port_from}}_{{t.port_to}}_time_from"  #time_from="ngModel" [(ngModel)]="t.time_from"   >
                            </div>
                            <div class="input-group input-group-sm mt-1">
                                <input type="text" class="form-control form-control-sm " required name="{{i}}_{{t.port_from}}_{{t.port_to}}_time_to"  #time_to="ngModel" [(ngModel)]="t.time_to"   >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Stazione</label>
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm " required name="{{i}}_station_from"  #station_from="ngModel" [(ngModel)]="t.station_from"   >
                            </div>
                            <div class="input-group input-group-sm mt-1">
                                <input type="text" class="form-control form-control-sm " required name="{{i}}_{{t.port_from}}_{{t.port_to}}_station_to"  #station_to="ngModel" [(ngModel)]="t.station_to"   >
                            </div> 
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Data viaggio</label>
                            <div class="input-group">
                                <input type="date" class="form-control form-control-sm " required name="{{i}}_{{t.port_from}}_{{t.port_to}}_date"  #date="ngModel" [(ngModel)]="t.date"   >
                            </div> 
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Fase</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i *ngIf="t.id_state == 1" class="fas fa-clock text-warning"></i>
                                        <i *ngIf="t.id_state == 2" class="fas fa-check text-success"></i>
                                        <i *ngIf="t.id_state == 3" class="fas fa-times text-danger"></i>
                                        <i *ngIf="record.id_state == 5" class="fas fa-exclamation text-danger"></i>
                                    </span>
                                </div>
                                <select class="form-control form-control-sm" [(ngModel)]="t.id_state" name="{{i}}_{{t.port_from}}_{{t.port_to}}_id_state" #id_state="ngModel" (change)="record.id_state=t.id_state">
                                    <option value="1">In attesa del pagamento (posti bloccati)</option>
                                    <option value="1">In attesa del pagamento (posti NON bloccati)</option>
                                    <option value="2">Confermato</option>
                                    <option value="3">Annullato</option>
                                    <option value="5">Biglietto da riprenotare</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group mb-1">
                            <label>File</label>
                            <div class="input-group input-group-sm">
                                <input type="text" readonly class="form-control form-control-sm" name="{{i}}_file" [(ngModel)]="t.file" style="min-height: 2.5rem;">
                                <div class="input-group-append input-group-append-sm" *ngIf="t.file">
                                    <button type="button"  class="btn btn-default" (click)="downloadPDF(t)">
                                        <span class="d-none d-sm-inline-block"><i class="fas fa-download mr-2"></i>Scarica file</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group input-group-sm">
                            <label>Carica file</label>
                            <input type="file" class="form-control"  #fileToUpload name="fileToUpload" (change)="selectedTicketDetail=t;handleFileInput($event.target.files)" [(ngModel)]="t.file">
                        </div>
                    </div>
                    <div class="col-lg-12 mt-2" *ngIf="t.tnx_id==0">
                        <div class="form-group">
                            <label>PNR</label>
                            <div class="input-group input-group-sm">
                                <input   type="text" class="form-control form-control-sm " name="{{i}}_{{t.port_from}}_{{t.port_to}}_pnr"  #file="ngModel" [(ngModel)]="t.pnr"   >
                            </div> 
                        </div>
                    </div>
                    <div class="col-sm-12 mt-2" *ngIf="t.tnx_id==0">
                        <div class="form-group">
                            <label>Posti assegnati</label>
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm " name="{{i}}_{{t.port_from}}_{{t.port_to}}_seats"  #seats="ngModel" [(ngModel)]="t.seats"   >
                            </div> 
                        </div>
                    </div>
                    <div class="col-lg-12 mt-2" >
                        <h4>Passeggeri <button class="btn btn-sm btn-success float-right" (click)="addPassenger(t)"><i class="fa fa-plus"></i><span class="ml-2">Aggiungi</span></button></h4>
                        <table class="table table-sm mb-1">
                            <thead class="border-0">
                                <th>Posto</th>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Telefono</th>
                                <th>Tipologia</th>
                                <th>Costo</th>
                                <th>PNR</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let p of t.passengersArray;let j=index">
                                    <td>
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" name="{{i}}_passegerseat{{j}}" [(ngModel)]="p.seat" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" name="{{i}}_passegername{{j}}" [(ngModel)]="p.name" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" name="{{i}}_passegerlastname{{j}}" [(ngModel)]="p.lastname" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" name="{{i}}_passegertelephone{{j}}" [(ngModel)]="p.telephone" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group-sm">
                                            <select class="custom-select"  name="{{i}}_passegertype{{j}}" [(ngModel)]="p.type" required>
                                                <option value="ADULT">Adulto</option>
                                                <option value="CHILDREN">Bambino</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group-sm">
                                            <input type="text"  class="form-control" name="{{i}}_passegercost{{j}}" [(ngModel)]="p.cost" />
                                            <span *ngIf="t.offer" class="badge badge-warning">Sconto {{t.offer}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-group-sm">
                                            <input type="text"  class="form-control" name="{{i}}_passegerbookingnumber{{j}}" [(ngModel)]="p.booking_number" />
                                        </div>
                                    </td>
                                    <td>
                                        <button class="btn btn-sm btn-danger" title="Elimina" (click)="removePassenger(t.passengersArray,p)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="bg-secondary">
                                <tr>
                                    <td colspan="5">Costo totale biglietto</td>
                                    <td><input type="number" class="form-control form-control-sm" name="{{i}}_{{t.port_from}}_{{t.port_to}}_tcost"  min="0" step="0.01" placeholder="..." [(ngModel)]="t.cost" ></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                   
                </div>
            </div>
        </div>
    </form> 
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>


<ng-template #modalUpload let-modal> <!-- Deprecata? -->
    <div class="modal-header">
        <h4 class="modal-title">Carica file</h4>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalUpload()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div> 
    <form [formGroup]="formUpload" (ngSubmit)="onUpload()">
        <div class="modal-body">
            <div  class="row">
                <div class="col-md-12">
                    <label>File da caricare</label>
                    <input
                        formControlName="file"
                        id="file" 
                        type="file" 
                        class="form-control"
                        (change)="onFileSelect($event)" />
                </div>
            </div>
            <div  class="row mt-5">
                <div class="col-md-12">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <input class="btn btn-success" type="submit" value="Carica" />
            <button type="button" class="btn btn-light" (click)="closeModalUpload()">Annulla</button>
        </div>
    </form>
</ng-template>