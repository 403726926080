import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, OrderingMode } from 'projects/core/src/include/structures';
import { RentalPrice } from '../../../db/rentalprice';
import { RentalpriceService } from '../../../services/rentalprice.service';
import { ShopsService } from '../../../services/shops.service';
import { RentalpricesDetailComponent } from './rentalprices-detail/rentalprices-detail.component';

@Component({
  selector: 'app-rentalprices',
  templateUrl: './rentalprices.component.html',
  styleUrls: ['./rentalprices.component.css']
})
export class RentalpricesComponent  extends ModelList<RentalPrice> implements OnInit {
  
  get user(){
    return Globals.user;
  }

  currentDate = Helper.convertDateControl(new Date());
  
  viewMode=2;
  filter_status:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_published_start:Filter=new Filter();
  filter_published_end:Filter=new Filter();
  
  
  filter_id_shop:number=0;
  shops=[];
  tags=[];


  constructor(
    private rentalpriceService: RentalpriceService,
    private shopsService:ShopsService,
    private fb1: FormBuilder,
  ) {
    super(rentalpriceService,fb1);
    this.detailViewModal=RentalpricesDetailComponent;
   }

  ngOnInit(): void {
    this.title="Listino prezzi"
    super.ngOnInit.apply(this, arguments); 


  

    const default_address=Globals.parameters.get("general").getParam("default_address");

    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
      if(this.shops.length>0)
        this.filter_id_shop=this.shops[0].id;
    });


    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);


    this.filter_published_start.mode=FilterMode.minor;
    this.filter_published_start.fields=[];
    this.filter_published_start.fields.push("published_start");
    this.filter_published_start.value="";
    this.filter.push(this.filter_published_start);


    this.filter_published_end.mode=FilterMode.major;
    this.filter_published_end.fields=[];
    this.filter_published_end.fields.push("published_end");
    this.filter_published_end.value=this.filter_published_start.value;
    this.filter.push(this.filter_published_end);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.ordering.field="ordering";
    this.ordering.mode=OrderingMode.ascendent;

    this.afterGetItems=()=>{
      for(let i of this.list){
        this.extractTags(i);
      }
    }

    this.getItems();

    

  }

  updateOrdering(record){
    this.update(record,()=>{
      record['editOrdering']=false;
      this.getItems();
    })
  }


  extractTags(record){

    for(let s of record.shops){
      let prices=JSON.parse(s.prices);
      for(let p of prices){
        if(this.tags.indexOf(p.tag)==-1)
          this.tags.push(p.tag);
      }

    }
  }


  getTagLabel(tag){
    const tagsLabels=Globals.parameters.get("products").getParam("tags");
    for(let l of tagsLabels){
      if(l.tag==tag){
        return l.label;
      }
    }

    return tag;
  }


  getTagPrice(record,id_shop,tag){
    
    for(let s of record.shops){
      
      if(s.id_shop==id_shop){
        let prices=JSON.parse(s.prices);
        for(let p of prices){
          if(p.tag==tag){
            return p.gross;
          }
        }
      }
    }

    return "nd";
  }
  

  enableOrderingEditMode(record){
    if(this.user.isBackendUser() ){
      record['editOrdering']=true;
    }
  }

}
